import { useTranslation } from 'react-i18next';
import { useState, useEffect} from 'react';
import { verifiedCategoryTypes, triggerTypes } from '../common';
import { getName } from '../common';
import Legend from './legend';
import FilterVerified from './filterVerified';

export default function Diagram(props) {
  const { t } = useTranslation();
  const width = 900;
  const height = 55;
  const y = 20;
  const endPoint = {y1: 0, y2: 40};
  const firstYear = 1900;  // First agreement is from 1921
  const currentYear = new Date().getFullYear();
  const years = currentYear-firstYear;
  const stepSize = Math.floor(width/years); // Scale: one year equals one stepSize

  const fullLength = {x1: 0, x2: stepSize*years};

  // Result
  const [results, setResults] = useState([]);

  const [verifiedFiltersIsChanged, setVerifiedFiltersIsChanged]= useState(true);

  const [agreementNames, setAgreementNames] = useState([]);

  const [triggerType, setTriggerType] = useState([]);

  useEffect(() => {
    getVerifiedTriggerTypes();
    // eslint-disable-next-line
  }, []);

  // Update demonstrated data whenever page, sort order or any of the filters are changed
  useEffect(() => {
    fetchVerifiedControlsystem();
    // eslint-disable-next-line
  }, [props.agreementFilters, verifiedFiltersIsChanged]);

  function endPointMaker(x1, x2) {
    return (
      <>
        <line x1={x1} y1={endPoint.y1}
          x2={x1} y2={endPoint.y2} stroke='black' />
        <line x1={x2} y1={endPoint.y1}
          x2={x2} y2={endPoint.y2} stroke='black' />
      </>
    );
  }

  function isDateValid(dateStr) {
    const datePatternWithSlash = new RegExp('[0-9]{2}[/][0-9]{2}[/][0-9]{4}');
    const datePatternWith = new RegExp('[0-9]{4}[-][0-9]{2}[-][0-9]{2}');

    return (datePatternWithSlash.test(dateStr) || datePatternWith.test(dateStr));
  }

  function isFullLengthAtStart(inition) {
    let isFullLength = false;
    if (inition === '0') {
      isFullLength = true;
    }
    return isFullLength;
  }

  function isFullLengthAtEnd(duration) {
    let isFullLength = true;
    if (duration === '2') {
      isFullLength = false;
    }
    return isFullLength;
  }

  // Vertical dashed timestamp lines at input year
  function timestampLine(year) {
    const x = (year-firstYear) * stepSize;
    return (
      <line x1={x} y1='0' x2={x} y2={height} className={`trigger_timestamp`} />
    );
  }

  function allTimeStampLines() {
    return (
      <>
        {timestampLine(1900)}
        {timestampLine(1925)}
        {timestampLine(1950)}
        {timestampLine(1975)}
        {timestampLine(2000)}
      </>
    );
  }

  // Lines depending on trigger; once(0), quota(1), cyclical(2), continuous(3)
  function getLines(objs, index) {
    const verifiedCards = [];
    const colorStyle = `trigger_stroke_${index}`;
    objs.forEach((item, i) => {
      let x1 = fullLength.x1;
      if (item.initiationTime && isDateValid(item.initiationTime)) {
        if (item.initiationTime.split('-').length > 1) {
          x1 = (item.initiationTime.split('-')[0]-firstYear) * stepSize;
        } else if (item.initiationTime.split('/').length > 1) {
          x1 = (item.initiationTime.split('/')[2]-firstYear) * stepSize;
        }
      }
      let x2 = fullLength.x2;
      if (item.durationTime && isDateValid(item.durationTime)) {
        if (item.durationTime.split('-').length > 1) {
          x2 = (item.durationTime.split('-')[0]-firstYear) * stepSize;
        } else if (item.durationTime.split('/').length > 1) {
          x2 = (item.durationTime.split('/')[2]-firstYear) * stepSize;
        }
      }

      const startDecoration = isFullLengthAtStart(item.inition) ? '' : 'url(#startArrow)';
      const endDecoration = isFullLengthAtEnd(item.inition) ? '' : 'url(#endArrow)';

      verifiedCards.push(
        <div title={getLineInfo(item)}
          id={`${item.agreement_id}-${item.triggerAgreement}-${i}`}
          key={`${item.agreement_id}-${item.triggerAgreement}-${i}`}>
          <svg height={height} width={width} xmlns='http://www.w3.org/2000/svg'>
            <defs>
              <marker
                id='startArrow'
                viewBox='0 0 10 10'
                refX='1'
                refY='5'
                markerWidth='1.5'
                markerHeight='1.5'
                orient='auto-start-reverse'>
                <path d='M 0 5 L 10 0 L 10 10 z' />
              </marker>
              <marker
                id='endArrow'
                viewBox='0 0 10 10'
                refX='1'
                refY='5'
                markerWidth='1.5'
                markerHeight='1.5'
                orient='auto-start-reverse'>
                <path d='M 0 5 L 10 0 L 10 10 z' />
              </marker>
            </defs>

            {allTimeStampLines()}

            {/* once */}
            {item.triggerType === '0' && item.triggerAgreement === '0' &&
            <g>
              <line x1={x1} y1={y} x2={x2} y2={y} className={`trigger-line ${colorStyle}`}
                markerStart={startDecoration} markerEnd={endDecoration} />
              <line x1={(x2-x1)/2-5+x1} y1={y}
                x2={(x2-x1)/2+5+x1} y2={y} className={`trigger-once ${colorStyle}`} />
              {endPointMaker(x1, x2)}
            </g>}

            {/* quota */}
            {item.triggerType === '0' && item.triggerAgreement === '1' &&
            <g>
              <line x1={x1} y1={y} x2={x2} y2={y} className={`trigger-line ${colorStyle}`}
                markerStart={startDecoration} markerEnd={endDecoration} />
              <line x1={x1} y1={y} x2={x2} y2={y} className={`trigger-quota ${colorStyle}`} />
              <line x1={x1} y1={y} x2={x2} y2={y} className={`trigger-quota-fill ${colorStyle}`} />
              {endPointMaker(x1, x2)}
            </g>}

            {/* cyclical */}
            {item.triggerType === '0' && item.triggerAgreement === '2' &&
            <g>
              <line x1={x1} y1={y} x2={x2} y2={y} className={`trigger-line ${colorStyle}`}
                markerStart={startDecoration} markerEnd={endDecoration} />
              <line x1={x1} y1={y} x2={x2} y2={y} className={`trigger-cyclical ${colorStyle}`} />
              {endPointMaker(x1, x2)}
            </g>}

            {/* continuous */}
            {item.triggerType === '0' && item.triggerAgreement === '3' &&
            <g>
              <line x1={x1} y1={y} x2={x2} y2={y} className={`trigger-continuous ${colorStyle}`}
                markerStart={startDecoration} markerEnd={endDecoration} />
              {endPointMaker(x1, x2)}
            </g>}

            {/* other */}
            {item.triggerType !== '0' &&
            <g>
              <rect x={x1} y={y} width={x2-x1} height='10' fill='none'
                className={`trigger_stroke_other ${colorStyle}`}
                markerStart={startDecoration} markerEnd={endDecoration} />
              {endPointMaker(x1, x2)}
            </g>}
          </svg>
        </div>
      );
    });

    return verifiedCards;
  }

  // Ticks on timeline at specific year
  function timelineTick(year) {
    const x = (year-firstYear) * stepSize;
    return (
      <>
        <line x1={x} y1={y+10} x2={x} y2={y-10}
          className={`trigger-line trigger_stroke_legend`} />
        <text x={x} y={y+25}>{year}</text>
      </>
    );
  }

  // Unit on the x-axe
  function unit() {
    const x = (currentYear-firstYear-10) * stepSize;
    return (
      <>
        <text x={x} y={y+25}>{t('agreementControlSystem.diagram.verified.unit')}</text>
      </>
    );
  }

  // Fill verified trigger types
  const getVerifiedTriggerTypes = async () => {
    try {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      };

      const response = await fetch('/api/type/getVerifiedTriggerTypes', requestOptions);
      const res = await response.json();
      const results = JSON.parse(res.data).result;
      const items = [];
      if (results && results?.length > 0) {
        [...results].forEach((action) => {
          items.push({id: action.id, type: action.type});
        });
      }
      setTriggerType(items);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  function timeLine(id){
    return(
      <svg height={height} width={width} xmlns='http://www.w3.org/2000/svg'
        id={`legend-timeline-${id}`} key={`legend-timeline-${id}`} >
        <line x1={fullLength.x1} y1={y} x2={fullLength.x2} y2={y}
          className={`trigger-line trigger_stroke_legend`} />
        <line x1={fullLength.x2-10} y1={y+10} x2={fullLength.x2} y2={y}
          className={`trigger-line trigger_stroke_legend`} />
        <line x1={fullLength.x2-10} y1={y-10} x2={fullLength.x2} y2={y}
          className={`trigger-line trigger_stroke_legend`} />

        {timelineTick(1900)}
        {timelineTick(1925)}
        {timelineTick(1950)}
        {timelineTick(1975)}
        {timelineTick(2000)}
        {unit()}
      </svg>
    );
  }

  // Filter agreement parties from database
  const fetchVerifiedControlsystem = async () => {
    try {
      let verifiedItems = [];
      const mechanism = {
        verifiedCompliance: {
          isNTMs: document.getElementById('verifiedNTMsId-diagram').checked,
          isDisplay: document.getElementById('verifiedDisplayId-diagram').checked,
          isRemoteMonitoring: document.getElementById('verifiedRemoteMonitoringId-diagram').checked,
          isInPersonExhibition: document.getElementById('verifiedInpersonExhibitionId-diagram').checked,
          isInPersonDemonstration: document.getElementById('verifiedInpersonDemoId-diagram').checked,
          isInPersonInspection: document.getElementById('verifiedInpersonInspectionId-diagram').checked,
          isOther: document.getElementById('verifiedOtherId-diagram').checked }
      };

      const checkboxes = document.getElementsByName('agreement-group-cs');
      const filterAgreements = [];
      for (let i = 0, n = checkboxes.length; i < n; i++) {
        if (checkboxes[i].checked) {
          const agreementId = checkboxes[i].value;
          filterAgreements.push(agreementId);
        }
      }

      const isNoMechanismSet = Object.values(mechanism).every(item => item === false);
      // Only fetch data when filter is set
      if (!isNoMechanismSet && filterAgreements.length > 0) {
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ ids: filterAgreements, mechanism: mechanism })
        };

        // Verified
        const verifiedResponse = await fetch('/api/controlSystem/getVerified', requestOptions);
        const resVer = await verifiedResponse.json();
        let ids = [];
        if (resVer.isOk) {
          resVer.data?.forEach(item => {
            ids = [...new Set([...ids, item.agreement_id])];
            verifiedItems.push(item);
          });

          const verifieds = [];
          const agreementNames = [];
          // If more than 8 lines draw 2 timelines, one at the top and one at the bottom
          if (resVer.data.length > 8) {
            // Timeline
            verifieds.push(timeLine('start'));
          }
          ids.forEach((id, i) => {
            const agrees = verifiedItems.filter(o => o.agreement_id === id);
            verifieds.push(
              <div className='mb-4' id={`card-1-${id}`} key={`card-1-${id}`}>
                <div id={`toggle-h2-1-${id}`} key={`toggle-h2-1-${id}`}>
                  {getLines(agrees, i)}
                </div>
              </div>
            );
            agreementNames.push(agrees[0].name);
          });

          if (verifieds.length === 0) {
            verifieds.push(
              <svg height={height} width={width} xmlns='http://www.w3.org/2000/svg'
                id='verticalStamps' key='verticalStamps'>
                {allTimeStampLines()}
              </svg>
            );
          }
          // Timeline
          verifieds.push(timeLine('end'));

          setResults(verifieds);
          setAgreementNames(agreementNames);
        }
      } else {
        setResults(
          <div>
            <svg height={height} width={width} xmlns='http://www.w3.org/2000/svg'>
              {allTimeStampLines()}
            </svg>
            {timeLine('init')}
          </div>
        );
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  function getLineInfo(item) {
    const verifiedCategoryStr = verifiedCategoryTypes.get(item.category);
    let verifiedTriggerStr = '';
    if (item.triggerType === '0') {
      verifiedTriggerStr = `agreement, ${triggerTypes.get(item.triggerAgreement)}`;
    } else {
      verifiedTriggerStr = `${triggerType.filter(obj => {
        return item.triggerType === obj.id.toString()
      })[0]?.type}`
    }
    return `Agreement: ${getName(item.name, 35)}\nCategory: ${verifiedCategoryStr}\nTriggered by ${verifiedTriggerStr}`;
  }

  return (
    <section className='component mb-5 control-system' id='component-form-table'>
      <div className='anchor' id='table'></div>
      <nav aria-label='Tabulär navigering'>
        <ul className='nav nav-tabs' id='myTabChart1' role='tablist'>
          <li className='nav-item flex-fill text-center' role='presentation'>
            <button className='nav-link disabled' id='demonstrated-chart-tab' data-bs-toggle='tab'
              data-bs-target='#demonstrated-chart' type='button' role='tab'
              aria-controls='deomonstrated' aria-selected='false' aria-disabled="true">
               {`${t('agreementControlSystem.table.demonstratedcompliance')}`}
            </button>
          </li>
          <li className='nav-item flex-fill text-center' role='presentation'>
            <button className='nav-link active' id='verified-chart-tab' data-bs-toggle='tab'
              data-bs-target='#verified-chart' type='button' role='tab' aria-controls='verified'
              aria-selected='true'>
              {`${t('agreementControlSystem.table.verifiedcompliance')}`}
            </button>
          </li>
          <li className='nav-item flex-fill text-center' role='presentation'>
            <button className='nav-link disabled' id='consultation-chart-tab' data-bs-toggle='tab'
              data-bs-target='#consultation-chart' type='button' role='tab' aria-controls='consultation'
              aria-selected='false' aria-disabled="true">
              {`${t('agreementControlSystem.table.consultation.title')}`}
            </button>
          </li>
        </ul>
      </nav>
      <div className='card tab-card tab-content' id='myTabChartContent1'>
        <div className='card-body tab-pane fade disable' id='demonstrated-chart' role='tabpanel'
          aria-labelledby='demonstrated-chart-tab'>
        </div>
        <div className='card-body tab-pane fade show active' id='verified-chart' role='tabpanel'
          aria-labelledby='verified-chart-tab'>
          <FilterVerified onFilterIsChanged={setVerifiedFiltersIsChanged} idStr='diagram' />
          <div>
            <p>
              {t('agreementControlSystem.diagram.verified.preamble')}
            </p>
            <div className='mt-4 pt-4'>
              {results}
            </div>
            <Legend height={height} width={width} agreementNames={agreementNames} />
          </div>
        </div>
        <div className='card-body tab-pane fade disable' id='consultation-chart' role='tabpanel'
          aria-labelledby='consultation-chart-tab'>
        </div>
      </div>
    </section>


  );
}
