import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function Filter(props) {
  const [filter, setFilter] = useState([]);
  const { t } = useTranslation();

  function toggleActionFilter(id) {
    // Add or remove from action Filter
    const updatedAmen = [...filter];
    const btnElement = document.getElementById(`${id}-${props.type}-btn`);
    if (updatedAmen.length > 0 && updatedAmen?.includes(id)) {
      // Remove
      const updatedRailCards = filter && filter.filter((item) => item !== id);
      props.setCurrentFilter(updatedRailCards);
      setFilter(updatedRailCards);
      btnElement.classList.remove('icon-clear');
    } else {
      // Add
      props.setCurrentFilter([...updatedAmen, id]);
      setFilter([...updatedAmen, id]);
      btnElement.classList.add('icon-clear');
    }
  }

  return (
    <>
      <button className='button-filter button-icon icon-badge' data-bs-toggle='collapse'
        data-bs-target={`#${props.type}-filter`} aria-controls={`${props.type}-filter`}
        aria-expanded='false' data-badge-number={filter.length} id={`${props.type}-filter-button`}>
        <span className='visually-hidden'>{t('digitalLibrary.filterOn')}</span>{props.info}
      </button>
      <div id={`${props.type}-filter`} className='filter-content collapse'
        data-bs-parent={`#${props.dataBsParent}`}>
        <div className='card'>
          <div className='card-body'>
            <div className="row">
              <div className="col">
                <ul>
                  {props.actions?.map((item) => (
                    <li id={`${item.id}-${props.type}-li`} key={`${item.id}-${props.type}-li`}>
                      <button className='button-text button-icon'
                        id={`${item.id}-${props.type}-btn`}
                        onClick={() => toggleActionFilter(item.id)}>
                        {item.type}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

