import { useTranslation } from 'react-i18next';
import { getName } from '../common';

export default function Legend(props) {
  const { t } = useTranslation();
  const legend = {x1: 0 , y1: 20, x2: 240, y2: 20};
  const height = props.height;
  const width = props.width

  const colorStyle = 'trigger_stroke_legend';

  function agreementInfo(name) {
    return (
      <>
        {name.length > 35 ?
          <div title={name}>
            {getName(name, 35)}
          </div> :
          <div>
            {name}
          </div>
        }
      </>
    );

  }

  function agreementLegend(index) {
    return (
      <div className='row' id={`legend-agreement-${index}`}
        key={`legend-agreement-${index}`}>
        <div className='col-2 agreement_legend'>
          <div className={`legend_box stroke_${index}`} />
        </div>
        <div className='col-10 verified_legend'>
          {props.agreementNames[index] && props.agreementNames[index] !== ''
            ? agreementInfo(props.agreementNames[index])
            : <div className='verified_legend_description'>
                {t('agreementControlSystem.diagram.verified.selectagreement')}
              </div>}
        </div>
      </div>
    );
  }

  return (
    <div className='verified_diagram control-system'>
      <h2 className='toggle-header'>
        <button className='toggle-button button-icon'
          data-bs-toggle='collapse' data-bs-target='#toggle-legend'
          aria-controls='toggle-legend' aria-expanded='false'>Legend
        </button>
      </h2>
      <div id='toggle-legend' className='toggle-content collapse'>
        <div className='card'>
          <div className='card-body'>
            <div className='row'>
              <div className='col-1 col-sm-6 verified_legend_divider'>
                <div className='row' id='legend-triangle-right'
                  key='legend-triangle-right'>
                  <div className='col-6'>
                    <div className='triangle-right' />
                  </div>
                  <div className='col-6 verified_legend'>
                    Starts later / Ends before
                  </div>
                </div>
                <div className='row' id='legend-once' key='legend-once'>
                  <div className='col-6'>
                    <svg height={height} width={width} xmlns='http://www.w3.org/2000/svg'>
                      <g>
                        <line x1={legend.x1} y1={legend.y1}
                          x2={legend.x2} y2={legend.y2} className={`trigger-line ${colorStyle}`} />
                        <line x1={legend.x2/2-10} y1={legend.y1}
                          x2={legend.x2/2+10} y2={legend.y1}
                          className={`trigger-once ${colorStyle}`} />
                      </g>
                    </svg>
                  </div>
                  <div className='col-6'>
                    Once
                  </div>
                </div>
                <div className='row' id='legend-cyclical' key='legend-cyclical'>
                  <div className='col-6'>
                    <svg height={height} width={width} xmlns='http://www.w3.org/2000/svg'>
                      <g>
                        <line x1={legend.x1} y1={legend.y1}
                          x2={legend.x2} y2={legend.y2} className={`trigger-line ${colorStyle}`} />
                        <line x1={legend.x1} y1={legend.y1}
                          x2={legend.x2} y2={legend.y2} className={`trigger-quota ${colorStyle}`} />
                        <line x1={legend.x1} y1={legend.y1}
                          x2={legend.x2} y2={legend.y2}
                          className={`trigger-quota-fill ${colorStyle}`} />
                      </g>
                    </svg>
                  </div>
                  <div className='col-6'>
                    Quota
                  </div>
                </div>
                <div className='row' id='legend-quota' key='legend-quota'>
                  <div className='col-6'>
                    <svg height={height} width={width} xmlns='http://www.w3.org/2000/svg'>
                      <g>
                        <line x1={legend.x1} y1={legend.y1}
                          x2={legend.x2} y2={legend.y2} className={`trigger-line ${colorStyle}`} />
                        <line x1={legend.x1} y1={legend.y1}
                          x2={legend.x2} y2={legend.y2}
                          className={`trigger-cyclical ${colorStyle}`} />
                      </g>
                    </svg>
                  </div>
                  <div className='col-6'>
                    Cyclical
                  </div>
                </div>
                <div className='row' id='legend-continuous' key='legend-continuous'>
                  <div className='col-6'>
                    <svg height={height} width={width} xmlns='http://www.w3.org/2000/svg'>
                      <g>
                      <line x1={legend.x1} y1={legend.y1}
                        x2={legend.x2} y2={legend.y2}
                        className={`trigger-continuous ${colorStyle}`} />
                      </g>
                    </svg>
                  </div>
                  <div className='col-6'>
                    Continuous
                  </div>
                </div>
                <div className='row' id='legend-other' key='legend-other'>
                  <div className='col-6'>
                    <svg height={height} width={width} xmlns='http://www.w3.org/2000/svg'>
                      <g>
                        <rect x={legend.x1} y={legend.y1} width={legend.x2} height='10' fill='none'
                          className={`trigger_stroke_other ${colorStyle}`}/>
                      </g>
                    </svg>
                  </div>
                  <div className='col-6'>
                    Other
                  </div>
                </div>

              </div>
              <div className='col-11 col-sm-6'>
                {agreementLegend(0)}
                {agreementLegend(1)}
                {agreementLegend(2)}
                {agreementLegend(3)}
                {agreementLegend(4)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
