import { useState, useEffect, useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';
import ProfileHeader from './profileHeader';
import ContextBackground from './contextBackground';
import ProfileObligations from './profileObligations';
import AgreementAssociations from './agreementAssociations';
import ConsultationControls from './consultationControls';
import { getHTMLFromSitevisionObj, getTextFromSitevisionObj } from '../common';
import parseToHtml from 'html-react-parser';
import { getName, getIcon } from '../common';
import {removeLeadingZeros} from '../common';

export default function ProfilePage() {

  // Available agreements
  const [agreements, setAgreements] = useState([]);

  const [loader, setLoader] = useState(false);

  // Properties from database
  const [name, setName] = useState('');
  const [year, setYear] = useState('');
  const [nrStatesPartiesTotal, setNrStatesPartiesTotal] = useState('');
  const [weaponsItemsTopic, setWeaponsItemsTopic] = useState('');
  const [format, setFormat] = useState('');
  const [weaponsItems, setWeaponsItems] = useState('');
  const [facilitySpecified, setFacilitySpecified] = useState('');
  const [agreementAssociationsEstablishedTitle, setAgreementAssociationsEstablishedTitle] =
    useState('');
  const [agreementAssociationsUtilizedTitle, setAgreementAssociationsUtilizedTitle] = useState('');
  const [consultation, setConsultation] = useState('');
  const [demonstratedCompliance, setDemonstratedCompliance] = useState('');
  const [verifiedCompliance, setVerifiedCompliance] = useState('');

  // Properties from SiteVision
  const [contextAndBackgroundInfo, setcontextAndBackgroundInfo] = useState('');
  const [obligationsInfo, setObligationsInfo] = useState('');
  const [consultationAndControlsInfo, setConsultationAndControlsInfo] =
    useState('');
  const [agreementAssociationsInfo, setAgreementAssociationsInfo] = useState('');
  const [statusInfo, setStatusInfo] = useState('');
  const [regulationTypeInfo, setRegulationTypeInfo] = useState('');
  const [generalInfo, setGeneralInfo] = useState('');
  const [rightsInfo, setRightsInfo] = useState('');
  const [centralFiles, setCentralFiles] = useState([]);
  const [implementationFiles, setImplementationFiles] = useState([]);
  const [resourcesFiles, setResourcesFiles] = useState([]);

  async function getProfileData(id) {
    await fetchAgreements();
    await fetchSVPageContent(id);
  }

  useLayoutEffect(() => {
    // Start at the top
    window.scrollTo(0, 0)
  });

  useEffect(() => {
    const queryParams = window.location.search;
    const id = queryParams ? queryParams.replace('?id=', '') : '340';
    getProfileData(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Create elements for file download
  function getFileDownload(res, id) {
    const downloads = [];
    for (let i = 0; i < res?.length; i++) {
      downloads.push(
        <li id={`file${id}-li-${i}`} key={`file${id}-li-${i}`}>
          <Link to={res[i].url} download className='icon-position-left icon-download'
            target='_blank'>
            {res[i].name}
          </Link>
        </li>
      );
    }
    return downloads;
  }

  // Get all agreements from database
  const fetchSVPageContent = async (id) => {
    try {
      setLoader(true);
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      };
      const response =
        await fetch(`/api/sv/getProfilePageSVContent?agreementId=${id}`, requestOptions);
      const res = await response.json();

      if (res?.data) {
        // Set text collected from cards in Sitevision
        setcontextAndBackgroundInfo(parseToHtml(getHTMLFromSitevisionObj(
          res?.data?.find(({ name }) => name === 'ContextAndBackgroundInfo').properties)));
        setObligationsInfo(parseToHtml(getHTMLFromSitevisionObj(res?.data?.find(({ name }) =>
          name === 'ObligationsInfo').properties)));
        setConsultationAndControlsInfo(parseToHtml(getHTMLFromSitevisionObj(
          res?.data?.find(({ name }) => name === 'ConsultationsAndControlsInfo').properties)));
        setAgreementAssociationsInfo(parseToHtml(getHTMLFromSitevisionObj(
          res?.data?.find(({ name }) => name === 'AgreementAssociationsInfo').properties)));

        // Set text collected from Sitevision
        setStatusInfo(parseToHtml(getTextFromSitevisionObj(res?.data?.find(({ name }) =>
          name === 'StatusInfo').properties)));
        setRegulationTypeInfo(parseToHtml(getHTMLFromSitevisionObj(res?.data?.find(({ name }) =>
          name === 'RegulationTypeInfo').properties)));
        setGeneralInfo(parseToHtml(getHTMLFromSitevisionObj(res?.data?.find(({ name }) =>
          name === 'GeneralInfo').properties)));
        setRightsInfo(parseToHtml(getHTMLFromSitevisionObj(res?.data?.find(({ name }) =>
          name === 'RightsInfo').properties)));
      } else {
        setcontextAndBackgroundInfo('')
        setObligationsInfo('');
        setConsultationAndControlsInfo('');
        setAgreementAssociationsInfo('');
        setStatusInfo('');
        setRegulationTypeInfo('');
        setGeneralInfo('');
        setRightsInfo('');
      }
      const fileRes = await fetch(`/api/sv/getProfilePageSVFiles?agreementId=${id}`, requestOptions);
      const fileResJson = await fileRes.json();
      if (fileResJson) {
        setCentralFiles(getFileDownload(fileResJson?.central, 'Central'));
        setImplementationFiles(getFileDownload(fileResJson?.implementation, 'Implementation'));
        setResourcesFiles(getFileDownload(fileResJson?.resources, 'Resources'));
      } else {
        setCentralFiles('');
        setImplementationFiles('');
        setResourcesFiles('');
      }
      setLoader(false);
      fetchGetProfilePage(id);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoader(false);
    }
  };

   // Get all agreements from database
  const fetchAgreements = async () => {
    try {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      };
      const response = await fetch('/api/getAgreements', requestOptions);
      const res = await response.json();
      if (res?.data) {
        const agrees = JSON.parse(res.data).result;
        const agreementNames = []
        for (let i = 0; i < agrees.length; i++) {
          // Only give links for agreements with nucclear weapons
          if (agrees[i].weapons_items_topic === '0') {
            agreementNames.push({ name: agrees[i].name,
              tmp_id:
                parseInt(removeLeadingZeros(agrees[i].agreement_id.substring(0,3))),
              agreement_id: agrees[i].agreement_id,
              weapons_items_topic: agrees[i].weapons_items_topic,
              nuclear_weapons_free_zones: agrees[i].nuclear_weapons_free_zones,
              laterality: agrees[i].laterality});
          }
        }
        const sortedAgreements = agreementNames.sort((a, b) => a.tmp_id - b.tmp_id);

        let agreementOptions = [];
        for (let i = 0; i < sortedAgreements.length; i++) {
          // Only give links for agreements with nucclear weapons
          const name = getName(sortedAgreements[i].name, 35);
          const nameTitle = sortedAgreements[i].name.length > 35 ? sortedAgreements[i].name : '';
          const iconClass =
            getIcon(sortedAgreements[i].nuclear_weapons_free_zones, sortedAgreements[i].laterality);
          agreementOptions.push(
            <li className='list-group-item list-group-item-action' id={`agreement-li-${i}`}
              key={`agreement-li-${i}`}
              onClick={() => fetchSVPageContent(sortedAgreements[i].agreement_id)}>
              <Link to={`?id=${sortedAgreements[i].agreement_id}`}>
                <span className={`list-group-item-action-title treaty ${iconClass}`}
                  title={nameTitle}>
                  {name}
                </span>
              </Link>
            </li>
          );
        }
        setAgreements(agreementOptions);
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Get profile page data from database depending on selected agreement (id)
  const fetchGetProfilePage= async (id) => {
    try {
      const requestOptions = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
      };
      const response = await fetch(`/api/getProfilePage?id=${id}`, requestOptions);
      const res = await response.json();
      const reJson = JSON.parse(res.data);

      // Only render page if id exist in database
      if (res?.isOk && reJson.res[0].name !== '') {
        setName(reJson.res[0].name);
        setYear(reJson.res[0].year);
        setNrStatesPartiesTotal(reJson.res[0].nrStatesPartiesTotal);

        setWeaponsItemsTopic(reJson.res[0].weaponsItemsTopic);
        setFormat(reJson.res[0].format);
        setWeaponsItems(reJson.res[0].weaponsItems?.replaceAll('}',','));
        setFacilitySpecified(reJson.res[0].facilitySpecified?.replaceAll('}',','));
        setAgreementAssociationsEstablishedTitle(
          reJson.res[0].agreementAssociationsEstablishedTitle?.replaceAll('}',','));
        setAgreementAssociationsUtilizedTitle(
          reJson.res[0].agreementAssociationsUtilizedTitle?.replaceAll('}',','));

        setConsultation(reJson.res[0].consultations?.join(', '));
        setDemonstratedCompliance(reJson.res[0].demonstrateds?.join(', '));
        setVerifiedCompliance(reJson.res[0].verifieds?.join(', '));
      } else {
        window.location.href = "./notFound";
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <div>
      <ProfileHeader name={name} year={year} status={statusInfo}
        nrStatesPartiesTotal={nrStatesPartiesTotal}
        weaponsItemsTopic={weaponsItemsTopic} format={format} regulationType={regulationTypeInfo}
        agreements={agreements} />

      <div className='container mt-5'>
        <ContextBackground info={contextAndBackgroundInfo} centralFiles={centralFiles}
          implementationFiles={implementationFiles} resourcesFiles={resourcesFiles}
          loader={loader} />
        <ProfileObligations info={obligationsInfo} weaponsItemsTopic={weaponsItemsTopic}
          weaponsItems={weaponsItems} facilitySpecified={facilitySpecified}
          general={generalInfo} rights={rightsInfo} />
        <ConsultationControls info={consultationAndControlsInfo} consultation={consultation}
          demonstratedCompliance={demonstratedCompliance} verifiedCompliance={verifiedCompliance}/>
        <AgreementAssociations info={agreementAssociationsInfo}
          agreementAssociationsEstablishedTitle={agreementAssociationsEstablishedTitle}
          agreementAssociationsUtilizedTitle={agreementAssociationsUtilizedTitle}/>
      </div>
    </div>
  );
}