import { useTranslation } from 'react-i18next';

export default function ProfileObligations(props) {
  const { t } = useTranslation();

   return (
    <>
      <h2>{t('profile.obligations')}</h2>
      <div className='container-fluid jumbotron jumbotron-fluid'>
        <div className='row obligations-details'>
          <div className='col-lg-3'>
            <h3>{t('profile.topic')}:</h3>
            {props.weaponsItemsTopic}
            <h3>{t('profile.weaponSpecific')}:</h3>
            {props.weaponsItems}
          </div>
          <div className='col-lg-4'>
            <h3>{t('profile.facilitySpecific')}:</h3>
            {props.facilitySpecified}
            <h3>{t('profile.general')}:</h3>
            {props.general}
          </div>
          <div className='col-lg-5'>
            <h3>{t('profile.rights')}:</h3>
            {props.rights}
          </div>
        </div>
      </div>
      <div className='container mt-5'>
        <div className='row'>
          <div className='col-md-8'>
            {props.info}
          </div>
        </div>
      </div>
    </>
  );
}