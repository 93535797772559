import { useTranslation } from 'react-i18next';
import { useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import parseToHtml from 'html-react-parser';
import { getName, triggerTypes, initiationTypes, durationTypes } from '../common';
import PageButtons from '../pageButtons';

export default function DemonstratedTable(props) {
  const { t } = useTranslation();
  const currentLang = localStorage.getItem('i18nextLng') || 'sv';

  // Sort order, ascending = true, descending = false
  const [sortNameAsc, setSortNameAsc] = useState(true);
  const [sortCategoryAsc, setSortCategoryAsc] = useState(true);

  // Result
  const [results, setResults] = useState([]);

  // Result for demonstrated
  const [demonstratedFilters, setDemonstratedFilters] = useState(false);

  // Show or hide spinning loader
  const [loader, setLoader] = useState(false);

  // Current page
  const [currentPage, setCurrentPage] = useState(1);
  // Number of rows per page
  const [totalPages, setTotalPages] = useState(1);
  // Maximum of 5 pagination buttons
  const [pageItems, setPageItems] = useState([]);

  const [currentRequestSort, setCurrentRequestSort] =
  useState( {"name": 'name', "sortAcending": true });

  const demonstratedCategoryTypes = new Map();
  demonstratedCategoryTypes.set('0', 'Report');
  demonstratedCategoryTypes.set('1', 'Information-submission');
  demonstratedCategoryTypes.set('2', 'Information-exchange');
  demonstratedCategoryTypes.set('3', 'Notification-submission');
  demonstratedCategoryTypes.set('4', 'Other');

  const demonstratedMechanismTypes = new Map();
  demonstratedMechanismTypes.set('1', 'Consultation');
  demonstratedMechanismTypes.set('2', 'Demonstrated compliance');
  demonstratedMechanismTypes.set('3', 'Verified compliance');

  const demonstratedForumTypes = new Map();
  demonstratedForumTypes.set('0', 'Party to party');
  demonstratedForumTypes.set('1', 'Party to agreement-established association');
  demonstratedForumTypes.set('2', 'Party to utilized association');
  demonstratedForumTypes.set('3', 'Other');

  useEffect(() => {
    fetchDemonstratedControlsystem(currentPage, currentRequestSort);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update demonstrated data whenever page, sort order or any of the filters are changed
  useEffect(() => {
    fetchDemonstratedControlsystem(currentPage, currentRequestSort);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, currentRequestSort, props.agreementFilters, demonstratedFilters]);

  // Toggle 'all' demonstrated on and off
  function onDemonstratedToggleAll(isChecked) {
    document.getElementById('demonstratedReportId').checked = isChecked;
    document.getElementById('demonstratedSubmissionInfoId').checked = isChecked;
    document.getElementById('demonstratedExchangeInfoId').checked = isChecked;
    document.getElementById('demonstratedNotificationSubmissionId').checked = isChecked;
    document.getElementById('demonstratedOtherId').checked = isChecked;
    setDemonstratedFilters(!demonstratedFilters);
  }

  // Update check on 'all'
  function onDemonstratedUpdateAllCheck() {
    let isChecked = false;
    if (document.getElementById('demonstratedReportId').checked
      && document.getElementById('demonstratedSubmissionInfoId').checked
      && document.getElementById('demonstratedExchangeInfoId').checked
      && document.getElementById('demonstratedNotificationSubmissionId').checked
      && document.getElementById('demonstratedOtherId').checked) {
        isChecked = true;
    }
    document.getElementById('demonstatedAllId').checked = isChecked;
    setDemonstratedFilters(!demonstratedFilters);
  }

  function getPageItems(selectedPage, totPages) {
    const tot = totPages < 5 ? totPages : 5;
    const items = [];
    let startIndex = 1;
    if (selectedPage <= 3 || totPages <= 5) {
      startIndex = 1;
    } else if (totPages - selectedPage <= 2) {
      startIndex = selectedPage - 4 + (totPages - selectedPage);
    } else {
      startIndex = selectedPage - 2;
    }
    for(let i = startIndex; i < startIndex+tot; i +=1) {
      var pagingClass = currentPage === i ? 'page-link active' : 'page-link';
      items.push(
        <li className='page-item page-item-nav' id={`page-list-${i}`} key={`page-list-${i}`} >
          <Link id={`page-${i}`} key={`page-${i}`} className={pagingClass} onClick={() => {
            setCurrentPage(i);
            getPageItems(i, totPages);
          }}>
            {i}
          </Link>
        </li>
      );
    }
    return items;
  }

  function getInterval(triggerAgreement, quota, cyclical) {
    let interval = ''
    if (triggerAgreement === '1') {
      interval = ` - ${quota}`;
    } else if (triggerAgreement === '2') {
      interval = ` - ${cyclical}`;
    }
    return interval;
  }

  function getSubmission(submission, index) {
    let submissionStr = '';
    let phaseInfo = '';
    if (submission.dev.hasInfo) {
      submissionStr += submission.dev.info;
      phaseInfo += 'Development';
    }
    if (submission.testing.hasInfo) {
      submissionStr += submission.testing.info;
      phaseInfo += 'Testing';
    }
    if (submission.prod.hasInfo) {
      submissionStr += submission.prod.info;
      phaseInfo += 'Production';
    }
    if (submission.acquiring.hasInfo) {
      submissionStr += submission.acquiring.info;
      phaseInfo += 'Acquiring';
    }
    if (submission.possession.hasInfo) {
      submissionStr += submission.possession.info;
      phaseInfo += 'Possession';
    }
    if (submission.stationing.hasInfo) {
      submissionStr += submission.stationing.info;
      phaseInfo += 'Stationing';
    }
    if (submission.transfer.hasInfo) {
      submissionStr += submission.transfer.info;
      phaseInfo += 'Transfer';
    }
    if (submission.use.hasInfo) {
      submissionStr += submission.use.info;
      phaseInfo += 'Use';
    }
    if (submission.transfer.hasInfo) {
      submissionStr += submission.transfer.info;
      phaseInfo += 'Transfer';
    }
    if (submission.general.hasInfo) {
      submissionStr += submission.general.info;
      phaseInfo += 'General';
    }

    const submissions = [];
    if (submissionStr !== '' || phaseInfo !== '') {
      submissions.push(
        <span className='controlsystem-extra-info' title={submissionStr} id={`submissions-${index}`}
          key={`submissions-${index}`}>
          {phaseInfo}, <i>{getName(submissionStr, 35)}</i>
        </span>);
    }
    return (submissions);
  }

  // Filter agreement parties from database
  const fetchDemonstratedControlsystem = async (page, requestDemonstratedSort) => {
    try {
      const pSize = document.getElementsByName('rowsPerPageDemonstrated')[0].value;
      let demonstratedItems = [];
      const mechanism = {
        demonstratedCompliance: {
          isReport: document.getElementById('demonstratedReportId').checked,
          isSubmissionInfo: document.getElementById('demonstratedSubmissionInfoId').checked,
          isExchangeInfo: document.getElementById('demonstratedExchangeInfoId').checked,
          isNotifictionSubmission:
            document.getElementById('demonstratedNotificationSubmissionId').checked,
          isOther: document.getElementById('demonstratedOtherId').checked },
      };

      const checkboxes = document.getElementsByName('agreement-group-cs');
      const filterAgreements = [];
      for (let i = 0, n = checkboxes.length; i < n; i++) {
        if (checkboxes[i].checked) {
          const agreementId = checkboxes[i].value;
          filterAgreements.push(agreementId);
        }
      }

      const isNoMechanismSet = Object.values(mechanism).every(item => item === false);
      // Only fetch data when filter is set
      if (!isNoMechanismSet && filterAgreements.length > 0) {
        setLoader(true);
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ ids: filterAgreements, mechanism: mechanism, page: page,
            pageSize: pSize, sortCol: requestDemonstratedSort.name,
            sortAscending: requestDemonstratedSort.sortAscending })
        };

        // Demonstrated
        const demonstratedResponse =
          await fetch('/api/controlSystem/getDemonstrated', requestOptions);
        const resDemon = await demonstratedResponse.json();
        if (resDemon.isOk) {
          setTotalPages(resDemon.totalPages);
          setPageItems(getPageItems(page, resDemon.totalPages));

          let i = 0;
          resDemon.data?.forEach(item => {
            item.demonstratedCategoryStr = demonstratedCategoryTypes.get(item.category);
            item.demonstratedInitiationStr = initiationTypes.get(item.initiation);
            item.demonstratedDurationStr = durationTypes.get(item.duration);
            item.demonstratedTriggerAgreementStr =
              triggerTypes.get(item.triggerAgreement);
            item.demonstratedTriggerInterval = getInterval(item.triggerAgreement,
              item.quota, item.cyclical);
            item.demonstratedMechanismStr =
              demonstratedMechanismTypes.get(item.mechanism);
            item.demonstratedForumStr = demonstratedForumTypes.get(item.forum);
            item.demonstratedSubmission = getSubmission(item.submission, i);
            i += 1;
            demonstratedItems.push(item);
          });
          setResults({"items": demonstratedItems, "hits": resDemon.hits});
        }
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error('Error fetching data:', error);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage- 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };

  const handleRowsPerPage = (pageCount) => {
    fetchDemonstratedControlsystem(currentPage, currentRequestSort);
  };

  function filterItem(id, str, isAll) {
    return (
      <div className='form-group'>
        <div className='form-check'>
          <input
            className='form-check-input' type='checkbox' id={id}
            onChange={(e) => isAll ? onDemonstratedToggleAll(e.target.checked) :
            onDemonstratedUpdateAllCheck() }
          />
          <label className='form-check-label' htmlFor={id}>
            {isAll && <strong>{str}</strong>}
            {!isAll && str}
          </label>
        </div>
      </div>
    );
  }

  return (
    <section className='component' id='component-form-table'
      aria-labelledby='section-header-form-table'>

      <div className='col'>
        <div className='card agreement-control-system'>
          <div className='card-body bg-light'>
            <fieldset>
            <legend>{t('agreementControlSystem.demonstrated')}</legend>
              <div className='row'>
                <div className='col-md-4'>
                  {filterItem('demonstatedAllId', t('agreementControlSystem.all'), true)}
                  {filterItem('demonstratedReportId', t('agreementControlSystem.report'), false)}
                </div>
                <div className='col-md-4'>
                  {filterItem('demonstratedSubmissionInfoId',
                    t('agreementControlSystem.sumbmissioninfo'), false)}
                  {filterItem('demonstratedExchangeInfoId',
                    t('agreementControlSystem.exchangeinfo'), false)}
                </div>
                <div className='col-md-4'>
                  {filterItem('demonstratedNotificationSubmissionId',
                    t('agreementControlSystem.notificationsubmission'), false)}
                  {filterItem('demonstratedOtherId', t('agreementControlSystem.other'), false)}
                </div>
              </div>
            </fieldset>
            </div>
        </div>
      </div>

      <h2>{t('agreementControlSystem.table.result')} - {results?.hits ? results?.hits : 0}
        {` ${t('agreementControlSystem.table.hits')}`}</h2>
      <div className='row'>
        <div className='col-2'>
          <h3 id='section-header-form-table'>
            {t('agreementControlSystem.table.page')} {currentPage} ({totalPages})
          </h3>
        </div>
        <div className='col-3'>
          <label className='control-system-rowsperpage' htmlFor='rowsPerPageDemonstrated'>
            {t('agreementControlSystem.table.rowsperpage')}
          </label>
          <select name='rowsPerPageDemonstrated' id='rowsPerPageDemonstrated'
            onChange={(event) => handleRowsPerPage(event.target.value)}>
            <option value='10'>10</option>
            <option value='25'>25</option>
            <option value='50'>50</option>
          </select>
        </div>
        <div className='col-2'>
          <div className='loader amcdpLoader' style={{visibility: loader ? 'visible' : 'hidden' }} />
        </div>
      </div>

      <div className='anchor' id='table'></div>
      <div className='table-responsive'>
        <table className='table table-striped table-hover mb-4'>
          <thead>
            <tr>
              <th className='icon-position-right icon-sort tableHeader table-select' scope='col'
                onClick={() => {
                  setSortNameAsc(!sortNameAsc);
                  setCurrentRequestSort({"name": 'name', "sortAscending": sortNameAsc });
                }}>
                {t('agreementControlSystem.table.name')}
              </th>
              <th className='icon-position-right icon-sort tableHeader table-select' scope='col'
                onClick={() => {
                  setSortCategoryAsc(!sortCategoryAsc);
                  setCurrentRequestSort({"name": 'category', "sortAscending": sortCategoryAsc });
                }}>
                {t('agreementControlSystem.table.demonstrated.category')}
              </th>
              <th scope='col'>
                {t('agreementControlSystem.table.demonstrated.duration')}
              </th>
              <th scope='col'>
                {t('agreementControlSystem.table.demonstrated.interval')}
              </th>
              <th>
                {t('agreementControlSystem.table.demonstrated.mechanismid')}
              </th>
              <th scope='col'>
                {t('agreementControlSystem.table.demonstrated.forum')}
              </th>
              <th scope='col'>
                {t('agreementControlSystem.table.demonstrated.submission')}
              </th>
            </tr>
          </thead>
          <tbody>
            {results?.items?.map((item, i) => (
              <tr id={`cs-${item.agreement_id}-${i}`} key={`cs-${item.agreement_id}-${i}`}>
                <td>
                  { item.name.length > 35 ?
                    <Link to={`../${currentLang}/profilePage?id=${item.agreement_id}`} className=''
                      title={item.name}>
                      {getName(item.name, 35)}
                    </Link> :
                    <Link to={`../${currentLang}/profilePage?id=${item.agreement_id}`} className=''>
                      {getName(item.name, 35)}
                    </Link>
                  }
                </td>
                <td>{item.demonstratedCategoryStr}</td>
                <td>{item.demonstratedInitiationStr} {parseToHtml('&ndash;')}
                  {item.demonstratedDurationStr}</td>
                <td>{item.demonstratedTriggerAgreementStr}{item.demonstratedTriggerInterval}</td>
                <td>{item.demonstrated_mechanism_id}</td>
                <td>{item.demonstratedForumStr}</td>
                <td>{item.demonstratedSubmission}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <PageButtons handleFirstPage={handleFirstPage} handlePrevPage={handlePrevPage}
        handleNextPage={handleNextPage} handleLastPage={handleLastPage} currentPage={currentPage}
        totalPages={totalPages} pageItems={pageItems} />
    </section>
  );
}