import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
const { forwardRef, useImperativeHandle } = React;

const CurrentDataInDatabase = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const [status, setStatus] = useState([]);

  useEffect(() => {
    fetchDataTableStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useImperativeHandle(ref, () => ({
    f() {
      fetchDataTableStatus();
    }
  }));

  const fetchDataTableStatus = async () => {
    try {
      const token = props.token;
      const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' };
      const requestOptions = {
        method: 'GET',
        headers: headers
      };
      const response = await fetch('/api/getDataTableStatus', requestOptions);
      const res = await response.json();
      if (res.isOk) {
        const tableStatus = await JSON.parse(res.data)?.status;
        const statusRows = [];
        for (let i = 0; i < tableStatus?.length; i += 1) {
          statusRows.push(
            <tr key={`currentTables-${i}`}>
              <td>{tableStatus[i].Name}</td>
              <td>{tableStatus[i].Rows}</td>
              <td>{tableStatus[i].Create_time}</td>
              <td>{tableStatus[i].Update_time}</td>
            </tr>
          );
        };
        setStatus(statusRows);
      } else {
        setStatus(t('db.fail'));
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
<section className='component mb-5'>
  <div className='row'>
    <div className='col-md'>
      <div className='card'>
        <h2 className='toggle-header'>
          <button className='toggle-button button-icon' data-bs-toggle='collapse'
            data-bs-target='#toggle-db-status' aria-controls='toggle-db-status'
            aria-expanded='false'>
            {t('db.currentDataInDatabase')}
          </button>
        </h2>
        <div id='toggle-db-status' className='toggle-content collapse'>
          <div className='card'>
            <div className='card-body'>
              <div className='table-responsive'>
                <table className='table table-striped table-hover'>
                  <thead>
                    <tr id='db-table-result' key='db-table-result'>
                      <th scope='col'>{t('db.table')}</th>
                      <th scope='col'>{t('db.countRecords')}</th>
                      <th scope='col'>{t('db.createDate')}</th>
                      <th scope='col'>{t('db.updateDate')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {status}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
  );
});

export default CurrentDataInDatabase;