import { useTranslation } from 'react-i18next';
import CurrentDataInDatabase from './currentData';
import ReplaceData from './replaceData';
import { useRef } from "react";

function LoadDatabase() {
  const { t } = useTranslation();
  const currentDb = useRef(null);

  const tokenString = sessionStorage.getItem('token');
  const token = JSON.parse(tokenString);

  return (
  <div className='container mt-5'>
    <h1>{t('db.title')}</h1>
    <CurrentDataInDatabase ref={currentDb} token={token}/>
    <ReplaceData token={token} callCurrentDb={() => {
      currentDb.current.f();
    }}/>
  </div>
  );
}

export default LoadDatabase;