import { useTranslation } from 'react-i18next';
import instructionImageGeneral from '../../img/controlsystem-instruction-general.jpg';
import instructionImageVerified from '../../img/controlsystem-instruction-verified.jpg';

const InstructionDropdown = () => {
  const { t } = useTranslation();

  return (
    <div className='instruction'>
      <h2 className='toggle-header'>
        <button className='toggle-button button-icon'
          data-bs-toggle='collapse' data-bs-target='#toggle-cs-instruction'
          aria-controls='toggle-cs-instruction' aria-expanded='false'>
          {t('obligations.instruction')}
        </button>
      </h2>
      <div id='toggle-cs-instruction' className='toggle-content collapse'>
        <div className='card'>
          <div className='card-body'>
            <h3>{t('agreementControlSystem.instruction.title')}</h3>
            <div className='row mb-4 pb-4'>
              <div className='col-md-5'>
                <img src={instructionImageGeneral} alt='Introduction to using control system diagram'
                  style={{ maxWidth: '100%' }} />
              </div>
              <div className='col-md-7'>
                <p>{t('agreementControlSystem.instruction.description_1')}</p>
                <p>{t('agreementControlSystem.instruction.description_2')}</p>

                <h4>{t('agreementControlSystem.instruction.consultation.title')}</h4>
                <p>{t('agreementControlSystem.instruction.consultation.description_1')}</p>
                <p>{t('agreementControlSystem.instruction.consultation.description_2')}</p>
                <p>{t('agreementControlSystem.instruction.consultation.description_3')}</p>

                <h4>{t('agreementControlSystem.instruction.demonstrated.title')}</h4>
                <p>{t('agreementControlSystem.instruction.demonstrated.description_1')}</p>
                <span>
                  {t('agreementControlSystem.instruction.demonstrated.description_2')}
                  <ol className='controlsystem-ex'>
                    <li>{t('agreementControlSystem.instruction.demonstrated.description_li_1')}</li>
                    <li>{t('agreementControlSystem.instruction.demonstrated.description_li_2')}</li>
                  </ol>
                </span>
                <p>{t('agreementControlSystem.instruction.demonstrated.description_3')}</p>
              </div>
            </div>
            <div className='row mb-4 pb-4'>
              <div className='col-md-5'>
                <img src={instructionImageVerified} alt='Graph illustrates five chosen agreements.'
                  style={{ maxWidth: '100%' }} />
              </div>
              <div className='col-md-7'>
                <h4>{t('agreementControlSystem.instruction.verified.title')}</h4>
                <p>{t('agreementControlSystem.instruction.verified.description_1')}</p>
                <p>{t('agreementControlSystem.instruction.verified.description_2')}</p>
                <p><i>{t('agreementControlSystem.instruction.verified.description_3')}</i></p>
              </div>
            </div>
          </div>
      </div>
      </div>
    </div>
  );
};

export default InstructionDropdown;
