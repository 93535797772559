import React, { useState, useEffect } from 'react';
import BrowseFile from './browseFile';
import { useTranslation } from 'react-i18next';

function ReplaceData(props) {
  const { t } = useTranslation();
  const token = props.token;

  const [agreementFile, setAgreementFile] = useState('');
  const [weaponFacilitytFile, setWeaponFacilityFile] = useState('');
  const [consultationFile, setConsultationFile] = useState('');
  const [demonstratedFile, setDemonstratedFile] = useState('');
  const [verifiedFile, setVerifiedFile] = useState('');
  const [associationFile, setAssociationFile] = useState('');
  const [countryFile, setCountryFile] = useState('');
  const [loader, setLoader] = useState(false);
  const [status, setStatus] = useState('');
  const [selectedFileCount, setSelectedFileCount] = useState('');

  // File select status
  const [agreementSelectStatus, setAgreementSelectStatus] = useState(`${t('db.noFileSelected')}`);
  const [weaponFacilitySelectStatus, setWeaponFacilitySelectStatus] =
    useState(`${t('db.noFileSelected')}`);
  const [consultationSelectStatus, setConsultationSelectStatus] =
    useState(`${t('db.noFileSelected')}`);
  const [demonstratedSelectStatus, setDemonstratedSelectStatus] =
    useState(`${t('db.noFileSelected')}`);
  const [verifiedSelectStatus, setVerifiedSelectStatus] = useState(`${t('db.noFileSelected')}`);
  const [associationFileSelectStatus, setAssociationFileSelectStatus] =
    useState(`${t('db.noFileSelected')}`);
  const [countryFileSelectStatus, setCountryFileSelectStatus] =
    useState(`${t('db.noFileSelected')}`);

  // Loading status
  const [statusAgreement, setStatusAgreement] = useState('');
  const [statusWeapon, setStatusWeapon] = useState('');
  const [statusConsultation, setStatusConsultation] = useState('');
  const [statusDemonstrated, setStatusDemonstrated] = useState('');
  const [statusVerified, setStatusVerified] = useState('');
  const [statusAssociation, setStatusAssociation] = useState('');
  const [statusCountry, setStatusCountry] = useState('');

  useEffect(() => {
    arrangeSelectedFileCount();
  }, [agreementFile, weaponFacilitytFile, consultationFile, demonstratedFile, verifiedFile,
    associationFile, countryFile]);

  async function fetchUploadData(agreementFile, weaponFacilityFile, consultationFile,
    demonstratedFile, verifiedFile, associationFile, countryFile) {
    agreementFile ? setStatusAgreement(t('db.waiting')) : setStatusAgreement('');
    weaponFacilityFile ? setStatusWeapon(t('db.waiting')) : setStatusWeapon('');
    consultationFile ? setStatusConsultation(t('db.waiting')) : setStatusConsultation('');
    demonstratedFile ? setStatusDemonstrated(t('db.waiting')) : setStatusDemonstrated('');
    verifiedFile ? setStatusVerified(t('db.waiting')) : setStatusVerified('');
    associationFile ? setStatusAssociation(t('db.waiting')) : setStatusAssociation('');
    countryFile ? setStatusCountry(t('db.waiting')) : setStatusCountry('');

    if (agreementFile) {
      setStatusAgreement(t('db.fileIsLoading'));
      const uploadStatus = await fetchUploadFile(agreementFile, '/api/upLoad/AgreementFile');
      setStatusAgreement(uploadStatus.message);
    }
    if (weaponFacilityFile) {
      setStatusWeapon(t('db.fileIsLoading'));
      const uploadStatus = await fetchUploadFile(weaponFacilityFile,
        '/api/upLoad/WeaponAndFacilitiesFile');
      setStatusWeapon(uploadStatus.message);
    }
    if (consultationFile) {
      setStatusConsultation(t('db.fileIsLoading'));
      const uploadStatus = await fetchUploadFile(consultationFile, '/api/upLoad/ConsultationFile');
      setStatusConsultation(uploadStatus.message);
    }
    if (demonstratedFile) {
      setStatusDemonstrated(t('db.fileIsLoading'));
      const uploadStatus = await fetchUploadFile(demonstratedFile, '/api/upLoad/DemonstratedFile');
      setStatusDemonstrated(uploadStatus.message);
    }
    if (verifiedFile) {
      setStatusVerified(t('db.fileIsLoading'));
      const uploadStatus = await fetchUploadFile(verifiedFile, '/api/upLoad/VerifiedFile');
      setStatusVerified(uploadStatus.message);
    }
    if (associationFile) {
      setStatusAssociation(t('db.fileIsLoading'));
      const uploadStatus = await fetchUploadFile(associationFile, '/api/upLoad/AssociationFile');
      setStatusAssociation(uploadStatus.message);
    }
    if (countryFile) {
      setStatusCountry(t('db.fileIsLoading'));
      const uploadStatus = await fetchUploadFile(countryFile, '/api/upLoad/CountryFile');
      setStatusCountry(uploadStatus.message);
    }
  };

  async function fetchUploadFile(file, uri) {
    if (file) {
      const headers = { 'Authorization': `Bearer ${token}` };
      const data = new FormData();
      data.append('files', file);
      setLoader(true);
      const requestOptions = {
          method: 'POST',
          headers: headers,
          body: data
      };

      const response = await fetch(uri, requestOptions);
      const res = await response.json();
      setLoader(false);
      let uploadStatus = '';
      if (res.isOk) {
        // Update status of data base tables
        props.callCurrentDb();
        uploadStatus = t('db.fileIsUploaded');
      } else {
        uploadStatus = t('db.browse.fail');
      }
      setStatus(uploadStatus);
      return { isOk: res.isOk, message: uploadStatus};
    }
  };

  function arrangeSelectedFileCount() {
    let count = 0;
    agreementFile.name === undefined
      ? setAgreementSelectStatus(`${t('db.noFileSelected')}`)
      : setAgreementSelectStatus(agreementFile.name);
    weaponFacilitytFile.name === undefined
      ? setWeaponFacilitySelectStatus(`${t('db.noFileSelected')}`)
      : setWeaponFacilitySelectStatus(weaponFacilitytFile.name);
    consultationFile.name === undefined
      ? setConsultationSelectStatus(`${t('db.noFileSelected')}`)
      : setConsultationSelectStatus(consultationFile.name);
    demonstratedFile.name === undefined
      ? setDemonstratedSelectStatus(`${t('db.noFileSelected')}`)
      : setDemonstratedSelectStatus(demonstratedFile.name);
    verifiedFile.name === undefined
      ? setVerifiedSelectStatus(`${t('db.noFileSelected')}`)
      : setVerifiedSelectStatus(verifiedFile.name);
    associationFile.name === undefined
      ? setAssociationFileSelectStatus(`${t('db.noFileSelected')}`)
      : setAssociationFileSelectStatus(associationFile.name);
    countryFile.name === undefined
      ? setCountryFileSelectStatus(`${t('db.noFileSelected')}`)
      : setCountryFileSelectStatus(countryFile.name);

    if (agreementFile.name !== undefined ) {
      count++;
    }
    if (weaponFacilitytFile.name !== undefined ) {
      count++;
    }
    if (consultationFile.name !== undefined ) {
      count++;
    }
    if (demonstratedFile.name !== undefined ) {
      count++;
    }
    if (verifiedFile.name !== undefined ) {
      count++;
    }
    if (associationFile.name !== undefined ) {
      count++;
    }
    if (countryFile.name !== undefined ) {
      count++;
    }
    setSelectedFileCount(count);
  }

  function clearSelectedFiles() {
    setAgreementFile('');
    setWeaponFacilityFile('');
    setConsultationFile('');
    setDemonstratedFile('');
    setVerifiedFile('');
    setAssociationFile('');
    setCountryFile('');
    setSelectedFileCount(0);
    setStatusAgreement('');
    setStatusWeapon('');
    setStatusConsultation('');
    setStatusDemonstrated('');
    setStatusVerified('');
    setStatusAssociation('');
    setStatusCountry('');
  }

  return (
<section className='component mb-5'>
  <div className='col-md'>
    <div className='card'>
      <h2 className='card-header'>
        {t('db.replaceData')}
      </h2>
      <div className="card-body">
        <div className='row mb-4 pb-4'>
          <p>{t('db.csvInstruction')}</p>
          <ol className='uploadList'>
            <li>{t('db.csvInstruction.step1')}</li>
            <li>{t('db.csvInstruction.step2')}</li>
          </ol>
        </div>
        <BrowseFile passFile={setAgreementFile} status={statusAgreement}
          selectionStatus={agreementSelectStatus} title='Agreement' />
        <BrowseFile passFile={setWeaponFacilityFile} status={statusWeapon}
          selectionStatus={weaponFacilitySelectStatus} title='Weapon and facility' />
        <BrowseFile passFile={setConsultationFile} status={statusConsultation}
          selectionStatus={consultationSelectStatus} title='Consultation' />
        <BrowseFile passFile={setDemonstratedFile} status={statusDemonstrated}
          selectionStatus={demonstratedSelectStatus} title='Demonstrated' />
        <BrowseFile passFile={setVerifiedFile} status={statusVerified}
          selectionStatus={verifiedSelectStatus} title='Verified' />
        <BrowseFile passFile={setAssociationFile} status={statusAssociation}
          selectionStatus={associationFileSelectStatus} title='Association' />
        <BrowseFile passFile={setCountryFile} status={statusCountry} selectionStatus={countryFileSelectStatus} title='Country' />
        <div className='row mt-2'>
          <div className='row'>
            <div className='col-2'>
              {t('db.selectedFiles')}: {selectedFileCount}
            </div>
            <div className='col'>
            <button
              disabled={agreementFile === '' && weaponFacilitytFile === ''
                && consultationFile === '' && demonstratedFile === '' && verifiedFile === ''
                && associationFile === '' && countryFile === ''}
              onClick={() => clearSelectedFiles()}>
                {t('db.clearSelectedFiles')}
            </button>
            </div>
          </div>
        </div>
        <div className='row mt-2'>
          <div className='col-2'>
            <button
              disabled={agreementFile === '' && weaponFacilitytFile === ''
                && consultationFile === '' && demonstratedFile === '' && verifiedFile === ''
                && associationFile === '' && countryFile === ''}
              onClick={() => fetchUploadData(agreementFile, weaponFacilitytFile,
                consultationFile, demonstratedFile, verifiedFile, associationFile, countryFile)}>
                {t('db.upload')}
            </button>
          </div>
          <div className='col-2'>
            {loader && (<div className='loader amcdpLoader' />)}
          </div>
        </div>
      </div>
      <p>{status}</p>
    </div>
  </div>
</section>
  );
}

export default ReplaceData;