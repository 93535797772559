import {Link} from 'react-router-dom';

function PageButtons(props) {
  return (
    <nav aria-label='Sidnavigering för sökresultatet'
      className='d-flex justify-content-center'>
      <ul className='pagination'>
        <li className='page-item page-item-nav'
          style={props.currentPage === 1 ? {pointerEvents: 'none', opacity:0.6}: {}}>
          { props.currentPage === 1 ?
            <div className='page-link icon-position-center icon-first' title='First page' /> :
            <Link className='page-link icon-position-center icon-first' title='First page'
              onClick={props.handleFirstPage} />
          }
        </li>
        <li className='page-item page-item-nav'
          style={props.currentPage === 1 ? {pointerEvents: 'none', opacity:0.6}: {}}>
          { props.currentPage === 1 ?
            <div className='page-link icon-position-center icon-left' title='Previous page' /> :
            <Link className='page-link icon-position-center icon-left' title='Previous page'
              onClick={props.handlePrevPage} disabled={props.currentPage === 1} />
          }
        </li>
        {props.pageItems}
        <li className='page-item page-item-nav'
          style={props.currentPage === props.totalPages ? {pointerEvents: 'none', opacity:0.6}: {}}>
          { props.currentPage === props.totalPages ?
            <div className='page-link icon-position-center icon-right' title='Next page' /> :
            <Link className='page-link icon-position-center icon-right' title='Next page'
              onClick={props.handleNextPage} disabled={props.currentPage === props.totalPages} />
          }
        </li>
        <li className='page-item page-item-nav'
          style={props.currentPage === props.totalPages ? {pointerEvents: 'none', opacity:0.6}: {}}>
          { props.currentPage === props.totalPages ?
            <div className='page-link icon-position-center icon-last' title='Last page' /> :
            <Link className='page-link icon-position-center icon-last' title='Last page'
              onClick={props.handleLastPage} disabled={props.currentPage === props.totalPages} />
          }
        </li>
      </ul>
    </nav>
  );
}

export default PageButtons;
