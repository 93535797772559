import { useTranslation } from 'react-i18next';

export default function AgreementAssociations(props) {
  const { t } = useTranslation();

   return (
    <>
    <h2>{t('profile.agreementAssociations')}</h2>
    <div className='container-fluid jumbotron jumbotron-fluid'>
      <div className='row obligations-details'>
        <div className='col-lg-3'>
          <h3>{t('profile.established')}:</h3>
          {props.agreementAssociationsEstablishedTitle}
        </div>
          <div className='col-lg-4'>
            <h3>{t('profile.utilized')}:</h3>
            {props.agreementAssociationsUtilizedTitle}
          </div>
          <div className='col-lg-5'>
        </div>
      </div>
    </div>
    <div className='container mt-5'>
      <div className='row'>
        <div className='col-md-8'>
          {props.info}
        </div>
      </div>
    </div>
    </>
  );
}