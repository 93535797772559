import { useTranslation } from 'react-i18next';
import { useState, useEffect} from 'react';
import Diagram from './diagram';
import Table from './table';
import { getName, getAgreementInColumns } from '../common';

function AgreementControlSystem() {
  const { t } = useTranslation();

  // Agreement
  let agreementCount = 0;
  let filterAgreements = [];
  const [agreementFilters, setAgreementFilters] = useState([]);
  const [agreementOptions, setAgreementOptions] = useState([]);

  // Toggle 'all' on and off
  function toggleAll(id, isChecked) {
    const checkboxes = document.getElementsByName(id);
    const s = [];
    for (let i = 0, n = checkboxes.length; i < n; i++) {
      checkboxes[i].checked = isChecked;
      if (isChecked) {
        const agreementId = checkboxes[i].value;
        s.push(agreementId);
      }
    }
    return s;
  }

  // Toggle 'all' agreements on and off
  async function onAgreementToggleAll(isChecked) {
    const agreements = toggleAll('agreement-group-cs', isChecked);
    let s = [];
    agreements.forEach((agreement) => s.push(agreement));
    filterAgreements = agreements;
    setAgreementFilters(s);
  }

  // Toggle checkbox
  function toggleCheckbox(id, filters, idAll, count) {
    const s = filters;
    const agreementId = document.getElementById(`checkControlSystem-${id}`).value;
    const findIdx = filters.indexOf(agreementId);
    if (findIdx > -1) {
      s.splice(findIdx, 1);
      // Uncheck 'all'
      document.getElementById(idAll).checked = false;
    } else {
      s.push(agreementId);
      // Check 'all' if all boxes are cheded
      if (s.length >= count) {
        document.getElementById(idAll).checked = true;
      }
    }
    return s;
  }

  const onAgreementChange = id => {
    const s = toggleCheckbox(id, filterAgreements, 'checkControlSystem-agreement-control-all',
      agreementCount);
    setAgreementFilters(prevNames => [...s])
  };

  useEffect(() => {
    fetchAgreements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function checkboxAll(id, onChange, selected, str) {
    return (
      <div className='form-group' key={`${id}-1`}>
        <div className='form-check'>
          <input
            className='form-check-input' type='checkbox' id={`checkControlSystem-${id}`}
            onChange={(e) => onChange(e.target.checked)} selected={selected}
          />
          <label className='form-check-label' htmlFor={`checkControlSystem-${id}`}
            id={`checkControlSystem-label-${id}`}>
            <strong>{str}</strong>
          </label>
        </div>
      </div>
    );
  }

  function checkbox(id, onChange, selected, str, title, value, name, isEnabled) {
    const checkbox = [];
    if (isEnabled) {
      checkbox.push(
        <div className='form-group' key={`${id}-1`}>
          <div className='form-check'>
            <input
              className='form-check-input' type='checkbox' id={`checkControlSystem-${id}`}
              onChange={() => onChange(id)} selected={selected} name={name}
              value={value}
            />
            <label className='form-check-label' htmlFor={`checkControlSystem-${id}`} title={title}>
              {str}
            </label>
          </div>
        </div>
      );
    } else {
      checkbox.push(
        <div className='form-group' key={`${id}-1`}>
          <div className='form-check disabledControlSystemAgreement'>
            <input
              className='form-check-input' type='checkbox' id={`checkControlSystem-${id}`}
              value={value}
            />
            <label className='form-check-label' htmlFor={`checkControlSystem-${id}`} title={title}>
              {str}
            </label>
          </div>
        </div>
      );
    }
    return checkbox;
  }

  function setAgreementAllLabel(text) {
    document.getElementById('checkControlSystem-label-agreement-control-all')
      .innerHTML=`<strong>${text}</strong>`;
  }

  function populateList(agreements, key, csIds) {
    const items = [];
    if (key === 'agreem-1') {
      const id = 'agreement-control-all';
      items.push(
        checkboxAll(id, onAgreementToggleAll, agreementFilters.includes(id),
          t('agreementParties.all'))
      );
    }

    for (let i = 0; i < agreements.length; i += 1) {
      const name = getName(agreements[i].name, 35);
      const nameTitle = agreements[i].name.length > 35 ? agreements[i].name : '';
      const id = `agreement-control-${agreements[i].name}-${i}`;
      const isEnabled = csIds?.includes(agreements[i].agreement_id);
      items.push(checkbox(id, onAgreementChange, agreementFilters.includes(id), name,
        nameTitle, agreements[i].agreement_id, 'agreement-group-cs', isEnabled));
    }

    return (
      <div className=' col-md-4 mb-0' key={key}>
        <ul className='list-group list-group-flush'>
          {items}
        </ul>
      </div>
    );
  }

  // Get all agreements from database
  const fetchAgreements = async () => {
    try {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      };
      // Find valid agreements
      const responseCS = await fetch('/api/controlSystem/getInfo', requestOptions);
      const resCS = await responseCS.json();
      const csIds = await JSON.parse(resCS.data);

      // Get all agreements
      const response = await fetch('/api/getAgreements', requestOptions);
      const res = await response.json();
      const agreements = JSON.parse(res.data).result;
      if (agreements && agreements?.length > 0) {
        agreementCount = agreements?.length;
        agreements?.sort((a, b) => a.name.localeCompare(b.name));

        const agreementsInColumns = getAgreementInColumns(agreements);
        const colsWithAgreements = [];
        colsWithAgreements
          .push(populateList(agreementsInColumns[0].column1, 'agreem-1', csIds));
        colsWithAgreements
          .push(populateList(agreementsInColumns[0].column2, 'agreem-2', csIds));
        colsWithAgreements
          .push(populateList(agreementsInColumns[0].column3, 'agreem-3', csIds));

        const agreementOptions2 = [];
        agreementOptions2.push(
          <div className='treatylist' key='agreementDb'>
            <div className='row'>{colsWithAgreements}</div>
          </div>
        );
        setAgreementOptions(agreementOptions2);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <div>
      <div className='row'>
        <div id='agreement' className=''>
          <div className='card'>
            <div className='card-body overflow-scroll height-250 bg-light'>
              <div className='row'>
                <fieldset>
                  <legend>{t('agreementControlSystem.agreementFilter.title')}</legend>
                  {agreementOptions}
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row pt-4'>
        <nav className='col-md-8 pt-3 mb-4'>
         <div className='chart-table-nav'>
            <ul className='nav nav-tabs' id='visualizerTab' role='tablist'>
              <li className='nav-item text-center' role='presentation'>
                <button className='nav-link button-icon button-text icon-chart'
                  id='chart-tab' data-bs-toggle='tab' data-bs-target='#chart-control-system'
                  type='button' role='tab' aria-controls='chart' aria-selected='false' tabIndex='-1'
                  onClick={() => {setAgreementAllLabel('All (Only 5 agreements will be drawn)')}}>
                  {t('agreementParties.chart')}
                </button>
              </li>
              <li className='nav-item text-center' role='presentation'>
                <button className='nav-link button-icon button-text icon-table active' id='table-tab'
                  data-bs-toggle='tab' data-bs-target='#table-control-system'
                  type='button' role='tab' aria-controls='table' aria-selected='true'
                  onClick={() => {setAgreementAllLabel(`<strong>${t('agreementParties.all')}</strong>`)}}>
                  {t('agreementParties.table')}
                </button>
              </li>
            </ul>
          </div>
        </nav>


        <div className='col-12'>
          <div className='col-12 chart-table tab-content' id='visualizerTabContent'>
            <div className='tab-pane fade' id='chart-control-system' role='tabpanel'
              aria-labelledby='chart-tab'>
              <Diagram agreementFilters={agreementFilters} />
            </div>
            <div className='tab-pane fade show active' id='table-control-system' role='tabpanel'
              aria-labelledby='table-tab'>
              <Table agreementFilters={agreementFilters} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AgreementControlSystem;