import {Link} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function NotFound() {
  const {t} = useTranslation();

  return (
<div className='container mt-5'>
  <h1>{t('noAccess.title')}</h1>
  <p>{t('noAccess.description')}</p>
  <ul>
    <li>
      <Link to='.' className=''>{t('header.title')}</Link>
    </li>
  </ul>
</div>
  );
}

export default NotFound;