import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function ResetPwd() {
  const { t } = useTranslation();
  const [password, setPassword] = useState('');
  const [userName, setUserName] = useState('');
  const [status, setStatus] = useState('');
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const [warningTitle, setWarningTitle] = useState('Warning');
  const [warning, setWarning] = useState('');

  async function resetPwd(user) {
    const tokenString = sessionStorage.getItem('token');
    const token = JSON.parse(tokenString);
    const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' };
    const requestOptions = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(user)
    };
    const response = await fetch('/api/user/resetPassword', requestOptions);
    const res = await response.json();
    return res;
  }

  const handlePwdReset = async (e) => {
    e.preventDefault();
    const res = await resetPwd({
      email: userName,
      password
    });
    if (res.isOk) {
      document.getElementById('loginwarning').style.display='none';
    } else {
      setWarningTitle(t('resetPwd.fail'));
      setWarning(t('resetPwd.fail.help'));
      document.getElementById('loginwarning').style.display='block';
    }
  };

  async function getUser() {
    const tokenString = sessionStorage.getItem('token');
    const token = JSON.parse(tokenString);
    const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' };
    const requestOptions = {
      method: 'POST',
      headers: headers
    };
    const response = await fetch('/api/user/getUser', requestOptions);
    const res = await response.json();
    setUserName(res.data.userId);
  }

  useEffect(() => {
    document.getElementById('loginwarning').style.display='none';
    const tokenString = sessionStorage.getItem('token');
    if (!tokenString || tokenString === '') {
      setStatus(t('logout.already'));
      setIsLoggedOut(true);
    } else {
      getUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='container mt-5'>
      <h1>{t('resetPwd.title')}</h1>
      <div className='alert alert-warning' role='alert' id='loginwarning'>
        <h2 className='icon-position-left icon-warning'>{warningTitle}</h2>
        <p>{warning}</p>
      </div>
      <form onSubmit={handlePwdReset}>
        <p>{`${t('resetPwd.currentUser')}: ${userName}`}</p>
        <p>
          <label className='form-label' htmlFor='password'>{t('resetPwd.newPassword')}</label>
          <input type='password' id='password' name='password' className='form-control'
            placeholder={t('resetPwd.password')} required='' autoFocus=''
            onChange={(e) => setPassword(e.target.value)} />
        </p>
        <button type='submit' disabled={isLoggedOut}>{t('resetPwd.title')}</button>
      </form>
      <p>{status}</p>
    </div>
  );
}