function Footer() {
  return (
<footer className='layout-footer '>
    <div className='layout-footer-bg-img no-printme'></div>
    <div className='container mt-5'>
        <div className='row layout-footer-last-row no-printme'>
            <div className='col-12'>
                <article className='footer-site-information'
                    aria-label='Kontakt- och organisationsinformation för Uppsala universitet'>
                    <address>
                        <span className='footer-site-entry'>© Uppsala universitet</span>
                        <span className='footer-site-entry'>Telefon:&nbsp;
                            <span className='tel'>018-471 00 00</span>
                        </span>
                        <span className='footer-site-entry'>Box 256, 751 05 Uppsala</span>
                    </address>
                    <p>
                        <span className='footer-site-entry'>Organisationsnummer:&nbsp;202100-2932
                        </span>
                        <span className='footer-site-entry'>
                            Momsregistreringsnummer:&nbsp;SE202100293201
                        </span>
                        <span
                            className='footer-site-entry'>PIC:&nbsp;999985029</span>
                        <span className='footer-site-entry'><a
                            href='http://www.uu.se/om-uu/kontakta-oss/registrator'>
                                Registrator</a>
                        </span>
                        <span className='footer-site-entry'>
                            <a href='http://www.uu.se/om-webbplatsen'>Om webbplatsen</a></span>
                        <span className='footer-site-entry'><a
                            href='https://www.uu.se/om-uu/dataskyddspolicy/'>Dataskyddspolicy</a>
                        </span>
                    </p>
                    <p>
                        <span className='footer-site-entry'>Sidansvarig:&nbsp;<a
                                href='mailto:amc@uu.se'>amc@uu.se</a></span>
                    </p>
                </article>
            </div>
        </div>
    </div>
</footer>
  );
}

export default Footer;