import { useTranslation } from 'react-i18next';

export default function ConsultationControls(props) {
  const { t } = useTranslation();

   return (
    <>
    <h2>{t('profile.consultationControls')}</h2>
    <div className='container-fluid jumbotron jumbotron-fluid'>
      <div className='row obligations-details'>
        <div className='col-lg-3'>
          <h3>{t('profile.consultation')}:</h3>
          <p>{props.consultation}
          </p>
        </div>
        <div className='col-lg-4'>
          <h3>{t('profile.demonstratedCompliance')}:</h3>
          <p>{props.demonstratedCompliance}</p>
        </div>
        <div className='col-lg-5'>
          <h3>{t('profile.verifiedCompliance')}:</h3>
          <p>{props.verifiedCompliance}</p>
        </div>
      </div>
    </div>
    <div className='container mt-5'>
      <div className='row'>
        <div className='col-md-8'>
          {props.info}
        </div>
      </div>
    </div>

    </>
  );
}