import { useTranslation } from 'react-i18next';
import { useLayoutEffect } from 'react';
import Obligations from './obligations/obligations';
import AgreementParties from './agreementParties/agreementParties';
import AgreementControlSystem from './agreementControlSystem/agreementControlSystem';
import InstructionObligationDropdown from './obligations/instructionDropdown';
import InstructionControlSystemDropdown from './agreementControlSystem/instructionDropdown';
import InstructionDropdownAgreements from './agreementParties/instructionDropdown';

function DataVisualizer() {
  const { t } = useTranslation();

  useLayoutEffect(() => {
    // Start at the top
    window.scrollTo(0, 0)
  });

  return (
<div className='container mt-5'>
  <h1 className='no-printme'>{t('dataVisualizer.title')}</h1>
  <p className='no-printme'>{t('dataVisualizer.preamble')}</p>
  <nav aria-label='Tabulär navigering'>
    <ul className='nav nav-tabs' id='visualizerTab' role='tablist'>
      <li className='nav-item flex-fill text-center' role='presentation'>
        <button className='nav-link active' id='X1-tab' data-bs-toggle='tab' data-bs-target='#X1'
          type='button' role='tab' aria-controls='X' aria-selected='true' tabIndex='-1'>
          {t('agreementParties.title')}
        </button>
      </li>
      <li className='nav-item flex-fill text-center' role='presentation'>
        <button className='nav-link' id='V1-tab' data-bs-toggle='tab' data-bs-target='#V1'
          type='button' role='tab' aria-controls='V' aria-selected='false'>
          {t('obligations.title')}
        </button>
      </li>
      <li className='nav-item flex-fill text-center' role='presentation'>
        <button className='nav-link' id='Z1-tab' data-bs-toggle='tab' data-bs-target='#Z1'
          type='button' role='tab' aria-controls='Z' aria-selected='false'>
          {t('agreementControlSystem.title')}
        </button>
      </li>
    </ul>
  </nav>
  <div className='card tab-card tab-content' id='visualizerTabContent'>
    <div className='card-body tab-pane fade show active' id='X1' role='tabpanel'
      aria-labelledby='X1-tab'>
      <p>{t('agreementParties.preamble')}</p>
      <InstructionDropdownAgreements/>
      <AgreementParties />
    </div>
    <div className='card-body tab-pane fade' id='V1' role='tabpanel' aria-labelledby='V1-tab'>
      <p className='no-printme'>{t('obligations.preamble')}</p>
      <InstructionObligationDropdown />
      <Obligations />
    </div>
    <div className='card-body tab-pane fade' id='Z1' role='tabpanel' aria-labelledby='Z1-tab'>
      <p>{t('agreementControlSystem.preamble')}</p>
      <InstructionControlSystemDropdown />
      <AgreementControlSystem />
    </div>
  </div>
</div>

  );
}

export default DataVisualizer;