import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function menuItemFirstLevel(path, title) {
  return (
    <li className='nav-item' data-bs-level='1' onClick={() => {unDisplayMenu();}}>
      <div className='d-flex'>
        <Link to={path} className='nav-link function-menu-first-level-list-link'>
          {title}
        </Link>
      </div>
    </li>
  );
}

function unDisplayMenu() {
  document.getElementById('menu-container').classList.remove('show');
  document.getElementById('menu-button').classList.add('collapsed');
  document.getElementById('menu-button').ariaExpanded = false;
}

function Menu(props) {
  const { t } = useTranslation();
  const currentLang = localStorage.getItem('i18nextLng') || 'sv';

  return (
    <div className='nav-expandable'>
      <nav className='navbar-expand-lg' aria-label='Page menu'>
        <div className='navbar-collapse'>
          <ul className='nav'>
            <li className='nav-item' data-bs-level='1' onClick={() => {
              const menuContainer = document.getElementById('menu-container');
              const ccc = menuContainer.classList.contains('collapse');
              menuContainer.classList.remove(!ccc);
              }}>
              <div className='d-flex'>
                <Link to={`./${currentLang}`} className='nav-link'>
                  {t('home.title')}
                </Link>
              </div>
              <ul className='nav'>
                {menuItemFirstLevel(`./${currentLang}/dataPortal`, t('home.dataPortal.title'))}
                {menuItemFirstLevel(`./${currentLang}/dataVisualizer`, t('dataVisualizer.title'))}
                {menuItemFirstLevel(`./${currentLang}/agreementDatabase`,
                  t('home.agreementDb.title'))}
                {menuItemFirstLevel(`./${currentLang}/digitalLibrary`,
                  t('home.digitalLibrary.title'))}
                {menuItemFirstLevel(`./${currentLang}/definitions`, t('home.definitions.title'))}

                <li className='nav-item amcdp-menu-border' data-bs-level='1'
                  onClick={() => {sessionStorage.setItem('token', ''); unDisplayMenu();}}>
                  <div className='d-flex'>
                    <Link to={`./${currentLang}/about`}
                      className='nav-link function-menu-first-level-list-link'>
                      {t('about.title')}
                    </Link>
                    </div>
                </li>

                {props.isLoggedOut &&
                  <li className='nav-item' data-bs-level='1'
                    onClick={() => {sessionStorage.setItem('token', ''); unDisplayMenu();}}>
                    <div className='d-flex'>
                      <Link to={`./${currentLang}/login`} className='nav-link
                        function-menu-first-level-list-link button-icon icon-system-login
                        button-text amcdp-menu-icon'>
                        {t('login.title')}
                      </Link>
                    </div>
                  </li>
                }
                {!props.isLoggedOut &&
                  <li className='nav-item' data-bs-level='1'
                    onClick={() => {sessionStorage.setItem('token', ''); unDisplayMenu();}}>
                    <div className='d-flex'>
                      <Link to={`./${currentLang}/`} className='nav-link
                        function-menu-first-level-list-link button-icon icon-system-logout
                        button-text amcdp-menu-icon'>
                        {t('logout.title')}
                      </Link>
                    </div>
                  </li>
                }
                {!props.isLoggedOut && menuItemFirstLevel(`./${currentLang}/resetPwd`,
                  t('resetPwd.title'))}
                {!props.isLoggedOut && menuItemFirstLevel(`./${currentLang}/loadDatabase`,
                  t('db.loadDatabase'))}
              </ul>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default Menu;