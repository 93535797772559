import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function YearFilter(props) {
  const [startYear, setStartYear] = useState(props.min);
  const [endYear, setEndYear] = useState(props.max);
  const { t } = useTranslation();

  useEffect(() => {
    setStartYear(props.min);
    setEndYear(props.max);
  }, [props.min, props.max]);

  function handleYearChange() {
    // Make sure user can't choose a end date before the choosen start date
    if (startYear) {
      document.getElementById('endYear')
        .setAttribute('min', document.getElementById('startYear').value);
    }
    // Make sure user can't choose a start date after the choosen end date
    if (endYear) {
      document.getElementById('startYear')
        .setAttribute('max', document.getElementById('endYear').value);
    }
    props.setCurrentFilter({from: startYear, to: endYear});
  }

  return (
    <>
      <button className='button-filter button-icon icon-badge' data-bs-toggle='collapse'
        data-bs-target={`#${props.type}-filter`} aria-controls={`${props.type}-filter`}
        aria-expanded='false' data-badge-number='1' id={`${props.type}-filter-button`}>
        <span className='visually-hidden'>{t('digitalLibrary.filterOn')}</span>{props.info}
      </button>
      <div id={`${props.type}-filter`} className='filter-content collapse'
        data-bs-parent={`#${props.dataBsParent}`}>
        <div className='card'>
          <div className='card-body'>
            <div className='row'>
              <div className='col-2'>
                <label htmlFor='startYear' className='form-label'>{t('digitalLibrary.year.from')}</label>
                <input type='number' min={props.min} max={props.max} id='startYear'
                  className='form-control' value={startYear} onChange={(e)=> {
                    setStartYear(e.target.value);
                    handleYearChange();
                  }} />
              </div>
              <div className='col-2'>
                <label htmlFor='endYear' className='form-label'>{t('digitalLibrary.year.to')}</label>
                <input type='number' min={props.min} max={props.max} id='endYear'
                  className='form-control' value={endYear} onChange={(e)=> {
                    setEndYear(e.target.value);
                    handleYearChange();
                  }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

