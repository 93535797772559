import { useTranslation } from 'react-i18next';
import { useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import parseToHtml from 'html-react-parser';
import { getName, triggerTypes, initiationTypes, durationTypes, verifiedCategoryTypes,
  getTriggerInterval } from '../common';
import PageButtons from '../pageButtons';
import FilterVerified from './filterVerified';

export default function VerifiedTable(props) {
  const { t } = useTranslation();
  const currentLang = localStorage.getItem('i18nextLng') || 'sv';

  // Sort order, ascending = true, descending = false
  const [sortNameAsc, setSortNameAsc] = useState(true);
  const [sortCategoryAsc, setSortCategoryAsc] = useState(true);
  const [sortMechanismAsc, setSortMechanismAsc] = useState(true);
  const [sortInspectorTypeAsc, setSortInspectorTypeAsc] = useState(true);
  const [sortAreaAccessAsc, setSortAreaAccessAsc] = useState(true);

  // Result
  const [results, setResults] = useState([]);

  const [verifiedFiltersIsChanged, setVerifiedFiltersIsChanged]= useState(true);

  // Show or hide spinning loader
  const [loader, setLoader] = useState(false);

  // Current page
  const [currentPage, setCurrentPage] = useState(1);
  // Number of rows per page
  const [totalPages, setTotalPages] = useState(1);
  // Maximum of 5 pagination buttons
  const [pageItems, setPageItems] = useState([]);

  const [triggerType, setTriggerType] = useState([]);

  const [currentRequestSort, setCurrentRequestSort] =
  useState( {'name': 'name', 'sortAcending': true });

  const veifiedMechanismTypes = new Map();
  veifiedMechanismTypes.set('0', 'Initiated compliance');
  veifiedMechanismTypes.set('1', 'Updated compliance');
  veifiedMechanismTypes.set('2', 'Challenged compliance (defended/affirmed)');
  veifiedMechanismTypes.set('3', 'Completed compliance (post)');
  veifiedMechanismTypes.set('4', 'Other');

  const veifiedInspectorTypes = new Map();
  veifiedInspectorTypes.set('0', 'Party on party');
  veifiedInspectorTypes.set('1', 'Agreement-established association');
  veifiedInspectorTypes.set('2', 'Utilized association');
  veifiedInspectorTypes.set('3', 'other ');

  const veifiedAreaAccessTypes = new Map();
  veifiedAreaAccessTypes.set('0', `The verifying party does not have access to a specified
    location/area during inspection`);
  veifiedAreaAccessTypes
    .set('1', `The verifying party has access to a specified location/area during inspection`);

  useEffect(() => {
    getVerifiedTriggerTypes();
    fetchVerifiedControlsystem(currentPage, currentRequestSort);
    // eslint-disable-next-line
  }, []);

  // Update demonstrated data whenever page, sort order or any of the filters are changed
  useEffect(() => {
    fetchVerifiedControlsystem(currentPage, currentRequestSort);
    // eslint-disable-next-line
  }, [currentPage, currentRequestSort, props.agreementFilters, verifiedFiltersIsChanged]);

  function getPageItems(selectedPage, totPages) {
    const tot = totPages < 5 ? totPages : 5;
    const items = [];
    let startIndex = 1;
    if (selectedPage <= 3 || totPages <= 5) {
      startIndex = 1;
    } else if (totPages - selectedPage <= 2) {
      startIndex = selectedPage - 4 + (totPages - selectedPage);
    } else {
      startIndex = selectedPage - 2;
    }
    for(let i = startIndex; i < startIndex+tot; i +=1) {
      var pagingClass = currentPage === i ? 'page-link active' : 'page-link';
      items.push(
        <li className='page-item page-item-nav' id={`page-list-${i}`} key={`page-list-${i}`} >
          <Link id={`page-${i}`} key={`page-${i}`} className={pagingClass} onClick={() => {
            setCurrentPage(i);
            getPageItems(i, totPages);
          }}>
            {i}
          </Link>
        </li>
      );
    }
    return items;
  }

  // Fill verified trigger types
  const getVerifiedTriggerTypes = async () => {
    try {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      };

      const response = await fetch('/api/type/getVerifiedTriggerTypes', requestOptions);
      const res = await response.json();
      const results = JSON.parse(res.data).result;
      const items = [];
      if (results && results?.length > 0) {
        [...results].forEach((action) => {
          items.push({id: action.id, type: action.type});
        });
      }
      setTriggerType(items);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  // Filter agreement parties from database
  const fetchVerifiedControlsystem = async (page, requestVerifiedSort) => {
    try {
      const pSize = document.getElementsByName('rowsPerPageVerified')[0].value;
      let verifiedItems = [];
      const mechanism = {
        verifiedCompliance: {
          isNTMs: document.getElementById('verifiedNTMsId-table').checked,
          isDisplay: document.getElementById('verifiedDisplayId-table').checked,
          isRemoteMonitoring: document.getElementById('verifiedRemoteMonitoringId-table').checked,
          isInPersonExhibition: document.getElementById('verifiedInpersonExhibitionId-table').checked,
          isInPersonDemonstration: document.getElementById('verifiedInpersonDemoId-table').checked,
          isInPersonInspection: document.getElementById('verifiedInpersonInspectionId-table').checked,
          isOther: document.getElementById('verifiedOtherId-table').checked }
      };

      const checkboxes = document.getElementsByName('agreement-group-cs');
      const filterAgreements = [];
      for (let i = 0, n = checkboxes.length; i < n; i++) {
        if (checkboxes[i].checked) {
          const agreementId = checkboxes[i].value;
          filterAgreements.push(agreementId);
        }
      }

      const isNoMechanismSet = Object.values(mechanism).every(item => item === false);
      // Only fetch data when filter is set
      if (!isNoMechanismSet && filterAgreements.length > 0) {
        setLoader(true);
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ ids: filterAgreements, mechanism: mechanism, page: page,
            pageSize: pSize, sortCol: requestVerifiedSort.name,
            sortAscending: requestVerifiedSort.sortAscending })
        };

        // Verified
        const verifiedResponse = await fetch('/api/controlSystem/getVerified', requestOptions);
        const resVer = await verifiedResponse.json();
        if (resVer.isOk) {
          setTotalPages(resVer.totalPages);
          setPageItems(getPageItems(page, resVer.totalPages));

          resVer.data?.forEach(item => {
            let interval = '';
            if (item.triggerType === '0') {
              interval = getTriggerInterval(item.triggerAgreement, item.quota, item.cyclical);
            } else {
              interval = triggerType.filter(obj => {
                return item.triggerType === obj.id.toString()
              })[0]?.type;
            }
            item.verifiedCategoryStr = verifiedCategoryTypes.get(item.category);
            item.verifiedInitiationStr = initiationTypes.get(item.initiation);
            item.verifiedDurationStr = durationTypes.get(item.duration);
            item.verifiedTriggerAgreementStr = triggerTypes.get(item.triggerAgreement);
            item.verifiedTriggerInterval = interval;
            item.verifiedMechanismStr = veifiedMechanismTypes.get(item.mechanism);
            item.verifiedInspectorTypeStr = veifiedInspectorTypes.get(item.inspectorType);
            item.verifiedAreaAccessStr = item.areaAccess;
            item.verifiedInitTime = item.initiationTime;
            item.verifiedDurationTime = item.durationTime;
            verifiedItems.push(item);
          });
          setResults({'items': verifiedItems, 'hits': resVer.hits});
        }
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error('Error fetching data:', error);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage- 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };

  const handleRowsPerPage = (pageCount) => {
    fetchVerifiedControlsystem(currentPage, currentRequestSort);
  };

  return (
    <section className='component' id='component-form-table'
      aria-labelledby='section-header-form-table'>
      <FilterVerified onFilterIsChanged={setVerifiedFiltersIsChanged} idStr='table' />
      <h2>{t('agreementControlSystem.table.result')} - {results?.hits ? results?.hits : 0}
        {` ${t('agreementControlSystem.table.hits')}`}</h2>
      <div className='row'>
        <div className='col-2'>
          <h3 id='section-header-form-table'>
            {t('agreementControlSystem.table.page')} {currentPage} ({totalPages})
          </h3>
        </div>
        <div className='col-3'>
          <label className='control-system-rowsperpage' htmlFor='rowsPerPageVerified'>
            {t('agreementControlSystem.table.rowsperpage')}
          </label>
          <select name='rowsPerPageVerified' id='rowsPerPageVerified'
            onChange={(event) => handleRowsPerPage(event.target.value)}>
            <option value='10'>10</option>
            <option value='25'>25</option>
            <option value='50'>50</option>
          </select>
        </div>
        <div className='col-2'>
          <div className='loader amcdpLoader'
            style={{visibility: loader ? 'visible' : 'hidden' }} />
        </div>
      </div>
      <div className='anchor' id='table'></div>
      <div className='table-responsive'>
        <table className='table table-striped table-hover mb-4'>
          <thead>
          <tr>
              <th className='icon-position-right icon-sort tableHeader table-select' scope='col'
                onClick={() => {
                  setSortNameAsc(!sortNameAsc);
                  setCurrentRequestSort({'name': 'name', 'sortAscending': sortNameAsc });
                }}>
                {t('agreementControlSystem.table.name')}
              </th>
              <th className='icon-position-right icon-sort tableHeader table-select' scope='col'
                onClick={() => {
                  setSortCategoryAsc(!sortCategoryAsc);
                  setCurrentRequestSort({'name': 'category',
                    'sortAscending': sortCategoryAsc });
                }}>
                {t('agreementControlSystem.table.verified.category')}
              </th>
              <th scope='col'>
                {t('agreementControlSystem.table.verified.duration')}
              </th>
              <th scope='col'>
                {t('agreementControlSystem.table.verified.interval')}
              </th>
              <th>
                {t('agreementControlSystem.table.verified.mechanismid')}
              </th>
              <th className='icon-position-right icon-sort tableHeader table-select' scope='col'
                onClick={() => {
                  setSortMechanismAsc(!sortMechanismAsc);
                  setCurrentRequestSort({'name': 'mechanism',
                    'sortAscending': sortMechanismAsc });
                }}>
                {t('agreementControlSystem.table.verified.mechanism')}
              </th>
              <th className='icon-position-right icon-sort tableHeader table-select' scope='col'
                onClick={() => {
                  setSortInspectorTypeAsc(!sortInspectorTypeAsc);
                  setCurrentRequestSort({'name': 'inspectorType',
                    'sortAscending': sortInspectorTypeAsc });
                }}>
                {t('agreementControlSystem.table.verified.inspectortype')}
              </th>
              <th className='icon-position-right icon-sort tableHeader table-select' scope='col'
                onClick={() => {
                  setSortAreaAccessAsc(!sortAreaAccessAsc);
                  setCurrentRequestSort({'name': 'areaAccess',
                    'sortAscending': sortAreaAccessAsc });
                }}>
                {t('agreementControlSystem.table.verified.areaaccess')}
              </th>
              <th scope='col'>
                {t('agreementControlSystem.table.verified.init')}
              </th>
              <th scope='col'>
                {t('agreementControlSystem.table.verified.timeduration')}
              </th>
            </tr>
          </thead>
          <tbody>
            {results?.items?.map((item, i) => (
            <tr id={`cs-${item.agreement_id}-${i}`} key={`cs-${item.agreement_id}-${i}`}>
              <td>
                { item.name.length > 35 ?
                  <Link to={`../${currentLang}/profilePage?id=${item.agreement_id}`} className=''
                    title={item.name}>
                    {getName(item.name, 35)}
                  </Link> :
                  <Link to={`../${currentLang}/profilePage?id=${item.agreement_id}`} className=''>
                    {getName(item.name, 35)}
                  </Link>
                }
              </td>
              <td>{item.verifiedCategoryStr}</td>
              <td>{item.verifiedInitiationStr} {parseToHtml('&ndash;')}
                {item.verifiedDurationStr}</td>
              <td>{item.verifiedTriggerAgreementStr}{item.verifiedTriggerInterval}</td>
              <td>{item.verified_mechanism_id}</td>
              <td>{item.verifiedMechanismStr}</td>
              <td>{item.verifiedInspectorTypeStr}</td>
              <td title={item.verifiedAreaAccessStr}>{getName(item.verifiedAreaAccessStr, 35)}</td>
              <td>{item.verifiedInitTime}</td>
              <td>{item.verifiedDurationTime}</td>
            </tr>
            ))}
          </tbody>
        </table>
      </div>

      <PageButtons handleFirstPage={handleFirstPage} handlePrevPage={handlePrevPage}
        handleNextPage={handleNextPage} handleLastPage={handleLastPage} currentPage={currentPage}
        totalPages={totalPages} pageItems={pageItems} />
    </section>
  );
}