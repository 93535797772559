import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import Diagram from './diagram';
import Table from './table';
import { getName } from '../common';

function Agreementparties() {
  const { t } = useTranslation();

  const [loaderAgreement, setLoaderAgreement] = useState(false);
  const [loaderCountry, setLoaderCountry] = useState(false);
  const [loaderAction, setLoaderAction] = useState(false);
  const [loaderWeapon, setLoaderWeapon] = useState(false);

  // Agreement
  let agreementCount = 0;
  let filterAgreements = [];
  const [agreementFilters, setAgreementFilters] = useState([]);
  const [agreementOptions, setAgreementOptions] = useState([]);

  // Country
  let countryCount = 0;
  let filterCountries = [];
  const [countryFilters, setCountryFilters] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);

  // Action
  let actionCount = 0;
  let filterActions = [];
  const [actionFilters, setActionFilters] = useState([]);
  const [actionOptions, setActionOptions] = useState([]);

  // Weapons
  let weaponCount = 0;
  let filterWeapons = [];
  const [weaponOptions, setWeaponOptions] = useState([]);
  const [weaponFilters, setWeaponFilters] = useState([]);

    // On/off for freeze hover and display country name
  const [displayCountryName, setDisplayCountryName] = useState(false);

  // Toggle 'all' on and off
  function toggleAll(id, isChecked) {
    const checkboxes = document.getElementsByName(id);
    const s = [];
    for (let i = 0, n = checkboxes.length; i < n; i++) {
      checkboxes[i].checked = isChecked;
      if (isChecked) {
        const agreementId = checkboxes[i].value;
        s.push(agreementId);
      }
    }
    return s;
  }

  // Toggle 'all' on and off for agreement
  function onAgreementToggleAll(isChecked) {
    const agreements = toggleAll('agreement-group', isChecked);
    let s = [];
    agreements.forEach((agreement) => s.push(agreement));
    filterAgreements = agreements;
    setAgreementFilters(s);
  }

  // Toggle 'all' on and off for country
  function onCountryToggleAll(isChecked) {
    const countries = toggleAll('country-group', isChecked);
    let s = [];
    countries.forEach((country) => s.push(country));
    filterCountries = countries;
    setCountryFilters(s);
  }

  // Toggle 'all' on and off for action
  function onActionToggleAll(isChecked) {
    const actions = toggleAll('action-group', isChecked);
    let s = [];
    actions.forEach((action) => s.push(action));
    filterActions = actions;
    setActionFilters(s);
  }

  // Toggle 'all' on and off for weapont type
  function onWeaponToggleAll(isChecked) {
    const weapons = toggleAll('weapon-group', isChecked);
    let s = [];
    weapons.forEach((weapon) => s.push(weapon));
    filterWeapons = weapons;
    setWeaponFilters(s);
  }

  // Toggle checkbox
  function toggleCheckbox(id, filters, idAll, count) {
    const s = filters;
    const agreementId = document.getElementById(`flexCheckDefault-${id}`).value;
    const findIdx = filters.indexOf(agreementId);
    if (findIdx > -1) {
      s.splice(findIdx, 1);
      // Uncheck 'all'
      document.getElementById(idAll).checked = false;
    } else {
      s.push(agreementId);
      // Check 'all' if all boxes are checked
      if (s.length >= count) {
        document.getElementById(idAll).checked = true;
      }
    }
    return s;
  }

  const onAgreementChange = id => {
    const s = toggleCheckbox(id, filterAgreements, 'flexCheckDefault-agreement-all', agreementCount);
    setAgreementFilters(prevNames => [...s])
  };

  const onCountryChange = id => {
    const s = toggleCheckbox(id, filterCountries, 'flexCheckDefault-country-all', countryCount);
    setCountryFilters(prevNames => [...s]);
  };

  const onActionChange = id => {
    const s = toggleCheckbox(id, filterActions,'flexCheckDefault-action-all', actionCount);
    setActionFilters(prevNames => [...s]);
  };

  const onWeaponChange = id => {
    const s = toggleCheckbox(id, filterWeapons, 'flexCheckDefault-weapon-all', weaponCount);
    setWeaponFilters(prevNames => [...s]);
  };

  const onCountryNameChange = e => {
    setDisplayCountryName(e.target.checked);
  };

  useEffect(() => {
    fetchAgreements();
    fetchCountries();
    fetchActions();
    fetchWeapons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function checkboxAll(id, onChange, selected, str) {
    return (
      <div className='form-group' key={`${id}-1`}>
        <div className='form-check'>
          <input
            className='form-check-input' type='checkbox' id={`flexCheckDefault-${id}`}
            onChange={(e) => onChange(e.target.checked)} selected={selected}
          />
          <label className='form-check-label' htmlFor={`flexCheckDefault-${id}`}
          id={`flexCheckDefault-label-${id}`}>
            <strong>{str}</strong>
          </label>
        </div>
      </div>
    );
  }

  function checkbox(id, onChange, selected, str, title, value, name, isEnabled, count) {
    const checkbox = [];
    if (isEnabled) {
      checkbox.push(
        <div className='form-group' key={`${id}-1`}>
          <div className='form-check'>
            <input
              className='form-check-input' type='checkbox' id={`flexCheckDefault-${id}`}
              onChange={() => onChange(id)} selected={selected} name={name}
              value={value}
            />
            <label className='form-check-label' htmlFor={`flexCheckDefault-${id}`} title={title}>
              {`${str} (${count})`}
            </label>
          </div>
        </div>
      );
    } else {
      checkbox.push(
        <div className='form-group' key={`${id}-1`}>
          <div className='form-check disabledControlSystemAgreement'>
            <input
              className='form-check-input' type='checkbox' id={`flexCheckDefault-${id}`}
              value={value}
            />
            <label className='form-check-label' htmlFor={`flexCheckDefault-${id}`} title={title}>
              {str}
            </label>
          </div>
        </div>
      );
    }
    return checkbox;
  }

  function setAgreementAllLabel(text) {
    document.getElementById('flexCheckDefault-label-agreement-all')
      .innerHTML=`<strong>${text}</strong>`;
  }

  // Get all agreements from database
  const fetchAgreements = async () => {
    try {
      setLoaderAgreement(true);
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      };
      // Find valid agreements
      const responseP = await fetch('/api/getAgreementCountryPartiesInfo', requestOptions);
      const resP = await responseP.json();
      const agreementsP = await JSON.parse(resP.data).result;
      if (agreementsP && agreementsP?.length > 0) {
        const validIds = [];
        agreementsP?.forEach((id) => {validIds.push(id.agreement_id)});

        const response = await fetch('/api/getAgreements', requestOptions);
        const res = await response.json();
        const agreements = JSON.parse(res.data).result;
        if (agreements && agreements?.length > 0) {
          agreementCount = agreements?.length;
          agreements?.sort((a, b) => a.name.localeCompare(b.name));

          let agreementOptions = [];
          const id = 'agreement-all';
          agreementOptions.push(
            checkboxAll(id, onAgreementToggleAll, agreementFilters.includes(id),
            t('All (Only 5 agreements will be drawn)'))
          );
          for (let i = 0; i < agreements?.length; i++) {
            const id = `agreement-${agreements[i].name}-${i}`;
            const name = getName(agreements[i].name, 35);
            const isEnabled = validIds.includes(agreements[i].agreement_id);
            const count = agreementsP
              .find(({ agreement_id }) => agreement_id === agreements[i].agreement_id)?.count;
            agreementOptions.push(checkbox(id, onAgreementChange, agreementFilters.includes(id), name,
              agreements[i].name, agreements[i].agreement_id, 'agreement-group', isEnabled, count)
            );
          }
          setAgreementOptions(agreementOptions);
        }
      }
      setLoaderAgreement(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoaderAgreement(false);
    }
  };

  // Get all countries from database
  const fetchCountries = async () => {
    try {
      setLoaderCountry(true);
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      };
      // Find valid countries
      const responseP = await fetch('/api/getAgreementCountryPartiesCountryInfo', requestOptions);
      const resP = await responseP.json();
      const countriesP = await JSON.parse(resP?.data)?.result;
      if (countriesP && countriesP?.length > 0) {
        const validIds = [];
        countriesP?.forEach((id) => {validIds.push(id.name)});

        const response = await fetch('/api/getAgreementCountryParties', requestOptions);
        const res = await response.json();
        const countries = JSON.parse(res.data).result;
        if (countries && countries?.length > 0) {
          countries?.sort((a, b) => a.name.localeCompare(b.name))
          countryCount = countries.length;

          let countryOptions = [];
          const id = 'country-all';
          countryOptions.push(
            checkboxAll(id, onCountryToggleAll, countryFilters.includes(id),
              t('agreementParties.all'))
          );

          for (let i = 0; i < countries.length; i++) {
            const id = `country-${countries[i].name}`;
            const isEnabled = validIds.includes(countries[i].name);
            const count = countriesP.find(({ name }) => name === countries[i].name)?.count;
            countryOptions.push(checkbox(id, onCountryChange, countryFilters.includes(id),
              countries[i].name, countries[i].name, countries[i].name, 'country-group', isEnabled,
              count)
            );
          }
          setCountryOptions(countryOptions);
        }
      }
      setLoaderCountry(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoaderCountry(false);
    }
  };

  // Get all actions from database
  const fetchActions = async () => {
    try {
      setLoaderAction(true);
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      };

      // Find valid actions
      const responseP = await fetch('/api/getAgreementCountryPartiesActionInfo', requestOptions);
      const resP = await responseP.json();
      const actionsP = await JSON.parse(resP?.data)?.result;
      if (actionsP && actionsP?.length > 0) {
        const validIds = [];
        actionsP?.forEach((id) => {validIds.push(id.id)});

        const response = await fetch('/api/type/getAction', requestOptions);
        const res = await response.json();
        const actions = JSON.parse(res.data).result;
        if (actions && actions?.length > 0) {
          actions?.sort((a, b) => a.type.localeCompare(b.type))
          actionCount = actions.length;

          let actionOptions = [];
          const id = 'action-all';
          actionOptions.push(
            checkboxAll(id, onActionToggleAll, actionFilters.includes(id),
              t('agreementParties.all'))
          );

          for (let i = 0; i < actions.length; i++) {
            const id = `action-${actions[i].type}`;
            const isEnabled = validIds.includes(actions[i].id);
            const count = actionsP.find(({ id }) => id === actions[i].id)?.count;
            actionOptions.push(checkbox(id, onActionChange, actionFilters.includes(id), actions[i].type,
              actions[i].type, actions[i].id, 'action-group', isEnabled, count)
            );
          }
          setActionOptions(actionOptions);
        }
      }
      setLoaderAction(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoaderAction(false);
    }
  };

  const fetchWeapons = async () => {
    try {
      setLoaderWeapon(true);
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      };
      // Find valid weapon categories
      const responseP = await fetch('/api/getAgreementCountryPartiesWeaponInfo', requestOptions);
      const resP = await responseP.json();
      const gunsP = await JSON.parse(resP?.data)?.result;
      if (gunsP && gunsP?.length > 0) {
        const validIds = [];
        gunsP?.forEach((id) => {validIds.push(id.weapons_items_topic)});

        const response = await fetch('/api/type/getWeaponCategoryTypes', requestOptions);
        const res = await response.json();
        const guns = JSON.parse(res.data)?.result;
        if (guns && guns?.length > 0) {
          guns?.sort((a, b) => a.type.localeCompare(b.type))
          weaponCount = guns.length;

          let weaponOptions = [];
          const id = 'weapon-all';
          weaponOptions.push(
            checkboxAll(id, onWeaponToggleAll, weaponFilters.includes(id),
              t('agreementParties.all'))
          );

          for (let i = 0; i < guns.length; i++) {
            const id = `weapon-${guns[i].type}`;
            const isEnabled = validIds.includes(guns[i].id.toString());
            const count = gunsP.find(({ weapons_items_topic }) =>
              weapons_items_topic === guns[i].id.toString())?.count;
            weaponOptions.push(checkbox(id, onWeaponChange, weaponFilters.includes(id),
              guns[i].type, guns[i].type, guns[i].id, 'weapon-group', isEnabled,
              count)
            );
          }
          setWeaponOptions(weaponOptions);
        }
      }
      setLoaderWeapon(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoaderWeapon(false);
    }
  }

  return (
    <div>
      <div className='row'>
	      <div className='col-md-3'>
		      <div className='height-200 overflow-scroll card card-body is-bg-light'>
            <fieldset>
              <legend>{t('agreementControlSystem.agreementFilter.title')}</legend>
              <div className='col-2'>
                {loaderAgreement && (<div className='loader amcdpLoader' />)}
              </div>
              {agreementOptions}
            </fieldset>
          </div>
        </div>

        <div className='col-md-3'>
          <div className='height-200 overflow-scroll card card-body is-bg-light'>
            <fieldset>
              <legend>{t('agreementControlSystem.countryFilter.title')}</legend>
              <div className='col-2'>
                {loaderCountry && (<div className='loader amcdpLoader' />)}
              </div>
              {countryOptions}
            </fieldset>
          </div>
        </div>

        <div className='col-md-3'>
          <div className='height-200 overflow-scroll card card-body is-bg-light'>
            <fieldset>
              <legend>{t('agreementControlSystem.actionFilter.title')}</legend>
              <div className='col-2'>
                {loaderAction && (<div className='loader amcdpLoader' />)}
            </div>
              {actionOptions}
            </fieldset>
          </div>
        </div>

        <div className='col-md-3'>
          <div className='height-200 overflow-scroll card card-body is-bg-light'>
            <fieldset>
              <legend>{t('agreementControlSystem.weaponFilter.title')}</legend>
              <div className='col-2'>
                {loaderWeapon && (<div className='loader amcdpLoader' />)}
            </div>
              {weaponOptions}
            </fieldset>
          </div>
        </div>
      </div>

      <div className='row pt-4'>
        <nav aria-label='Chart and table' className='col-md-8 pt-3 mb-4'>
         <div className='chart-table-nav'>
            <ul className='nav nav-tabs' id='visualizerTab' role='tablist'>
              <li className='nav-item text-center' role='presentation'>
                <button className='nav-link button-icon button-text icon-chart active'
                  id='chart-tab' data-bs-toggle='tab' data-bs-target='#chart'
                  type='button' role='tab' aria-controls='chart' aria-selected='true' tabIndex='-1'
                  onClick={() => {setAgreementAllLabel('All (Only 5 agreements will be drawn)')}}>
                  {t('agreementParties.chart')}
                </button>
              </li>
              <li className='nav-item text-center' role='presentation'>
                <button className='nav-link button-icon button-text icon-table' id='table-tab'
                  data-bs-toggle='tab' data-bs-target='#table'
                  type='button' role='tab' aria-controls='table' aria-selected='false'
                  onClick={() => {setAgreementAllLabel(`<strong>${t('agreementParties.all')}</strong>`)}}>
                  {t('agreementParties.table')}
                </button>
              </li>
            </ul>
          </div>
        </nav>

        <div className='col-md-4'>
          <div className='form-check form-switch'>
            <input className='form-check-input' type='checkbox' role='switch'
              onChange={onCountryNameChange}
              id='flexSwitchCheckChecked' />
            <label className='form-check-label' htmlFor='flexSwitchCheckChecked'>
              {t('agreementParties.contryName')}
            </label>
          </div>
        </div>

        <div className='col-12'>
          <div className='col-12 chart-table tab-content' id='visualizerTabContent'>
            <div className='tab-pane fade show active' id='chart' role='tabpanel'
              aria-labelledby='chart-tab'>
              <Diagram filterAgreements={agreementFilters} filterCountries={countryFilters}
                filterActions={actionFilters} filterWeapons={weaponFilters} displayCountryName={displayCountryName} />
            </div>
            <div className='tab-pane fade' id='table' role='tabpanel' aria-labelledby='table-tab'>
              <Table filterAgreements={agreementFilters} filterCountries={countryFilters}
                filterActions={actionFilters} filterWeapons={weaponFilters}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Agreementparties;