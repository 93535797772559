import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import SearchResult from './searchResult';
import Filter from './filter';
import YearFilter from './yearFilter';

function DigitalLibrary() {
  const { t } = useTranslation();
  const [text, setText] = useState('');
  const [hits, setHits] = useState(0);

  // Text field for search
  const searchTextField = 'searchSite';

  //
  const [minYear, setMinYear] = useState(1900);
  const [maxYear, setMaxYear] = useState(2020);
  // Selected year
  const [yearIntervalFilter, setYearIntervalFilter] = useState({from: minYear, to: maxYear});

  const [documentTypeFilter, setDocumentTypeFilter] = useState([]);
  const documentTypes = [{id: 0, type: 'Document type'}];

  // Selected associated document types
  const [associatedDocumentTypesFilter, setAssociatedDocumentTypesFilter] = useState([]);
  // Associated document types to choose from
  const [associatedDocumentTypes, setAssociatedDocumentTypes] = useState([]);

  // Selected region specifics
  const [regionSpecificFilter, setRegionSpecificFilter] = useState([]);
  // Region specifics to choose from
  const [regionSpecifics, setRegioinSpecifics] = useState([]);

  // Selected region specifics
  const [lateralityFilter, setLateralityFilter] = useState([]);
  // Region specifics to choose from
  const [lateralities, setLateralities] = useState([]);

  // Selected agreement formats
  const [agreementFormatFilter, setAgreementFormatFilter] = useState([]);
  // Agreement formats to choose from
  const [agreementFormats, setAgreementFormats] = useState([]);

  // Selected status
  const [statusFilter, setStatusFilter] = useState([]);
  // Status to choose from
  const [agreementStatus, setAgreementStatus] = useState([]);

  // Selected weapon category
  const [weaponCategoryFilter, setWeaponCategoryFilter] = useState([]);
  // Weapon category to choose from
  const [weaponCategory, setWeaponCategory] = useState([]);

  // Selected consultation topic
  const [consultationTopicFilter, setConsultationTopicFilter] = useState([]);
  // Consultation topic to choose from
  const [consultationTopic, setConsultationTopic] = useState([]);

  // Selected demonstrated category
  const [demonstratedCategoryFilter, setDemonstratedCategoryFilter] = useState([]);
  // Demonstrated category to choose from
  const [demonstratedCategory, setDemonstratedCategory] = useState([]);

  // Selected verified category
  const [verifiedCategoryFilter, setVerifiedCategoryFilter] = useState([]);
  // Verified category to choose from
  const [verifiedCategory, setVerifiedCategory] = useState([]);

  // Selected agreement association type
  const [agreementAssociationTypeFilter, setAgreementAssociationTypeFilter] = useState([]);
  // Agreement association type to choose from
  const [agreementAssociationType, setAgreementAssociationType] = useState([]);

  // Selected ban phase type
  const [banPhaseTypeFilter, setBanPhaseTypeFilter] = useState([]);
  // Ban phase type to choose from
  const [banPhaseType, setBanPhaseType] = useState([]);

  // Selected restriction phase type
  const [resPhaseTypeFilter, setResPhaseTypeFilter] = useState([]);
  // Restriction phase type to choose from
  const [resPhaseType, setResPhaseType] = useState([]);

  const [filter, setFilter] = useState({
    yearInterval: yearIntervalFilter,
    documentType: documentTypeFilter,
    associatedDocument: associatedDocumentTypesFilter,
    regionSpecific: regionSpecificFilter,
    laterality: lateralityFilter,
    agreementFormat: agreementFormatFilter,
    status: statusFilter,
    weaponCategory: weaponCategoryFilter,
    consultationTopic: consultationTopicFilter,
    demonstratedCategory: demonstratedCategoryFilter,
    verifiedCategory: verifiedCategoryFilter,
    agreementAssociationType: agreementAssociationTypeFilter,
    banPhaseType: banPhaseTypeFilter,
    resPhaseType: resPhaseTypeFilter
    });

  useEffect(() => {
    fetchMinMaxYear();
    fillAssociatedDocumentTypes();
    fillRegionSpecifics();
    fillLateralityTypes();
    fillAgreementFormatTypes();
    fillStatusTypes();
    fillWeaponCategoryTypes();
    fillConsultationTopicTypes();
    fillDemonstratedCategoryTypes();
    fillVerifiedCategoryTypes();
    fillAgreementAssociationTypes();
    fillPhaseTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fill agreements to choose from
  const fetchMinMaxYear = async () => {
    try {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      };

      const response = await fetch('/api/getMinMaxYear', requestOptions);
      const res = await response.json();
      const results = JSON.parse(res.data).result;
      if (results && results.length > 0) {
        setMinYear(results[0].min);
        setMaxYear(results[0].max);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  // Fill associatedDocumentTypes to choose from
  const fillAssociatedDocumentTypes = async () => {
    try {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      };

      const response = await fetch('/api/type/getAssociatedDocument', requestOptions);
      const res = await response.json();
      const results = JSON.parse(res.data).result;
      const items = [];
      if (results && results?.length > 0) {
        [...results].forEach((action) => {
          items.push({id: action.id, type: action.type})
        });
      }
      setAssociatedDocumentTypes(items);

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  // Fill regionSpecifics to choose from
  const fillRegionSpecifics = async () => {
    try {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      };

      const response = await fetch('/api/type/getRegionSpecific', requestOptions);
      const res = await response.json();
      const results = JSON.parse(res.data).result;
      const items = [];
      if (results && results?.length > 0) {
        [...results].forEach((action) => {
          items.push({id: action.id, type: action.type})
        });
      }
      setRegioinSpecifics(items);

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  // Fill lateralityTypes to choose from
  const fillLateralityTypes = async () => {
    try {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      };

      const response = await fetch('/api/type/getLaterality', requestOptions);
      const res = await response.json();
      const results = JSON.parse(res.data).result;
      const items = [];
      if (results && results?.length > 0) {
        [...results].forEach((action) => {
          items.push({id: action.id, type: action.type})
        });
      }
      setLateralities(items);

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  // Fill agreementFormatTypes to choose from
  const fillAgreementFormatTypes = async () => {
    try {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      };

      const response = await fetch('/api/type/getAgreementFormat', requestOptions);
      const res = await response.json();
      const results = JSON.parse(res.data).result;
      const items = [];
      if (results && results?.length > 0) {
        [...results].forEach((action) => {
          items.push({id: action.id, type: action.type})
        });
      }
      setAgreementFormats(items);

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  // Fill statusTypes to choose from
  const fillStatusTypes = async () => {
    try {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      };

      const response = await fetch('/api/type/getStatus', requestOptions);
      const res = await response.json();
      const results = JSON.parse(res.data).result;
      const items = [];
      if (results && results?.length > 0) {
        [...results].forEach((action) => {
          items.push({id: action.id, type: action.type})
        });
      }
      setAgreementStatus(items);

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  // Fill weapon category types to choose from
  const fillWeaponCategoryTypes = async () => {
    try {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      };

      const response = await fetch('/api/type/getWeaponCategoryTypes', requestOptions);
      const res = await response.json();
      const results = JSON.parse(res.data).result;
      const items = [];
      if (results && results?.length > 0) {
        [...results].forEach((action) => {
          items.push({id: action.id, type: action.type})
        });
      }
      setWeaponCategory(items);

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  // Fill statusTypes to choose from
  const fillConsultationTopicTypes = async () => {
    try {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      };

      const response = await fetch('/api/type/getConsultationTopicTypes', requestOptions);
      const res = await response.json();
      const results = JSON.parse(res.data).result;
      const items = [];
      if (results && results?.length > 0) {
        [...results].forEach((action) => {
          items.push({id: action.id, type: action.type})
        });
      }
      setConsultationTopic(items);

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  // Fill demonstratedCategoryTypes to choose from
  const fillDemonstratedCategoryTypes = async () => {
    try {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      };

      const response = await fetch('/api/type/getDemonstrateCategoryTypes', requestOptions);
      const res = await response.json();
      const results = JSON.parse(res.data).result;
      const items = [];
      if (results && results?.length > 0) {
        [...results].forEach((action) => {
          items.push({id: action.id, type: action.category})
        });
      }
      setDemonstratedCategory(items);

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  // Fill demonstratedCategoryTypes to choose from
  const fillVerifiedCategoryTypes = async () => {
    try {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      };

      const response = await fetch('/api/type/getVerifiedCategoryTypes', requestOptions);
      const res = await response.json();
      const results = JSON.parse(res.data).result;
      const items = [];
      if (results && results?.length > 0) {
        [...results].forEach((action) => {
          items.push({id: action.id, type: action.mechanism})
        });
      }
      setVerifiedCategory(items);

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  // Fill agreementAssociationTypes to choose from
  const fillAgreementAssociationTypes = async () => {
    try {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      };

      const response = await fetch('/api/type/getAgreementAssociationTypes', requestOptions);
      const res = await response.json();
      const results = JSON.parse(res.data).result;
      const items = [];
      if (results && results?.length > 0) {
        [...results].forEach((action) => {
          items.push({id: action.id, type: action.type})
        });
      }
      setAgreementAssociationType(items);

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  // Fill phase types to choose from
  const fillPhaseTypes = async () => {
    try {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      };

      const response = await fetch('/api/type/getPhaseTypes', requestOptions);
      const res = await response.json();
      const results = JSON.parse(res.data).result;
      const items = [];
      if (results && results?.length > 0) {
        [...results].forEach((action) => {
          items.push({id: action.sort_id, type: action.display_name});
        });
      }
      setBanPhaseType(items);
      setResPhaseType(items);

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  function search() {
    const inputText = document.getElementById(searchTextField).value;
    setText(inputText);
    setFilter({
      yearInterval: yearIntervalFilter,
      documentType: documentTypeFilter,
      associatedDocument: associatedDocumentTypesFilter,
      regionSpecific: regionSpecificFilter,
      laterality: lateralityFilter,
      agreementFormat: agreementFormatFilter,
      status: statusFilter,
      weaponCategory: weaponCategoryFilter,
      consultationTopic: consultationTopicFilter,
      demonstratedCategory: demonstratedCategoryFilter,
      verifiedCategory: verifiedCategoryFilter,
      agreementAssociationType: agreementAssociationTypeFilter,
      banPhaseType: banPhaseTypeFilter,
      resPhaseType: resPhaseTypeFilter
    });
  }

  const handleHitsChange = (noHits) => {
    setHits(noHits);
  }

  return (
<div className='container mt-5'>

  <div className='mb-5'>
    <h1>{t('home.digitalLibrary.title')}</h1>
    <p>{t('db.preamble')}</p>
    <div role='search'>
      <div className='search-form'>
        <label htmlFor={searchTextField} className='visually-hidden'>
          {t('digitalLibrary.description')}
        </label>
        <input className='search-form-input form-control' type='search'
          id={searchTextField} disabled />
        <button className='search-button' onClick={() => search()}>
          {t('digitalLibrary.search')}
        </button>
      </div>

      <p className='uu-text'>
        {t('digitalLibrary.result.your')}
        <strong>{hits ? hits : 0}</strong>
        {t('digitalLibrary.result.hits')}
      </p>

      <div className='button-group search-filters' id='accordion-filter'>
        <div className='filter-container'>
          <YearFilter setCurrentFilter={setYearIntervalFilter} info={'Year'}
            type={'Year'} dataBsParent={'accordion-filter'} min={minYear} max={maxYear} />
          <Filter actions={documentTypes} setCurrentFilter={setDocumentTypeFilter}
            info={'Document type'} type={'documentType'} dataBsParent={'accordion-filter'} />
          <Filter actions={associatedDocumentTypes}
            setCurrentFilter={setAssociatedDocumentTypesFilter} info={'Associated document types'}
            type={'associatedDocumentType'} dataBsParent={'accordion-filter'} />
          <Filter actions={regionSpecifics} setCurrentFilter={setRegionSpecificFilter}
            info={'Region specific'} type={'regionSpecific'} dataBsParent={'accordion-filter'} />

          <button className='button-filter button-icon icon-badge' data-bs-toggle='collapse'
            data-bs-target={`#filter-more`} aria-controls={`filter-more`} aria-expanded='false'
            data-badge-number={lateralityFilter.length + agreementFormatFilter.length
              + statusFilter.length + weaponCategoryFilter.length + consultationTopicFilter.length
              + demonstratedCategoryFilter.length + verifiedCategoryFilter.length
              + agreementAssociationTypeFilter.length + banPhaseTypeFilter.length
              + resPhaseTypeFilter.length}>
            <span className='visually-hidden'>{t('digitalLibrary.filterOn')}</span>
            <span className='toggle-button-text-collapsed'>{t('digitalLibrary.more.filter')}</span>
            <span className='toggle-button-text-expanded'>{t('digitalLibrary.fewer.filter')}</span>
          </button>
        </div>
      </div>

      <div id={`filter-more`} className='filter-content collapse'
        data-bs-parent={`#accordion-filter`}>
        <div className='button-group search-filters' id='accordion-filter-more'>
          <div className='filter-container'>
            <Filter actions={lateralities} setCurrentFilter={setLateralityFilter}
              info={'Laterality'} type={'laterality'} dataBsParent={'accordion-filter-more'} />
            <Filter actions={agreementFormats} setCurrentFilter={setAgreementFormatFilter}
              info={'Agreement format'} type={'agreementFormat'}
              dataBsParent={'accordion-filter-more'} />
            <Filter actions={agreementStatus} setCurrentFilter={setStatusFilter} info={'Status'}
              type={'status'} dataBsParent={'accordion-filter-more'} />
            <Filter actions={weaponCategory} setCurrentFilter={setWeaponCategoryFilter}
              info={'Weapon category'} type={'weaponCategory'}
              dataBsParent={'accordion-filter-more'} />
            <Filter actions={consultationTopic} setCurrentFilter={setConsultationTopicFilter}
              info={'Consultation topic'} type={'consultationTopic'}
              dataBsParent={'accordion-filter-more'} />
            <Filter actions={demonstratedCategory} setCurrentFilter={setDemonstratedCategoryFilter}
              info={'Demonstrated compliance format'} type={'demonstratedCategory'}
              dataBsParent={'accordion-filter-more'} />
            <Filter actions={verifiedCategory} setCurrentFilter={setVerifiedCategoryFilter}
              info={'Verified compliance format'} type={'verifiedCategory'}
              dataBsParent={'accordion-filter-more'} />
            <Filter actions={agreementAssociationType}
              setCurrentFilter={setAgreementAssociationTypeFilter}
              info={'Agreement association type'} type={'agreementAssociationType'}
              dataBsParent={'accordion-filter-more'} />
            <Filter actions={banPhaseType} setCurrentFilter={setBanPhaseTypeFilter}
              info={'Ban phases'} type={'banphaseType'} dataBsParent={'accordion-filter-more'} />
            <Filter actions={resPhaseType} setCurrentFilter={setResPhaseTypeFilter}
              info={'Restricition phases'} type={'resPhaseType'}
              dataBsParent={'accordion-filter-more'} />
          </div>
        </div>
      </div>
    </div>
  </div>
  <SearchResult text={text} handleHitsChange={handleHitsChange} filter={filter} />
</div>

  );
}

export default DigitalLibrary;