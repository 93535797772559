import React from 'react';
import { Navigate } from 'react-router-dom';

export const ProtectedRoute = ({ children }) => {
  const currentLang = localStorage.getItem('i18nextLng') || 'sv';
  const tokenString = sessionStorage.getItem('token');
  let token = '';
  if (tokenString && tokenString !== '') {
    token = JSON.parse(tokenString);
  }
  if (!token && token === '') {
    // user is not authenticated
    return <Navigate to={`/${currentLang}/login`} />;
  }
  return children;
};

export default ProtectedRoute;