import { useTranslation } from 'react-i18next';

export default function ContextBackground(props) {
  const { t } = useTranslation();

  return (
    <div className='row'>
      <div className='col-md-8'>
        <h2>{t('profile.contextBackground')}</h2>
        {props.info}
      </div>

      <div className='col-lg-4 mb-0'>
        <div className='card'>
          <h2 className='card-header'>{t('profile.contralDocuments')}</h2>
          <div className='col-2'>
            {props.loader && (<div className='loader amcdpLoader' />)}
          </div>
          <div className='card-body'>
            <ul className='list-unstyled'>
              {props.centralFiles}
            </ul>
            { /* Not in use until automn 2024
            <h3>{t('profile.implementationDocuments')}</h3>
            <ul className='list-unstyled'>
              {props.implementationFiles}
            </ul> */ }
            <h3>{t('profile.amcResources')}</h3>
            <ul className='list-unstyled'>
              {props.resourcesFiles}
            </ul>
        </div>
        </div>
      </div>

    </div>
  );
}