import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import doveImg from '../img/dove.png';
import libraryImg from '../img/library.png';
import portalImg from '../img/portal.png';
import visualImg from '../img/visual.png';

function Home() {
  const { t } = useTranslation();

  // Parameters for downloadable encylocpedia
  const [encyclopediaName, setEncyclopediaName] = useState('');
  const [encyclopediaYear, setEncyclopediaYear] = useState('');

  useEffect(() => {
    fetchGetDataPortalContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get content of encyclopedia from sitevision
  const fetchGetDataPortalContent= async () => {
    try {
      const requestOptions = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
      };
      const response = await fetch('/api/sv/getEncyclopediaSVContent', requestOptions);
      const res = await response.json();
      setEncyclopediaName(res?.name);
      setEncyclopediaYear(res?.year);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
<div className='layout-main'>
	<main>
		<section className='component' id='component-module-jumbotron-img'
      aria-labelledby='section-header-module-jumbotron-img'>
			<div className='anchor' id='jumbotron-img'></div>
			<div className='container-fluid jumbotron jumbotron-image jumbotron-fluid'>
				<div className='d-flex justify-content-left align-items-end height-100 mt-5'>
					<div className='row'>
						<div className='jumbotron-text-col col-md-6 me-4 mb-4'>
							<h1 id='section-header-module-jumbotron-img' className='mb-1'>
                {t('home.jumbotron.visualization.title')}
              </h1>
							<div className='button-group text-start'>
								<p className='p-3 mb-0 home-bkg'>
                  {t('home.jumbotron.projectInfo')}
								</p>
                <Link to={`./dataVisualizer`} className='icon-position-right
                  icon-call-to-action link-call-to-action-rounded justify-content-start me-0'>
                  {t('home.jumbotron.visualizeData')}
                </Link>
							</div>
						</div>

            <div className='jumbotron-text-col col-md-4 mb-4 p-0 second-block'>
              <div className='pb-4 amc-start-download clearfix'>
                <h2 role='search' className='encyclopedia text-uppercase encylopedia-puff'>
                  AMC <strong>{encyclopediaName}</strong>
                </h2>
                <div className='text-uppercase'>
                  {encyclopediaYear}
                </div>
                <div>
                  <Link to='https://www.uu.se/download/18.a7a6ac1902a7ff5d9f51/1718709549968/amcencyclopedia.pdf'
                    download className='button button-icon icon-download float-end' target='_blank'>
                    {t('home.download')}
                  </Link>
                </div>
              </div>
              <div className='button-group text-start'>
                <h3 className='p-3 pt-4 mb-0'>
                {t('home.jumbotron.centreInfo')}
                </h3>
                <p className='text-uppercase'>
                  <Link to='https://www.uu.se/centrum/alva-myrdal-centrum'
                    className='icon-position-right icon-call-to-action link-call-to-action-rounded
                    justify-content-start me-0'>
                    {t('header.first.title')}<br/>{t('header.second.title')}
                  </Link>
                </p>
              </div>
            </div>
					</div>
				</div>
			</div>
		</section>
		<div className='container'>
			<div className='row card-group mt-5'>
        <div className='col-lg-3'>
          <div className='link-block card  card-image is-no-text-decoration'>
            <img className='card-img-top' src={portalImg} alt='Map and explosion' />
            <div className='card-body icon-position-right icon-call-to-action'>
              <h3 className='card-title card-title-linked'>
                <Link to={`./dataPortal`}>
                  {t('home.dataPortal.title')}
                </Link>
              </h3>
              <p className='card-text'>{t('home.dataPortal.info')}</p>
            </div>
          </div>
        </div>
        <div className='col-lg-3'>
          <div className='link-block card card-image is-no-text-decoration'>
            <img className='card-img-top' src={visualImg} alt='Graphs and tables' />
            <div className='card-body icon-position-right icon-call-to-action'>
              <h3 className='card-title card-title-linked'>
                <Link to={`./dataVisualizer`}>
                  {t('home.dataVisualizer.title')}
                </Link>
              </h3>
              <p className='card-text'>{t('home.dataVisualizer.info')}</p>
            </div>
          </div>
        </div>
        <div className='col-lg-3'>
          <div className='link-block card card-image is-no-text-decoration'>
            <img className='card-img-top' src={doveImg} alt='Friendly dove' />
            <div className='card-body icon-position-right icon-call-to-action'>
              <h3 className='card-title card-title-linked'>
                <Link to={`./agreementDatabase`}>
                  {t('home.agreementDb.title')}
                </Link>
              </h3>
              <p className='card-text'>{t('home.agreementDb.info')}</p>
            </div>
          </div>
        </div>
        <div className='col-lg-3'>
          <div className='link-block card card-image is-no-text-decoration'>
            <img className='card-img-top' src={libraryImg} alt='Books' />
            <div className='card-body icon-position-right icon-call-to-action'>
              <h3 className='card-title card-title-linked'>
                <Link to={`./digitalLibrary`}>
                  {t('home.digitalLibrary.title')}
                </Link>
              </h3>
              <p className='card-text'>{t('home.digitalLibrary.info')}</p>
            </div>
          </div>
  			</div>
		  </div>
    </div>
	</main>
</div>
  );
}

export default Home;