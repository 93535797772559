import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getHTMLFromSitevisionObj } from './common';
import parseToHtml from 'html-react-parser';

function Definitions() {
  const { t } = useTranslation();
  const [svContents, setSvContents] = useState('');

  useEffect(() => {
    fetchDefinitionSVContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get content of encyclopedia from sitevision
  const fetchDefinitionSVContent= async () => {
    try {
      const requestOptions = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
      };
      const response = await fetch('/api/sv/getDefinitionSVContent', requestOptions);
      const res = await response.json();
      let content = '';
      [...res.apiResponseJson].forEach((obj) => {
        if (obj.name !== 'Rubrik' && obj.name !== 'Beskrivning') {
          content += getHTMLFromSitevisionObj(obj.properties);
        }
      });

      setSvContents(parseToHtml(content));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
<div className='container mt-5'>
  <div className='row'>
    <div className='col-9'>
      {svContents}
    </div>
    <div className='col-3'>
      <div className='row'>
      <span>The Definitions Comendium</span>
        <p><Link to='https://www.uu.se/download/18.4dfbb1201900ff134ea31087/1718688858158/The%20Agreement%20Datasets%20Codebook%20and%20List%20of%20Variables.pdf'
          download className='button button-icon icon-download float-end' target='_blank'>
          {t('home.download')}
        </Link></p>
      </div>
      <div className='row'>
        <span>The Agreement Datasets Codebook & List of Variables</span>
        <p><Link to='https://www.uu.se/download/18.4dfbb1201900ff134ea31088/1718688859043/The%20Definitions%20Comendium.pdf'
          download className='button button-icon icon-download float-end' target='_blank'>
          {t('home.download')}
        </Link></p>
        </div>
    </div>
  </div>
</div>
  );
}

export default Definitions;