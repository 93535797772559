import { useTranslation } from 'react-i18next';
import { useState } from 'react';

export default function VerifiedFilter(props) {
  const { t } = useTranslation();

  // 
  const [isChangedFilter, setIsChangedFilter] = useState(true);

  // Toggle 'all' verified on and off
  function onVerifiedToggleAll(isChecked) {
    document.getElementById(`verifiedNTMsId-${props.idStr}`).checked = isChecked;
    document.getElementById(`verifiedDisplayId-${props.idStr}`).checked = isChecked;
    document.getElementById(`verifiedRemoteMonitoringId-${props.idStr}`).checked = isChecked;
    document.getElementById(`verifiedInpersonExhibitionId-${props.idStr}`).checked = isChecked;
    document.getElementById(`verifiedInpersonDemoId-${props.idStr}`).checked = isChecked;
    document.getElementById(`verifiedInpersonInspectionId-${props.idStr}`).checked = isChecked;
    document.getElementById(`verifiedOtherId-${props.idStr}`).checked = isChecked;
    setIsChangedFilter(!isChangedFilter);
    props.onFilterIsChanged(!isChangedFilter);
  }

  // Update check on 'all'
  function onVerifiedUpdateAllCheck() {
    let isChecked = false;
    if (document.getElementById(`verifiedNTMsId-${props.idStr}`).checked
      && document.getElementById(`verifiedDisplayId-${props.idStr}`).checked
      && document.getElementById(`verifiedRemoteMonitoringId-${props.idStr}`).checked
      && document.getElementById(`verifiedInpersonExhibitionId-${props.idStr}`).checked
      && document.getElementById(`verifiedInpersonDemoId-${props.idStr}`).checked
      && document.getElementById(`verifiedInpersonInspectionId-${props.idStr}`).checked
      && document.getElementById(`verifiedOtherId-${props.idStr}`).checked) {
        isChecked = true;
    }
    document.getElementById(`verifiedAllId-${props.idStr}`).checked = isChecked;
    setIsChangedFilter(!isChangedFilter);
    props.onFilterIsChanged(!isChangedFilter);
  }

  function filterItem(id, str, isAll) {
    const key = `${id}-${props.idStr}`;
    return (
      <div className='form-group'>
        <div className='form-check'>
          <input
            className='form-check-input' type='checkbox' id={key}
            onChange={(e) => isAll ? onVerifiedToggleAll(e.target.checked) :
              onVerifiedUpdateAllCheck()
            }
          />
          <label className='form-check-label' htmlFor={key}>
            {isAll && <strong>{str}</strong>}
            {!isAll && str}
          </label>
        </div>
      </div>
    );
  }

  return (
    <div className='col'>
      <div className='card agreement-control-system'>
        <div className='card-body bg-light'>
          <fieldset>
            <legend>{t('agreementControlSystem.verified')}</legend>
            <div className='row'>
              <div className='col-md-3'>
                {filterItem('verifiedAllId', t('agreementControlSystem.all'), true)}
                {filterItem('verifiedNTMsId', t('agreementControlSystem.ntms'), false)}
              </div>
              <div className='col-md-3'>
                {filterItem('verifiedDisplayId', t('agreementControlSystem.display'), false)}
                {filterItem('verifiedRemoteMonitoringId',
                  t('agreementControlSystem.remotemonitoring'), false)}
              </div>
              <div className='col-md-3'>
                {filterItem('verifiedInpersonExhibitionId',
                  t('agreementControlSystem.inpersonexhibition'), false)}
                {filterItem('verifiedInpersonDemoId',
                  t('agreementControlSystem.inpersondemonstration'), false)}
              </div>
              <div className='col-md-3'>
                {filterItem('verifiedInpersonInspectionId',
                  t('agreementControlSystem.inpersoninspection'), false)}
                {filterItem('verifiedOtherId', t('agreementControlSystem.other'), false)}
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </div>
  );
}