import { useTranslation } from 'react-i18next';
import instructionImageGeneral from '../../img/obligations-instruction-general.jpg';
import instructionImageRestriction from '../../img/obligations-instruction-res.jpg';
import instructionImageBan from '../../img/obligations-instruction-ban.jpg';

const InstructionDropdown = () => {
  const { t } = useTranslation();

  return (
    <div className='instruction'>
      <h2 className='toggle-header'>
        <button className='toggle-button button-icon'
          data-bs-toggle='collapse' data-bs-target='#toggle-o-instruction'
          aria-controls='toggle-o-instruction' aria-expanded='false'>
          {t('obligations.instruction')}
        </button>
      </h2>
      <div id='toggle-o-instruction' className='toggle-content collapse'>
        <div className='card'>
          <div className='card-body'>
            <h3>{t('obligations.instruction.title')}</h3>
            <div className='row mb-4 pb-4'>
              <div className='col-md-5'>
                <img src={instructionImageGeneral} alt='Introduction to using obligation diagram'
                  style={{ maxWidth: '100%' }} />
              </div>
              <div className='col-md-7'>
                <h4>{t('obligations.instruction.general.title')}</h4>
                <p>{t('obligations.instruction.general.description_1')}</p>
                <p>{t('obligations.instruction.general.description_2')}</p>
                <p>
                  {t('obligations.instruction.general.description_3_1')}
                  <b>{t('obligations.instruction.general.description_3_use')}</b>
                  {t('obligations.instruction.general.description_3_2')}
                </p>
              </div>
            </div>
            <div className='row mb-4 pb-4'>
              <div className='col-md-5'>
                <img src={instructionImageRestriction}
                  alt='Obligation diagram with exapnded restrictions'
                  style={{ maxWidth: '100%' }} />
              </div>
              <div className='col-md-7'>
                <h4>{t('obligations.instruction.res.title')}</h4>
                <p>{t('obligations.instruction.res.description_1')}</p>
                <p>
                  {t('obligations.instruction.res.description_2_1')}
                  <b>{t('obligations.instruction.res.description_2_use')}</b>
                  {t('obligations.instruction.res.description_2_2')}
                  <b>{t('obligations.instruction.res.description_2_qualcond')}</b>
                  {t('obligations.instruction.res.description_2_3')}
                  <b>{t('obligations.instruction.res.description_2_practice')}</b>
                  {t('obligations.instruction.res.description_2_4')}
                </p>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-5'>
                <img src={instructionImageBan} alt='Obligation diagram with expanded bans'
                  style={{ maxWidth: '100%' }} />
              </div>
              <div className='col-md-7'>
                <h4>{t('obligations.instruction.ban.title')}</h4>
                <p>{t('obligations.instruction.ban.description_1')}</p>
                <div>
                  {t('obligations.instruction.ban.description_2')}
                  <ul>
                    <li>{t('obligations.instruction.ban.description_2_li_1')}</li>
                    <li>{t('obligations.instruction.ban.description_2_li_2')}</li>
                    <li>{t('obligations.instruction.ban.description_2_li_3')}</li>
                    <li>{t('obligations.instruction.ban.description_2_li_4')}</li>
                  </ul>
                </div>
                <p>{t('obligations.instruction.ban.description_3')}</p>
              </div>
            </div>
          </div>
      </div>
      </div>
    </div>
  );
};

export default InstructionDropdown;
