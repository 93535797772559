import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import { getName } from '../common';
import PageButtons from '../pageButtons';

export default function Table(props) {
  const { t } = useTranslation();
  const currentLang = localStorage.getItem('i18nextLng') || 'sv';

  // Result
  const [results, setResults] = useState([]);

  // Show or hide spinning loader
  const [loader, setLoader] = useState(false);

  // Current page
  const [currentPage, setCurrentPage] = useState(1);
  // Number of rows per page
  const [totalPages, setTotalPages] = useState(1);
  // Maximum of 5 pagination buttons
  const [pageItems, setPageItems] = useState([]);

  const [currentRequestSort, setCurrentRequestSort] =
  useState( {"name": 'name', "sortAcending": true });

  // Sort order, ascending = true, descending = false
  const [sortNameAsc, setSortNameAsc] = useState(true);
  const [sortActionAsc, setSortActionAsc] = useState(true);
  const [sortCountryAsc, setSortCountryAsc] = useState(true);
  const [sortDateAsc, setSortDateAsc] = useState(true);
  const [sortWeaponCategoryAsc, setSortWeaponCategoryAsc] = useState(true);

  useEffect(() => {
    fetchAgreementParties(currentPage, currentRequestSort);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update demonstrated data whenever page, sort order or any of the filters are changed
  useEffect(() => {
    fetchAgreementParties(currentPage, currentRequestSort);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, currentRequestSort, props.filterAgreements, props.filterCountries,
    props.filterActions, props.filterWeapons]);

  function getPageItems(selectedPage, totPages) {
    const tot = totPages < 5 ? totPages : 5;
    const items = [];
    let startIndex = 1;
    if (selectedPage <= 3 || totPages <= 5) {
      startIndex = 1;
    } else if (totPages - selectedPage <= 2) {
      startIndex = selectedPage - 4 + (totPages - selectedPage);
    } else {
      startIndex = selectedPage - 2;
    }
    for(let i = startIndex; i < startIndex+tot; i +=1) {
      var pagingClass = currentPage === i ? 'page-link active' : 'page-link';
      items.push(
        <li className='page-item page-item-nav' id={`page-list-${i}`} key={`page-list-${i}`} >
          <Link id={`page-${i}`} key={`page-${i}`} className={pagingClass} onClick={() => {
            setCurrentPage(i);
            getPageItems(i, totPages);
          }}>
            {i}
          </Link>
        </li>
      );
    }
    return items;
  }

  // Filter agreement parties from database
  const fetchAgreementParties = async (page, requestDemonstratedSort) => {
    try {
      let items = [];
      let hits = 0;
      if (props.filterAgreements?.length > 0 || props.filterCountries?.length > 0
        || props.filterActions?.length > 0 || props.filterWeapons?.length > 0) {
        setLoader(true);
        const pSize = document.getElementsByName('rowsPerPageParties')[0].value;
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ ids: props.filterAgreements, countries: props.filterCountries,
            actions: props.filterActions, weapons: props.filterWeapons, page: page,
            pageSize: pSize, sortCol: requestDemonstratedSort.name,
            sortAscending: requestDemonstratedSort.sortAscending})
        };
        const response = await fetch('/api/getAgreementParties', requestOptions);
        const res = await response.json();
        if (res?.isOk) {
          setTotalPages(res.totalPages);
          setPageItems(getPageItems(page, res.totalPages));

          items = res.data;
          hits = res.hits;
        }
        setLoader(false);
      }
      setResults({"items": items, "hits": hits});
    } catch (error) {
      setLoader(false);
      console.error('Error fetching data:', error);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage- 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };

  const handleRowsPerPage = (pageCount) => {
    fetchAgreementParties(currentPage, currentRequestSort);
  };

  return (
    <section className='component mb-5' id='component-form-table'
      aria-labelledby='section-header-form-table'>
      <div className='anchor' id='table'></div>
      <h2>{t('agreementParties.table.result')} - {results?.hits ? results?.hits : 0}
        {` ${t('agreementControlSystem.table.hits')}`}</h2>
      <div className='row'>
        <div className='col-2'>
          <h3 id='section-header-form-table'>
            {t('agreementControlSystem.table.page')} {currentPage} ({totalPages})
          </h3>
        </div>
        <div className='col-3'>
          <label className='control-system-rowsperpage' htmlFor='rowsPerPageParties'>
            {t('agreementControlSystem.table.rowsperpage')}
          </label>
          <select name='rowsPerPageParties' id='rowsPerPageParties'
            onChange={(event) => handleRowsPerPage(event.target.value)}>
            <option value='10'>10</option>
            <option value='25'>25</option>
            <option value='50'>50</option>
          </select>
        </div>
        <div className='col-2'>
          <div className='loader amcdpLoader' style={{visibility: loader ? 'visible' : 'hidden' }} />
        </div>
      </div>
      <div className='table-responsive'>
        <table className='table table-striped table-hover mb-4'>
          <thead>
            <tr>
              <th className='icon-position-right icon-sort tableHeader table-select' scope='col'
                onClick={() => {
                  setSortNameAsc(!sortNameAsc);
                  setCurrentRequestSort({"name": 'name', "sortAscending": sortNameAsc });
                }}>
                {t('agreementParties.table.name')}
              </th>
              <th className='icon-position-right icon-sort tableHeader table-select' scope='col'
                onClick={() => {
                  setSortActionAsc(!sortActionAsc);
                  setCurrentRequestSort({"name": 'action', "sortAscending": sortActionAsc });
                }}>
                {t('agreementParties.table.action')}
              </th>
              <th className='icon-position-right icon-sort tableHeader table-select' scope='col'
                onClick={() => {
                  setSortCountryAsc(!sortCountryAsc);
                  setCurrentRequestSort({"name": 'country', "sortAscending": sortCountryAsc });
                }}>
                {t('agreementParties.table.country')}
              </th>
              <th className='icon-position-right icon-sort tableHeader table-select' scope='col'
                onClick={() => {
                  setSortDateAsc(!sortDateAsc);
                  setCurrentRequestSort({"name": 'date', "sortAscending": sortDateAsc });
                }}>
                {t('agreementParties.table.date')}
              </th>
              <th className='icon-position-right icon-sort tableHeader table-select' scope='col'
                onClick={() => {
                  setSortWeaponCategoryAsc(!sortWeaponCategoryAsc);
                  setCurrentRequestSort({"name": 'weaponCategory',
                    "sortAscending": sortWeaponCategoryAsc });
                }}>
                {t('agreementParties.table.weaponCategory')}
              </th>
            </tr>
          </thead>
          <tbody>
            {results?.items?.map((item, i) => (
              <tr id={`parties-${item.agreement_id}-${i}`}
                key={`parties-${item.agreement_id}-${i}`}>
                <td>
                  { item.name.length > 35 ?
                  <Link to={`../${currentLang}/profilePage?id=${item.agreement_id}`} className=''
                    title={item.name}>
                    {getName(item.name, 35)}
                  </Link> :
                  <Link to={`../${currentLang}/profilePage?id=${item.agreement_id}`} className=''>
                    {getName(item.name, 35)}
                  </Link>}
                </td>
                <td>{item.action}</td>
                <td>{item.country}</td>
                <td>{item.date}</td>
                <td>{item.weaponCategory}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <PageButtons handleFirstPage={handleFirstPage} handlePrevPage={handlePrevPage}
        handleNextPage={handleNextPage} handleLastPage={handleLastPage} currentPage={currentPage}
        totalPages={totalPages} pageItems={pageItems} />
    </section>

  );
}