import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { getName, getIcon, getAgreementInColumns } from '../common';
import { Link } from 'react-router-dom';
import 'font-awesome/css/font-awesome.min.css';

export default function Obligations(props) {
  const QUANTITY_TYPE = new Map([
    ['SoleQuantity', '0'],
    ['AggQuantity', '1'],
    ['AggThrowWeight', '2'],
    ['Other', '3']
  ]);

  const { t } = useTranslation();

  // Keeps track of selection
  const [banSelectLevels, setBanSelectLevels] = useState([]);
  const [resAcqSelectLevels, setResAcqSelectLevels] = useState([]);
  const [resDevSelectLevels, setResDevSelectLevels] = useState([]);
  const [resDispSelectLevels, setResDispSelectLevels] = useState([]);
  const [resPossSelectLevels, setResPossSelectLevels] = useState([]);
  const [resProdSelectLevels, setResProdSelectLevels] = useState([]);
  const [resStatSelectLevels, setResStatSelectLevels] = useState([]);
  const [resTestSelectLevels, setResTestSelectLevels] = useState([]);
  const [resTransSelectLevels, setResTransSelectLevels] = useState([]);
  const [resUseSelectLevels, setResUseSelectLevels] = useState([]);

  // Obligations to list
  const [obligations, setObligations] = useState([]);

  // Available agreements
  const [agreementsOptions, setAgreementsOptions] = useState([]);
  let optionAgreements = [];
  const [obligationAgreements, setObligationAgreements] = useState([]);

  // Weapons
  const [weaponOptions, setWeaponOptions] = useState([]);

  // Title above obligation cards
  const [agreementTitle, setAgreementTitle] = useState('');

  // Set selection at level 1 for ban
  function setSelectLevelsBan(levels, index, level, isSelected, id) {
    if (level === 1) {
      if (levels?.length === 0 ||
        levels?.filter(function(item) { return item.index === index })?.length === 0) {
        levels.push({ index: index, isSelected: isSelected, id: id });
      } else {
        const pos = levels.map(val => val.index).indexOf(index);
        levels[pos].isSelected = isSelected;
        levels[pos].id = id;
      }
    }
    return levels;
  }

  // Set selection at level 1, 2, 3 for restriction
  function setRestrictionLevel(phaseLevels, currentPhase, phaseStr, index, level, id) {
    // Remove all selections at current level on all phases at specific weapon (index)
    phaseLevels.forEach((phaseLevel) => {
      if (phaseLevel.index === index) {
        if (level === 1) {
          const element1 = document.getElementById(phaseLevel.level1);
          element1?.classList.remove('selectedObligationItem');
          element1?.classList.add('fa-chevron-up');
          element1?.classList.remove('fa-chevron-down');
        }
        if (level === 1 || level === 2) {
          const element2 = document.getElementById(phaseLevel.level2);
          element2?.classList.remove('selectedObligationItem');
          element2?.classList.add('fa-chevron-up');
          element2?.classList.remove('fa-chevron-down');
        }
        if (level === 1 || level === 2 || level === 3) {
          const element3 = document.getElementById(phaseLevel.level3);
          element3?.classList.remove('selectedObligationItem');
          element3?.classList.add('fa-chevron-up');
          element3?.classList.remove('fa-chevron-down');
        }
      }
    });
    // Add selection at current level on current phase at specific weapon (index)
    if (currentPhase === phaseStr) {
      if (phaseLevels?.length === 0
        || phaseLevels?.filter(function(item) { return item.index === index })?.length === 0) {
        phaseLevels.push({ index: index, level1: '', level2: '', level3: '' });
      }

      const pos = phaseLevels.map(val => val.index).indexOf(index);
      let elementLevel1 = document.getElementById(phaseLevels[pos].level1);
      let elementLevel2 = document.getElementById(phaseLevels[pos].level2);
      let elementLevel3 = document.getElementById(phaseLevels[pos].level3);
      if (level === 1) {
        phaseLevels[pos].level1 = id;
        elementLevel1 = document.getElementById(phaseLevels[pos].level1);
        if (elementLevel1?.getAttribute('aria-expanded') === 'true') {
          elementLevel1?.classList.add('selectedObligationItem');
          elementLevel2?.classList.add('selectedObligationItem');
          elementLevel3?.classList.add('selectedObligationItem');
          elementLevel1?.classList.add('fa-chevron-down');
          elementLevel2?.classList.add('fa-chevron-down');
          elementLevel3?.classList.add('fa-chevron-down');
          elementLevel1?.classList.remove('fa-chevron-up');
          elementLevel2?.classList.remove('fa-chevron-up');
          elementLevel3?.classList.remove('fa-chevron-up');
        }
      } else if (level === 2) {
        phaseLevels[pos].level2 = id;
        elementLevel2 = document.getElementById(phaseLevels[pos].level2);
        if (elementLevel2?.getAttribute('aria-expanded') === 'true') {
          phaseLevels[pos].level3 = '';
          elementLevel2?.classList.add('selectedObligationItem');
          elementLevel3?.classList.add('selectedObligationItem');
          elementLevel2?.classList.add('fa-chevron-down');
          elementLevel3?.classList.add('fa-chevron-down');
          elementLevel2?.classList.remove('fa-chevron-up');
          elementLevel3?.classList.remove('fa-chevron-up');
        }
      } else if (level === 3) {
        phaseLevels[pos].level3 = id;
        elementLevel3 = document.getElementById(phaseLevels[pos].level3);
        if (elementLevel3?.getAttribute('aria-expanded') === 'true') {
          elementLevel3?.classList.add('selectedObligationItem');
          elementLevel3?.classList.add('fa-chevron-down');
          elementLevel3?.classList.remove('fa-chevron-up');
        }
      }
    }
    return phaseLevels;
  }

  // Toggle select on info boxes
  function toggleSelect(id, index, level, isBan) {
    const element = document.getElementById(id);
    // Ban
    if (isBan) {
      if (level === 1) {
        const pos = banSelectLevels.map(val => val.index).indexOf(index);
        const element1 = document.getElementById(banSelectLevels[pos]?.id);
        element1?.classList.remove('selectedObligationItem');
      }

      if ((!element.classList.contains('collapse') && !element.classList.contains('collapsed')) &&
        element?.classList.contains('active') && level === 1) {
        element?.classList.add('selectedObligationItem');
        setBanSelectLevels(setSelectLevelsBan(banSelectLevels, index, level, true, id));
      } else if (level === 1) {
        setBanSelectLevels(setSelectLevelsBan(banSelectLevels, index, level, false, id));
        element?.classList.remove('selectedObligationItem');
      }
    } else { // Restriction
      const currentPhase = id.replace('collapseRestriction', '').split('-')?.[0];
      setResAcqSelectLevels(setRestrictionLevel(resAcqSelectLevels, currentPhase, 'Acq', index,
        level, id));
      setResDevSelectLevels(setRestrictionLevel(resDevSelectLevels, currentPhase, 'Dev', index,
        level, id));
      setResDispSelectLevels(setRestrictionLevel(resDispSelectLevels, currentPhase, 'Dis', index,
        level, id));
      setResPossSelectLevels(setRestrictionLevel(resPossSelectLevels, currentPhase, 'Pos', index,
        level, id));
      setResProdSelectLevels(setRestrictionLevel(resProdSelectLevels, currentPhase, 'Prod', index,
        level, id));
      setResStatSelectLevels(setRestrictionLevel(resStatSelectLevels, currentPhase, 'Stat', index,
        level, id));
      setResTestSelectLevels(setRestrictionLevel(resTestSelectLevels, currentPhase, 'Test', index,
        level, id));
      setResTransSelectLevels(setRestrictionLevel(resTransSelectLevels, currentPhase, 'Trans',
        index, level, id));
      setResUseSelectLevels(setRestrictionLevel(resUseSelectLevels, currentPhase, 'Use', index,
        level, id));
    }
  }

  // Create box
  function createInfoBox(isActive, id, targetId, title, index, level, isBan, isLast) {
    const boxes = [];
    let boxClasses = 'col-1 g-0 rotate active obligationInfoBox bottom-arrow fa fa-chevron-up';
    if (isLast) {
      boxClasses = 'col-1 g-0 rotate obligationInfoBox disabledObligationBox';
    }
    if (isActive) {
      boxes.push(
        <li className={boxClasses} id={id} key={id} style={{fontSize:13}}
          data-bs-target={`#${targetId}`}
          type='button'
          data-bs-toggle='collapse'
          onClick={() => toggleSelect(id, index, level, isBan)}>
          <div className='obligationbox-area active'>
            <h3 className='obligation-text'>{title}</h3>
          </div>
        </li>
      );
    } else {
      boxes.push(
        <li className='col-1 g-0 rotate obligationInfoBox disabledAgreement' id={id} key={id}
          type='button' onClick={() => toggleSelect(id, index, level, isBan)}>
          <div className='obligationbox-area'>
            <h3>{title}</h3>
          </div>
        </li>
      );
    }

    return (boxes);
  }

  // Create empty columns to adjust collapsed boxes under selected box
  // totCols - total number of boxes in column
  // size - number of boxes to display (adjust)
  // position - position of active element
  function getEmptyCols(totCols, size, position, id) {
    const emptyCols = [];
    let start = 0;
    const middle = Math.round(size / 2);
    const stop = totCols - size - 1;
    if (position <= middle) {
      start = 0;
    } else if (position - middle > stop) {
      start = stop + 1;
    } else {
      start = position - middle+1;
    }
    for (let i = 0; i < start; i++) {
      emptyCols.push(<li className='col-1 emptyObligationBox' id={`${id}-${i}`}
        key={`${id}-${i}`} />);
    }
    return emptyCols;
  }

  // Create collapsed level for the first level (ban)
  function createCollapseBanLevel1(obj, targetId, parentId, index, position) {
    const length = 4;
    const emptyCols = getEmptyCols(9, length, position, `tes-${index}`);
    const elements = [];
    for (let i = 0; i < length; i += 1) {
      elements.push(<li className='col-1 filledObligationBox' id={`ban-level1-${i}`}
        key={`ban-level1-${i}`} />);
    }

    return (
      <div className='accordion-collapse collapse' id={targetId} key={targetId}
        data-bs-parent={`#${parentId}`}>
        <ul className='row obligation-boxes-in-col restrictionObligationBox' id={`banLevel11-${index}`}>
          {emptyCols}
          {elements}
        </ul>
        <ul className='row obligation-boxes-in-col restrictionObligationBox'>
          {emptyCols}
          {createInfoBox(obj.isAssistanceGrant, `${targetId}-grant-id`, `${targetId}-grant`,
            'Grant Assistance', index, 2, true, true)}
          {createInfoBox(obj.isAssistanceGain, `${targetId}-gain-id`, `${targetId}-gain`,
            'Gain Assistance', index, 2, true, true)}
          {createInfoBox(obj.isPermission, `${targetId}-permission-id`, `${targetId}-permission`,
            'Permission', index, 2, true, true)}
          {createInfoBox(obj.isExecution, `${targetId}-execution-id`, `${targetId}-execution`,
            'Execution', index, 2, true, true)}
        </ul>
      </div>
    );
  }

  // Create collapsed level for the first level (restriction)
  function createCollapseRestrictionLevel1(obj, targetId, parentId, index, position) {
    const length = 5;
    const emptyCols = getEmptyCols(8, length, position, `bulle-${index}`);
    const elements = [];
    for (let i = 0; i < length; i += 1) {
      elements.push(<li className='col-1 filledObligationBox' id={`res-level1-${i}`}
        key={`res-level1-${i}`} />);
    }

    return (
      <div className='accordion-collapse collapse' id={`${targetId}`} key={`${targetId}`}
        data-bs-parent={`#${parentId}`}>
        {<ul className='row obligation-boxes-in-col restrictionObligationBox' id={`restrictionLevel1-${index}`}>
          {emptyCols}
          {elements}
        </ul>}
        <ul className='row obligation-boxes-in-col restrictionObligationBox'>
          {emptyCols}
          {createInfoBox(obj.isParty,`${targetId}-parties-id`, `${targetId}-parties`, 'Parties',
            index, 2, false, false)}
          {createInfoBox(obj.isFacility,`${targetId}-facilities-id`, `${targetId}-facilities`,
            'Facilities', index, 2, false, false)}
          {createInfoBox(obj.isLocation,`${targetId}-locations-id`, `${targetId}-locations`,
            'Locations', index, 2, false, false)}
          {createInfoBox(obj.quality.isQuality,`${targetId}-quality-id`, `${targetId}-quality`,
            'Quality', index, 2, false, false)}
          {createInfoBox(obj.quantity.isQuantity,`${targetId}-quantity-id`, `${targetId}-quantity`,
            'Quantity', index, 2, false, false)}

          {createSpecificationBox(obj.facilitySpecific, `${targetId}-facilities`, targetId)}
          {createSpecificationBox(obj.locationSpecific, `${targetId}-locations`, targetId)}
          {createSpecificationBox(obj.partySpecific, `${targetId}-parties`, targetId)}

          {createCollapseRestrictionLevel2Quality(obj, `${targetId}-quality`, targetId, index,
            emptyCols.length + 3)}
          {createCollapseRestrictionLevel2Quantity(obj, `${targetId}-quantity`, targetId, index,
            emptyCols.length + 4)}
        </ul>
      </div>
    );
  }

  // Create collapsed level for the second level of quality (restriction)
  function createCollapseRestrictionLevel2Quality(obj, targetId, parentId, index, position) {
    const length = 2;
    const emptyCols = getEmptyCols(8, length, position, `tes-${index}`);
    const elements = [];
    for (let i = 0; i < length; i += 1) {
      elements.push(<li className='col-1 filledObligationBox' id={`res-level2-q-${i}`}
        key={`res-level2-q-${i}`} />);
    }

    return (
      <div className='accordion-collapse collapse' id={`${targetId}`} key={`${targetId}`}
        data-bs-parent={`#${parentId}`}>
        {<ul className='row obligation-boxes-in-col' id={`restrictionLevel2quantity-${index}`}>
          {emptyCols}
          {elements}
        </ul>}
        <ul className='row obligation-boxes-in-col'>
          {emptyCols}
          {createInfoBox(obj.quality.isCpabilities, `${targetId}-capab-id`,
            `${targetId}-capab`, 'Capabilities', index, 3, false, false)}
          {createInfoBox(obj.quality.isPractice, `${targetId}-practice-id`,
            `${targetId}-practice`, 'Practice', index, 3, false, false)}

          {createSpecificationBox(obj.quality.capabilitiesSpecific,
            `${targetId}-capab`, targetId)}
          {createSpecificationBox(obj.quality.practiceSpecific,
            `${targetId}-practice`, targetId)}
        </ul>
      </div>
    );
  }

  // Create collapsed level for the second level of quantity (restriction)
  function createCollapseRestrictionLevel2Quantity(obj, targetId, parentId, index, position) {
    const length = 3;
    const emptyCols = getEmptyCols(8, length, position+1, `tes-${index}`);
    const elements = [];
    for (let i = 0; i < length; i += 1) {
      elements.push(<li className='col-1 filledObligationBox' id={`res-level2-qn-${i}`}
        key={`res-level2-qn-${i}`} />);
    }
    return (
      <div className='accordion-collapse collapse' id={`${targetId}`} key={`${targetId}`}
        data-bs-parent={`#${parentId}`}>
        {<ul className='row obligation-boxes-in-col' id={`restrictionLevel2quality-${index}`}>
          {emptyCols}
          {elements}
        </ul>}
        <ul className='row obligation-boxes-in-col'>
          {emptyCols}
          {createInfoBox(obj.quantity.type === QUANTITY_TYPE.get('SoleQuantity'),
            `${targetId}-soleQuantity-id`, `${targetId}-soleQuantity`, 'Sole Quantity', index, 3,
            false, false)}
          {createInfoBox(obj.quantity.type === QUANTITY_TYPE.get('AggQuantity'),
            `${targetId}-aggQuantity-id`, `${targetId}-aggQuantity`, 'Aggreg. Quantity', index,
            3, false, false)}
          {createInfoBox(obj.quantity.type === QUANTITY_TYPE.get('AggThrowWeight'),
            `${targetId}-aggThrowWeight-id`, `${targetId}-aggThrowWeight`,
            'Aggreg. Thr. Weight', index, 3, false, false)}

          {createSpecificationBox(obj.quantity.specific, `${targetId}-soleQuantity`,
            targetId)}
          {createSpecificationBox(obj.quantity.specific, `${targetId}-aggQuantity`,
            targetId)}
          {createSpecificationBox(obj.quantity.specific, `${targetId}-aggThrowWeight`,
            targetId)}
        </ul>
      </div>
    );
  }

  // Create text box
  function createSpecificationBox(text, targetId, parentId) {
    return (
      <div className={`accordion-collapse collapse col-8 g-0`} id={targetId} key={targetId}
        data-bs-parent={`#${parentId}`}>
        <p className='row obligationSpecifictaion obligationText'>
          {text}
        </p>
      </div>
    );
  }

  // Fill ban object on the first level
  function fillBanObligations(obj, index) {
    const parentId = `banPhases-${index}`;
    const acqId = `collapseBanAcq-${index}`;
    const devId = `collapseBanDev-${index}`;
    const disId = `collapseBanDis-${index}`;
    const posId = `collapseBanPos-${index}`;
    const prodId = `collapseBanProd-${index}`;
    const statId = `collapseBanStat-${index}`;
    const testId = `collapseBanTest-${index}`;
    const transId = `collapseBanTrans-${index}`;
    const useId = `collapseBanUse-${index}`;
    const elements = [];
    for (let i = 0; i < 9; i += 1) {
      elements.push(<li className='col-1 filledObligationBox' id={`ban-${i}`} key={`ban-${i}`} />);
    }

    return (
    <div className='g-0' id={parentId} key={parentId}>
      <ul className='row obligation-boxes-in-col restrictionObligationBox mx-lg-n5'>
        {elements}
      </ul>
      <ul className='row obligation-boxes-in-col restrictionObligationBox '>
        {createInfoBox(obj.development.ban.isBan, `${devId}-id`, devId, 'Development', index, 1,
          true, false)}
        {createInfoBox(obj.testing.ban.isBan, `${testId}-id`, transId, 'Testing', index, 1,
          true, false)}
        {createInfoBox(obj.production.ban.isBan, `${prodId}-id`, prodId, 'Production', index, 1,
          true, false)}
        {createInfoBox(obj.acquisition.ban.isBan, `${acqId}-id`,  acqId, 'Acquisition', index, 1,
          true, false)}
        {createInfoBox(obj.possession.ban.isBan, `${posId}-id`, posId, 'Possession', index, 1,
          true, false)}
        {createInfoBox(obj.stationing.ban.isBan, `${statId}-id`, statId, 'Stationing', index, 1,
          true, false)}
        {createInfoBox(obj.transfer.ban.isBan, `${transId}-id`, transId, 'Transfer', index, 1,
          true, false)}
        {createInfoBox(obj.use.ban.isBan, `${useId}-id`, useId, 'Use', index, 1,
          true, false)}
        {createInfoBox(obj.disposal.ban.isBan, `${disId}-id`, disId, 'Disposal', index, 1,
          true, false)}
      </ul>
      <div className=''>
        {createCollapseBanLevel1(obj.development.ban, devId, parentId, index, 0)}
        {createCollapseBanLevel1(obj.testing.ban, testId, parentId, index, 1)}
        {createCollapseBanLevel1(obj.production.ban, prodId, parentId, index, 2)}
        {createCollapseBanLevel1(obj.acquisition.ban, acqId, parentId, index, 3)}
        {createCollapseBanLevel1(obj.possession.ban, posId, parentId, index, 4)}
        {createCollapseBanLevel1(obj.stationing.ban, statId, parentId, index, 5)}
        {createCollapseBanLevel1(obj.transfer.ban, transId, parentId, index, 6)}
        {createCollapseBanLevel1(obj.use.ban, useId, parentId, index, 7)}
        {createCollapseBanLevel1(obj.disposal.ban, disId, parentId, index, 8)}
      </div>
    </div>
    );
  }

  // Fill restriction object on the first level
  function fillRestrictionObligations(obj, index) {
    const parentId = `restrictionPhases-${index}`;
    const parentId2 = `collapseRestriction-${index}`;
    const acqId = `collapseRestrictionAcq-${index}`;
    const devId = `collapseRestrictionDev-${index}`;
    const disId = `collapseRestrictionDis-${index}`;
    const posId = `collapseRestrictionPos-${index}`;
    const prodId = `collapseRestrictionProd-${index}`;
    const testId = `collapseRestrictionTest-${index}`;
    const transId = `collapseRestrictionTrans-${index}`;
    const useId = `collapseRestrictionUse-${index}`;
    const elements = [];
    for (let i = 0; i < 8; i += 1) {
      elements.push(<li className='col-1 filledObligationBox' id={`restriction-phase-${i}`}
        key={`restriction-phase-${i}`} />);
    }
    return (
    <div className='' id={parentId} key={parentId}>
      <ul className='row obligation-boxes-in-col restrictionObligationBox'>
        {elements}
      </ul>
      <ul className='row obligation-boxes-in-col restrictionObligationBox'>
        {createInfoBox(obj.development.restriction.isRestriction, `${devId}-id`, devId,
          'Development', index, 1, false, false)}
        {createInfoBox(obj.testing.restriction.isRestriction, `${testId}-id`, testId,
          'Testing', index, 1, false, false)}
        {createInfoBox(obj.production.restriction.isRestriction, `${prodId}-id`, prodId,
          'Production', index, 1, false, false)}
        {createInfoBox(obj.acquisition.restriction.isRestriction, `${acqId}-id`, acqId,
          'Acquisition', index, 1, false, false)}
        {createInfoBox(obj.possession.restriction.isRestriction, `${posId}-id`, posId,
          'Possession', index, 1, false, false)}
        {createInfoBox(obj.transfer.restriction.isRestriction, `${transId}-id`, transId,
          'Transfer', index, 1, false, false)}
        {createInfoBox(obj.use.restriction.isRestriction, `${useId}-id`, useId,
          'Use', index, 1, false, false)}
        {createInfoBox(obj.disposal.restriction.isRestriction, `${disId}-id`, disId,
          'Disposal', index, 1, false, false)}
      </ul>

      <div className='obligation-boxes-in-col' id={parentId2} key={parentId2}>
        {createCollapseRestrictionLevel1(obj.development.restriction, devId, parentId, index, 0)}
        {createCollapseRestrictionLevel1(obj.testing.restriction, testId, parentId, index, 1)}
        {createCollapseRestrictionLevel1(obj.production.restriction, prodId, parentId, index, 2)}
        {createCollapseRestrictionLevel1(obj.acquisition.restriction, acqId, parentId, index, 3)}
        {createCollapseRestrictionLevel1(obj.possession.restriction, posId, parentId, index, 4)}
        {createCollapseRestrictionLevel1(obj.transfer.restriction, transId, parentId, index, 5)}
        {createCollapseRestrictionLevel1(obj.use.restriction, useId, parentId, index, 6)}
        {createCollapseRestrictionLevel1(obj.disposal.restriction, disId, parentId, index, 7)}
      </div>
    </div>

    );
  }

  // Create cards for all weapons
  function populateObligations(phaseDataObj, isAgreement) {
    const obligationObjs = [];
    // sort alphabetically
    if (isAgreement) {
      phaseDataObj.res?.sort((a, b) => a.item.localeCompare(b.item));
    } else {
      phaseDataObj.res?.sort((a, b) => a.name.localeCompare(b.name));
    }

    for (let i = 0; i< phaseDataObj.res.length; i += 1) {
      if (phaseDataObj.res[i].item !== 'N/A') {
        const id = `toggle-weapon-${i}-${phaseDataObj.res[i].agreement_id}`
        const banObligations = [];
        banObligations.push(fillBanObligations(phaseDataObj.res[i], i));
        const restrictionObligations = [];
        let cardTitle = phaseDataObj.res[i].item;
        if (!isAgreement) {
          cardTitle = phaseDataObj.res[i].name;
        }
        restrictionObligations.push(fillRestrictionObligations(phaseDataObj.res[i], i));
        obligationObjs.push(
  <div className='mb-2' key={`weapon-category-${i}`}>
    <h2 className='toggle-header'><button className='toggle-button button-icon'
      data-bs-toggle='collapse' data-bs-target={`#${id}`}
      aria-controls={id} aria-expanded='false'>{cardTitle}</button></h2>
    <div id={id} key={id} className='toggle-content collapse'>
      <div id={`weaponCard-${i}`} className='card'>
        <div className='card-body'>
          <div id={`WeaponTitle-${i}`} className="only-printme row">
            <h2>{cardTitle}</h2>
          </div>
          <div className='obligaton-print'></div>
          <div className='row no-printme justify-content-end'>
            <div className='col-2'>
              <button className='icon-position-left icon-print obligaton-print no-printme'
                onClick={() => {
                  document.getElementById(`WeaponTitle-${i}`).classList.add('printThis');
                  document.getElementById(id).classList.add('printThis');
                  document.getElementById('printerFoot').classList.add('printThis');
                  document.getElementById(`weaponCard-${i}`).classList
                    .add('obligations-weapon-border');
                  window.print();
                  document.getElementById(`WeaponTitle-${i}`).classList.remove('printThis');
                  document.getElementById(id).classList.remove('printThis');
                  document.getElementById('printerFoot').classList.remove('printThis');
                  document.getElementById(`weaponCard-${i}`).classList
                    .remove('obligations-weapon-border');}}>
                {t('print')}
              </button>
            </div>
          </div>

          <div className='mb-2 row mb-4'>
            <div className='col-md-6'>
              <div className='amc-toggle-box'>
                <h3>Ban</h3>
                {banObligations}
              </div>
            </div>
            <div className='col-md-6'>
              <div className='amc-toggle-box'>
                <h3>Restriction</h3>
                {restrictionObligations}
              </div>
            </div>
          </div>

          <div id='printerFoot' className="only-printme row">
            <div className='col text-uppercase'>
                {t('header.first.title')}
                <br/>
                {t('header.second.title')}
            </div>
            <div className='col'>
              The Weapon-specific Obligations Visualizer, available for free at AMCPD.UU.SE
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
        );
      }
    }
    return obligationObjs;
  }

  useEffect(() => {
    fetchAgreements();
    fetchWeapons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function populateList(agreements, key) {
    const items = [];
    for (let i = 0; i < agreements.length; i += 1) {
      const name = getName(agreements[i].name, 35);
      const nameTitle = agreements[i].name.length > 35 ? agreements[i].name : '';
      const iconClass = getIcon(agreements[i].nuclear_weapons_free_zones, agreements[i].laterality);
      // Only give links to valid agreements
      if (agreements[i].isValid) {
        items.push(
          <li className='list-group-item list-group-item-action' id={`agreement-li-${i}`}
            key={`agreement-li-${i}`}
            onClick={() => handleAgreementChange(agreements[i].agreement_id)}>
            <Link to={`?id=${agreements[i].agreement_id}`}>
              <span className={`list-group-item-action-title treaty ${iconClass}`}
                title={nameTitle}>
                {name}
              </span>
            </Link>
          </li>
        );
      } else {
        items.push(
          <li className='list-group-item list-group-item-action disabledAgreement'
            id={`agreement-li-${i}`} key={`agreement-li-${i}`}>
            <span className={`list-group-item-action-title treaty ${iconClass}`}
              title={nameTitle}>
              {name}
            </span>
          </li>
        );
      }
    }

    return (
      <div className=' col-md-4 mb-0' key={key}>
        <ul className='list-group list-group-flush restrictionObligationBox'>
            {items}
        </ul>
      </div>
    );
  }

  // Get all agreements from database
  const fetchAgreements = async () => {
    try {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      };

      const responseTot = await fetch('/api/getAgreements', requestOptions);
      const resTot = await responseTot.json();
      const totAgreements = JSON.parse(resTot.data)?.result;

      const response = await fetch('/api/obligation/getAgreements', requestOptions);
      const res = await response.json();
      const agreements = JSON.parse(res.data).result;
      const agrees = [];
      if (agreements && agreements?.length > 0) {
        if (totAgreements && totAgreements.length > 0) {
          totAgreements.forEach(agreement => {
            let isValid = true;
            if (!agreements.find((a) => a.agreement_id === agreement.agreement_id)) {
              isValid = false;
            }
            agrees.push({agreement_id: agreement.agreement_id, name: agreement.name,
              nuclear_weapons_free_zones: agreement.nuclear_weapons_free_zones,
              laterality: agreement.laterality, isValid: isValid});

          });
        }
        agrees?.sort((a, b) => a.name.localeCompare(b.name));
        optionAgreements = agrees;
        setObligationAgreements(agrees);

        const agreementsInColumns = getAgreementInColumns(agrees);
        const colsWithAgreements = [];
        colsWithAgreements.push(populateList(agreementsInColumns[0].column1, 'agreem-1'));
        colsWithAgreements.push(populateList(agreementsInColumns[0].column2, 'agreem-2'));
        colsWithAgreements.push(populateList(agreementsInColumns[0].column3, 'agreem-3'));

        const agreementOptions2 = [];
        agreementOptions2.push(
          <div className='treatylist' key='agreementDb'>
            <div className='row obligation-boxes-in-col'>{colsWithAgreements}</div>
          </div>
        );

        setAgreementsOptions(agreementOptions2);

        fetchObligations(agreements[0].agreement_id);
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Get all weapons from database
  const fetchWeapons = async () => {
    try {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      };

      const response = await fetch('/api/obligation/getWeapons', requestOptions);
      const res = await response.json();
      const weapons = JSON.parse(res.data).result;
      if (weapons && weapons?.length > 0) {
        weapons?.sort((a, b) => a.item.localeCompare(b.item));
        const gunOptions = [];
        for (let i = 0; i < weapons.length; i += 1) {
          gunOptions.push(
            <option value={`${weapons[i].item}`} id={`tt-${i}`} key={`tt-${i}`}>
              {`${weapons[i].item} (${weapons[i].count})`}
            </option>);
        }
        setWeaponOptions(gunOptions);
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Get all obligations from database depending on selected agreement (id)
  const fetchObligations= async (id) => {
    try {
      const requestOptions = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
      };
      const response = await fetch(`/api/obligation/get?id=${id}`, requestOptions);
      const res = await response.json();
      const data = await JSON.parse(res?.data);
      let name = optionAgreements.length > 0 ?
        optionAgreements.filter(function(item) { return item.agreement_id === id;})[0].name
        : obligationAgreements.filter(function(item) {
          return item.agreement_id === id;})[0].name;
      setAgreementTitle(name);
      const guns = [];
      guns.push(populateObligations(data, true));
      setObligations(guns);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Get all obligations from database depending on selected weapon (item)
  const fetchObligationsByWeapon = async (item) => {
    try {
      const requestOptions = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
      };
      const response = await fetch(`/api/obligation/getByWeapon?item=${item}`, requestOptions);
      const res = await response.json();
      const data = await JSON.parse(res?.data);
      setAgreementTitle(item);

      const guns = [];
      guns.push(populateObligations(data, false));
      setObligations(guns);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  function handleAgreementChange(id) {
    // Not enough to clear with only setXXXLevels([]);
    banSelectLevels.splice(0, banSelectLevels.length);
    setBanSelectLevels([]);

    resAcqSelectLevels.splice(0, resAcqSelectLevels.length);
    setResAcqSelectLevels([]);

    resDevSelectLevels.splice(0, resDevSelectLevels.length);
    setResDevSelectLevels([]);

    resDispSelectLevels.splice(0, resDispSelectLevels.length);
    setResDispSelectLevels([]);

    resPossSelectLevels.splice(0, resPossSelectLevels.length);
    setResPossSelectLevels([]);

    resProdSelectLevels.splice(0, resProdSelectLevels.length);
    setResProdSelectLevels([]);

    resStatSelectLevels.splice(0, resStatSelectLevels.length);
    setResStatSelectLevels([]);

    resTestSelectLevels.splice(0, resTestSelectLevels.length);
    setResTestSelectLevels(resTestSelectLevels);

    resTransSelectLevels.splice(0, resTransSelectLevels.length);
    setResTransSelectLevels([]);

    resUseSelectLevels.splice(0, resUseSelectLevels.length);
    setResUseSelectLevels([]);

    fetchObligations(id);
  }

  return (
    <div>
      <div id='agreement' className='no-printme'>
        <div className='card'>
          <div className='card-body overflow-scroll height-250'>
            <div className='row obligation-boxes-in-col'>
              <fieldset>
                <legend>{t('obligations.agreementFilter.title')}</legend>
                {agreementsOptions}
              </fieldset>
            </div>
          </div>
        </div>
      </div>

      <div className='col-md-4'>
        <label htmlFor='weapons'>{t('obligations.select.weapon')}</label>
        <select name='weapons' id='weapons' className='form-select mb-4'
          onChange={(e) => fetchObligationsByWeapon(e.target.value)}>
          {weaponOptions}
        </select>
      </div>

      <section className='component mb-5' id='component-toggle'
        aria-labelledby='section-header-toggle'>
        <div className='anchor' id='toggle'></div>
        <div className='row justify-content-between'>
          <div className='col-md-10'>
            <h2 id='section-header-toggle'>
              {agreementTitle}
            </h2>
          </div>
        </div>
        <div className='row obligation-boxes-in-col'>
          <div className='col-md'>
            {obligations}
          </div>
        </div>
      </section>
    </div>
  );
}
