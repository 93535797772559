import { useState, useEffect } from 'react';
import { getName, getIcon, getAgreementInColumns } from './common';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {removeLeadingZeros} from './common';

function populateList(agreements, key) {
  const items = [];
  const currentLang = localStorage.getItem('i18nextLng') || 'sv';
  agreements.forEach(agreement => {
    const name = getName(agreement.name, 35);
    const nameTitle = agreement.name.length > 35 ? agreement.name : '';
    const iconClass = getIcon(agreement.nuclear_weapons_free_zones, agreement.laterality);
    items.push(
      <li className='list-group-item list-group-item-action' key={agreement.agreement_id}>
        <Link to={`../${currentLang}/profilePage?id=${agreement.agreement_id}`}>
          <span className={`list-group-item-action-title treaty ${iconClass}`}
            title={nameTitle}>
            {name}
          </span>
        </Link>
      </li>);
  });

  return (
    <div className=' col-md-4 mb-0' key={key}>
      <ul className='list-group list-group-flush'>
          {items}
      </ul>
    </div>
  );
}

function AgreementDatabase() {
  const { t } = useTranslation();
  const [agreements, setAgreements] = useState([]);

  useEffect(() => {
    fetchAgreements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAgreements = async () => {
    try {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      };
      const response = await fetch('/api/getAgreements', requestOptions);
      const res = await response.json();
      const agreements = JSON.parse(res.data);

      if (agreements && agreements?.result?.length > 0) {
        const agreementNames = []
        for (let i = 0; i < agreements.result.length; i++) {
          // Only give links for agreements with nucclear weapons
          if (agreements.result[i].weapons_items_topic === '0') {
            agreementNames.push({ name: agreements.result[i].name,
              tmp_id:
                parseInt(removeLeadingZeros(agreements.result[i].agreement_id.substring(0,3))),
              agreement_id: agreements.result[i].agreement_id,
              weapons_items_topic: agreements.result[i].weapons_items_topic,
              nuclear_weapons_free_zones: agreements.result[i].nuclear_weapons_free_zones,
              laterality: agreements.result[i].laterality});
          }
        }
        const sortedAgreements = agreementNames.sort((a, b) => a.tmp_id - b.tmp_id);
        const agreementsInColumns = getAgreementInColumns(sortedAgreements);
        const colsWithAgreements = [];
        colsWithAgreements.push(populateList(agreementsInColumns[0].column1, 'agreem-1'));
        colsWithAgreements.push(populateList(agreementsInColumns[0].column2, 'agreem-2'));
        colsWithAgreements.push(populateList(agreementsInColumns[0].column3, 'agreem-3'));
        const agreementOptions = [];
        agreementOptions.push(
          <div className='treatylist' key='agreementDb'>
            <div className='row'>{colsWithAgreements}
            </div>
          </div>
        );
        setAgreements(agreementOptions);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
<div className='container mt-5'>
  <h1>{t('home.agreementDb.title')}</h1>
  <p>{t('agreementDatabase.preamble')}</p>
  {agreements}
</div>
  );
}

export default AgreementDatabase;