import * as d3 from "d3";
import { LineChart, Line, XAxis, YAxis, Tooltip, Brush, ResponsiveContainer,Label,ReferenceLine } from 'recharts';
import { useState, useEffect } from 'react';
import Legend from './legend';


export default function Diagram(props) {
  const [loader, setLoader] = useState(false);
  // Result
  const [results, setResults] = useState([]);

  const [EntryIntoForceDates, setEntryIntoForceDates] = useState([]);

  useEffect(() => {
    fetchAgreementParties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update demonstrated data whenever page, sort order or any of the filters are changed
  useEffect(() => {
    fetchAgreementParties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filterAgreements, props.filterCountries, props.filterActions, props.filterWeapons, props.displayCountryName]);

  useEffect(() => {
    fetchEntryIntoForceDates();
  }, []);

  //Convert date to timestamp for x axis
  const getXValueData = data => {
    const time = data.date.getTime();
    return time;
  };

  const groupedData = results?.reduce((result, item) => {
    const key = `${item.agreement_id}-${item.name}-${item.action}-${item.date}-${item.entered}-${item.withdrawal}-${item.signature}-${item.ratification}-${item.countcountries}-${item.agreementcount}`;
  if (!result[key]) {
    result[key] = { agreement_id: item.agreement_id, name: item.name, action: item.action, date: item.date, entered: item.entered, withdrawal: item.withdrawal, signature: item.signature, ratification: item.ratification, countcountries: item.countcountries, agreementcount: item.agreementcount, countries: [], countries_abbrev: [] };
  }
  result[key].countries.push(item.country);
  result[key].countries_abbrev.push(item.country_alpha);
  return result;
}, {});

  const groupedDataCountry = results?.reduce((result, item) => {
    const key = `${item.agreement_id}-${item.name}-${item.action}-${item.date}-${item.entered}-${item.withdrawal}-${item.signature}-${item.ratification}-${item.country}-${item.countcountries}-${item.agreementcount}`;
  if (!result[key]) {
    result[key] = { agreement_id: item.agreement_id, name: item.name, action: item.action, date: item.date, entered: item.entered, withdrawal: item.withdrawal, signature: item.signature, ratification: item.ratification, countcountries: item.countcountries, agreementcount: item.agreementcount, countries: item.country, countries_abbrev: item.country_alpha };
  }
  return result;
}, {});

  const graphData = Object.values(groupedData).map(({ agreement_id, name, action, date, entered, withdrawal, signature, ratification, countcountries, agreementcount, countries, countries_abbrev }) => ({
    agreement_id,
    name,
    action,
    date: new Date(date),
    entered,
    withdrawal,
    signature,
    ratification,
    countries: countries.join(', '),
    countries_abbrev: countries_abbrev.join(', '),
    count: countries.join(', ').split(",").length,
    agreementcount,
    value: parseInt(countcountries) + parseInt(countries.join(', ').split(",").length - 1)
  })).sort((a, b) => a.date - b.date);

  const graphDataCountry = Object.values(groupedDataCountry).map(({ agreement_id, name, action, date, entered, withdrawal, signature, ratification, countcountries, agreementcount, countries, countries_abbrev }) => ({
    agreement_id,
    name,
    action,
    date: new Date(date),
    entered,
    withdrawal,
    signature,
    ratification,
    countries,
    countries_abbrev,
    count: 1,
    agreementcount,
    value: 0
  })).sort((a, b) => a.date - b.date);

  function getDate(){
    return graphData
        .filter(d => d.date !== null && d.date !== 'N/A')
        .map(d => new Date(d.date).getTime());
  }
  function getEntered(){
    return graphData
        .filter(d => d.entered !== null && d.entered !== 'N/A')
        .map(d => new Date(d.entered).getTime());
  }
  function getRatification(){
    return graphData
        .filter(d => d.ratification !== null && d.ratification !== 'N/A')
        .map(d => new Date(d.ratification).getTime());
  }
  function getSignature(){
    return graphData
        .filter(d => d.signature !== null && d.signature !== 'N/A')
        .map(d => new Date(d.signature).getTime());
  }
  function getWithdrawal(){
    return graphData
        .filter(d => d.withdrawal !== null && d.withdrawal !== 'N/A')
        .map(d => new Date(d.withdrawal).getTime());
  }

  const arrOfDates = [...getDate(), ...getEntered(), ...getRatification(), ...getSignature(), ...getWithdrawal()];

  //Start the x-axis five years before the first date
  const addMlSeconds = 1000 * 60 * 60 * 24 * 365 * 5;
  const minX = new Date(Math.min(...arrOfDates)).getTime() - addMlSeconds;
  const maxX = new Date(Math.max(...arrOfDates)).getTime() + addMlSeconds;

  const minY = 0;
  const maxY = Math.max(...graphData.map(item => item.value));
  const domainToday = d3.scaleTime().domain([minX, maxX]);
  const timeFormatter = (tick) => {return d3.timeFormat('%Y')(new Date(tick));};
  const xticks = domainToday.ticks(d3.timeYear.every(5));

  function findUniqueObjectsAndSelectProperties(array, propertiesToConsider, propertiesToSelect) {
    const uniqueObjects = {};

    array.forEach(item => {
      const key = propertiesToConsider.map(property => item[property]).join('_');
      const containsEmptyString = propertiesToConsider.some(property => item[property] === '');

      if (!containsEmptyString) {
        if (!uniqueObjects[key]) {
          uniqueObjects[key] = {};
          propertiesToSelect.forEach(property => {
            uniqueObjects[key][property] = item[property];
          });
        }
      }
    });
    return Object.values(uniqueObjects);
  }

  // Define the properties to be considered for uniqueness and selected properties
  const propertiesToConsiderEntered = ['name', 'entered'];
  const propertiesToSelectEntered = ['name', 'entered'];
  const propertiesToConsiderSignature= ['name', 'signature'];
  const propertiesToSelectSignature = ['name', 'signature'];
  const propertiesToConsiderRatification = ['name', 'ratification'];
  const propertiesToSelectRatification = ['name', 'ratification'];
  const propertiesToConsiderWithdrawal = ['name', 'withdrawal'];
  const propertiesToSelectWithdrawal = ['name', 'withdrawal'];

  // Find unique objects based on the specified properties and select the specified properties
  let uniqueObjectsEntered = findUniqueObjectsAndSelectProperties(graphData, propertiesToConsiderEntered, propertiesToSelectEntered);
  const uniqueObjectsSignature = findUniqueObjectsAndSelectProperties(graphData, propertiesToConsiderSignature, propertiesToSelectSignature);
  const uniqueObjectsRatification = findUniqueObjectsAndSelectProperties(graphData, propertiesToConsiderRatification, propertiesToSelectRatification);
  const uniqueObjectsWithdrawal = findUniqueObjectsAndSelectProperties(graphData, propertiesToConsiderWithdrawal, propertiesToSelectWithdrawal);

  const mergedObjectsSignatureAndRatification = [...new Set([...uniqueObjectsSignature, ...uniqueObjectsRatification])]

 //Get unique objects while comparing property with different name and renaming the new property to date
  const uniqueSignatureAndRatification = mergedObjectsSignatureAndRatification.filter((obj, index, self) => {
    return index === self.findIndex(otherObj => {
        return otherObj.signature === obj.signature || otherObj.ratification === obj.ratification
    });
  }).map(obj => {
    return {
    name: obj.name,
    date: obj.signature !== undefined ? obj.signature : obj.ratification,
    };
  });

  function addMonthToMatchingDates(uniqueObjectsEntered, uniqueSignatureAndRatification) {

    function addOneMonth(dateString) {
      let date = new Date(dateString);
      date.setMonth(date.getMonth() + 1);
      return date.toLocaleDateString('sv-SE');
    }
    uniqueObjectsEntered.forEach(item1 => {
      uniqueSignatureAndRatification.forEach(item2 => {
        if (item1.entered !== 'N/A' && item1.entered === item2.date) {
          item1.entered = addOneMonth(item1.entered);
        }
      });
    });
    return uniqueObjectsEntered;
  }

  uniqueObjectsEntered = addMonthToMatchingDates(uniqueObjectsEntered, uniqueSignatureAndRatification);

  const renderEnteredLines = () => {
  const lines = uniqueObjectsEntered.map((value, index) =>  (
    <ReferenceLine x={new Date(value.entered).getTime()} stroke="green" strokeDasharray="3 3"
      strokeWidth={2} id={`refLine-line-${index}`} key={`refLine-line-${index}`}>
      <Label value={value.name + ' EIF ' +
        new Date(value.entered).toLocaleString('sv-SE',{year: 'numeric',month: '2-digit',day: '2-digit'})}
        position="insideLeft" angle={-90} fontSize={10} style={{ textAnchor: 'middle' } }/>
    </ReferenceLine>
    ));
  return lines;
  }

  const renderEnteredLinesCountries = () => {
    const lines = EntryIntoForceDates?.map((value, index) =>  (
      <ReferenceLine x={new Date(value.entered).getTime()} stroke="green" strokeDasharray="3 3"
        strokeWidth={2} id={`refLine-ountry-${index}`} key={`refLine-country-${index}`}>
        <Label value={value.name + ' EIF ' +
          new Date(value.entered).toLocaleString('sv-SE',{year: 'numeric',month: '2-digit',day: '2-digit'})}
          position="insideLeft" angle={-90} fontSize={10} style={{ textAnchor: 'middle' } }/>
      </ReferenceLine>
      ));
    return lines;
    }

  const renderSignatureLines = () => {
  const lines = uniqueSignatureAndRatification.map((value, index) =>  (
    <ReferenceLine x={new Date(value.date).getTime()} stroke="yellow" strokeDasharray="3 3"
      strokeWidth={2} id={`refLine-signature-${index}`} key={`refLine-signature-${index}`}>
      <Label value={value.name + ' Signature ' +
        new Date(value.date).toLocaleString('sv-SE',{year: 'numeric',month: '2-digit',day: '2-digit'})}
        position="insideLeft" angle={-90} fontSize={10} style={{ textAnchor: 'middle' }}/>
    </ReferenceLine>
    ));
  return lines;
  }

  const renderWithdrawalLines = () => {
    const lines = uniqueObjectsWithdrawal.map((value, index) =>  (
      <ReferenceLine x={new Date(value.withdrawal).getTime()} stroke="red" strokeDasharray="3 3"
        id={`refLine-withdrawal-${index}`} key={`refLine-withdrawal-${index}`} strokeWidth={2} >
        <Label value={value.name + ' Withdrawal ' +
          new Date(value.withdrawal).toLocaleString('sv-SE',{year: 'numeric',month: '2-digit',day: '2-digit'})}
          position="insideLeft" angle={-90} fontSize={10} style={{ textAnchor: 'middle' }}/>
      </ReferenceLine>
      ));
    return lines;
    }

  const CustomizedDotCircle = (props) => {
    const { cx, cy, payload } = props;
    let size = 0;
    switch (true) {
      case (payload.count === 1):
        size = 6;
        break;
      case (payload.count === 2):
        size = 8;
        break;
      case (payload.count > 2 && payload.count <= 10):
        size = 10;
        break;
      case (payload.count > 10 && payload.count <= 20):
        size = 12;
        break;
      case (payload.count > 20 && payload.count <= 30):
        size = 14;
        break;
      case (payload.count > 30 && payload.count <= 40):
        size= 16;
        break;
      case (payload.count > 40 && payload.count < 50):
        size = 18;
        break;
      case (payload.count > 50):
        size = 20;
        break;
      default:
        console.log("payload.count does not match any case");
        break;
    }

    if (payload.action === 'Acceptance') {
        return (
            <svg>
            <circle cx={cx} cy={cy} r={size} fill="#FF9300"/>
        </svg>
        );
      }
    if (payload.action === 'Accession') {
        return (
            <svg>
            <circle cx={cx} cy={cy} r={size} fill="#7FD1FF"/>
        </svg>
        );
      }
      if (payload.action === 'Approval') {
        return (
            <svg>
            <circle cx={cx} cy={cy} r={size} fill="#0082CC"/>
        </svg>
        );
      }
      if (payload.action === 'Consent to be bound') {
        return (
            <svg>
            <circle cx={cx} cy={cy} r={size} fill="#96EDBF"/>
        </svg>
        );
      }
      if (payload.action === 'Definitive signature') {
        return (
            <svg>
            <circle cx={cx} cy={cy} r={size} fill="#F4E922"/>
        </svg>
        );
      }
      if (payload.action === 'Ratification') {
        return (
      <svg>
      <circle cx={cx} cy={cy} r={size} fill="#0EAD29"/>
  </svg>
      );
      }
      if (payload.action === 'Signature subject to ratification') {
        return (
      <svg>
      <circle cx={cx} cy={cy} r={size} fill="#AC83E5"/>
      </svg>
      );
      }
      if (payload.action === 'Succession') {
        return (
            <svg>
            <circle cx={cx} cy={cy} r={size} fill="#02B2A1"/>
        </svg>
        );
      }
      if (payload.action === 'Withdrawal') {
        return (
            <svg>
            <circle cx={cx} cy={cy} r={size} fill="#CC000A"/>
        </svg>
        );
      }
      if (payload.action === 'Participants') {
        return (
            <svg>
            <circle cx={cx} cy={cy} r={size} fill="#F794E0"/>
        </svg>
        );
      }
  };

  const CustomizedDotRect = (props) => {
    const { cx, cy, payload } = props;
    let size = 0;
    switch (true) {
      case (payload.count === 1):
        size = 12;
        break;
      case (payload.count === 2):
        size = 14;
        break;
      case (payload.count > 2 && payload.count <= 10):
        size = 16;
        break;
      case (payload.count > 10 && payload.count <= 20):
        size = 18;
        break;
      case (payload.count > 20 && payload.count <= 30):
        size = 20;
        break;
      case (payload.count > 30 && payload.count <= 40):
        size = 22;
        break;
      case (payload.count > 40 && payload.count < 50):
        size = 24;
        break;
      case (payload.count > 50):
        size = 26;
        break;
      default:
        console.log("payload.count does not match any case");
        break;
    }
    if (payload.action === 'Acceptance') {
        return (
            <svg>
            <rect x={cx-7} y={cy-7} width={size} height={size} fill="#FF9300" />
        </svg>
        );
      }
    if (payload.action === 'Accession') {
        return (
            <svg>
            <rect x={cx-7} y={cy-7} width={size} height={size} fill="#7FD1FF" />
        </svg>
        );
      }
      if (payload.action === 'Approval') {
        return (
            <svg>
            <rect x={cx-7} y={cy-7} width={size} height={size} fill="#0082CC" />
        </svg>
        );
      }
      if (payload.action === 'Consent to be bound') {
        return (
            <svg>
            <rect x={cx-7} y={cy-7} width={size} height={size} fill="#96EDBF" />
        </svg>
        );
      }
      if (payload.action === 'Definitive signature') {
        return (
            <svg>
            <rect x={cx-7} y={cy-7} width={size} height={size} fill="#F4E922" />
        </svg>
        );
      }
      if (payload.action === 'Ratification') {
        return (
          <svg>
          <rect x={cx-7} y={cy-7} width={size} height={size} fill="#0EAD29" />
      </svg>
      );
      }
      if (payload.action === 'Signature subject to ratification') {
        return (
          <svg>
          <rect x={cx-7} y={cy-7} width={size} height={size} fill="#AC83E5" />
      </svg>
      );
      }
      if (payload.action === 'Succession') {
        return (
            <svg>
            <rect x={cx-7} y={cy-7} width={size} height={size} fill="#02B2A1" />
        </svg>
        );
      }
      if (payload.action === 'Withdrawal') {
        return (
            <svg>
            <rect x={cx-7} y={cy-7} width={size} height={size} fill="#CC000A" />
        </svg>
        );
      }
      if (payload.action === 'Participants') {
        return (
            <svg>
            <rect x={cx-7} y={cy-7} width={size} height={size} fill="#F794E0" />
        </svg>
        );
      }
  };

  const CustomizedDotSign = (props) => {
    const { cx, cy, payload } = props;
    let size = 0;
    switch (true) {
      case (payload.count === 1):
        size = "7.5,0 15,7.5 7.5,15 0,7.5";
        break;
      case (payload.count === 2):
        size = "10,0 20,10 10,20 0,10";
        break;
      case (payload.count > 2 && payload.count <= 10):
        size = "12.5,0 25,12.5 12.5,25 0,12.5";
        break;
      case (payload.count > 10 && payload.count <= 20):
        size = "15,0 30,15 15,30 0,15";
        break;
      case (payload.count > 20 && payload.count <= 30):
        size = "17.5,0 35,17.5 17.5,35 0,17.5";
        break;
      case (payload.count > 30 && payload.count <= 40):
        size = "20,0 40,20 20,40 0,20";
        break;
      case (payload.count > 40 && payload.count < 50):
        size = "22.5,0 45,22.5 22.5,45 0,22.5";
        break;
      case (payload.count > 50):
        size = "25,0 50,25 25,50 0,25";
        break;
      default:
        console.log("payload.count does not match any case");
        break;
    }
    if (payload.action === 'Acceptance') {
        return (
		 <svg width="100" height="100" x={cx-10} y={cy-10}>
      <polygon points={size} fill="#FF9300" />
    </svg>
        );
      }
    if (payload.action === 'Accession') {
        return (
		 <svg width="100" height="100" x={cx-10} y={cy-10}>
      <polygon points={size} fill="#7FD1FF" />
    </svg>
        );
      }
      if (payload.action === 'Approval') {
        return (
		<svg width="100" height="100" x={cx-10} y={cy-10}>
      <polygon points={size} fill="#0082CC" />
    </svg>
        );
      }
      if (payload.action === 'Consent to be bound') {
        return (
		 <svg width="100" height="100" x={cx-10} y={cy-10}>
      <polygon points={size} fill="#96EDBF" />
    </svg>
        );
      }
      if (payload.action === 'Definitive signature') {
        return (
		 <svg width="100" height="100" x={cx-10} y={cy-10}>
      <polygon points={size} fill="#F4E922" />
    </svg>
        );
      }
      if (payload.action === 'Ratification') {
        return (
      <svg width="100" height="100" x={cx-10} y={cy-10}>
      <polygon points={size} fill="#0EAD29" />
    </svg>
      );
      }
      if (payload.action === 'Signature subject to ratification') {
        return (
	 <svg width="100" height="100" x={cx-10} y={cy-10}>
      <polygon points={size} fill="#AC83E5" />
    </svg>
      );
      }
      if (payload.action === 'Succession') {
        return (
		 <svg width="100" height="100" x={cx-10} y={cy-10}>
      <polygon points={size} fill="#02B2A1" />
    </svg>
        );
      }
      if (payload.action === 'Withdrawal') {
        return (
		 <svg width="100" height="100" x={cx-10} y={cy-10}>
      <polygon points={size} fill="#CC000A" />
    </svg>
        );
      }
      if (payload.action === 'Participants') {
        return (
		 <svg width="100" height="100" x={cx-10} y={cy-10}>
      <polygon points={size} fill="#F794E0" />
    </svg>
        );
      }
  };



  const CustomizedDotTri = (props) => {
    const { cx, cy, payload } = props;
    let size = 0;
    switch (true) {
      case (payload.count === 1):
        size = "7.5,0.75 14.25,14.25 0.75,14.25";
        break;
      case (payload.count === 2):
        size = "10,1 19,19 1,19";
        break;
      case (payload.count > 2 && payload.count <= 10):
        size = "12.5,1.25 23.75,23.75 1.25,23.75";
        break;
      case (payload.count > 10 && payload.count <= 20):
        size = "15,1.5 28.5,28.5 1.5,28.5";
        break;
      case (payload.count > 20 && payload.count <= 30):
        size = "17.5,1.75 33.25,33.25 1.75,33.25";
        break;
      case (payload.count > 30 && payload.count <= 40):
        size = "20,2 38,38 2,38";
        break;
      case (payload.count > 40 && payload.count < 50):
        size = "22.5,2.25 42.75,42.75 2.25,42.75";
        break;
      case (payload.count > 50):
        size = "25,2.5 47.5,47.5 2.5,47.5";
        break;
      default:
        console.log("payload.count does not match any case");
        break;
    }
    if (payload.action === 'Acceptance') {
        return (
		 <svg width="100" height="100" x={cx-10} y={cy-12}>
           <polygon points={size} fill="#FF9300" />
         </svg>
        );
      }
    if (payload.action === 'Accession') {
        return (
		  <svg width="100" height="100" x={cx-10} y={cy-12}>
           <polygon points={size} fill="#7FD1FF" />
         </svg>
        );
      }
      if (payload.action === 'Approval') {
        return (
		 <svg width="100" height="100" x={cx-10} y={cy-12}>
           <polygon points={size} fill="#0082CC" />
         </svg>
        );
      }
      if (payload.action === 'Consent to be bound') {
        return (
		 <svg width="100" height="100" x={cx-10} y={cy-12}>
           <polygon points={size} fill="#96EDBF" />
         </svg>
        );
      }
      if (payload.action === 'Definitive signature') {
        return (
		 <svg width="100" height="100" x={cx-10} y={cy-12}>
           <polygon points={size} fill="#F4E922" />
         </svg>
        );
      }
      if (payload.action === 'Ratification') {
        return (
	 <svg width="100" height="100" x={cx-10} y={cy-12}>
           <polygon points={size} fill="#0EAD29" />
         </svg>
      );
      }
      if (payload.action === 'Signature subject to ratification') {
        return (
	 <svg width="100" height="100" x={cx-10} y={cy-12}>
           <polygon points={size} fill="#AC83E5" />
         </svg>
      );
      }
      if (payload.action === 'Succession') {
        return (
		 <svg width="100" height="100" x={cx-10} y={cy-12}>
           <polygon points={size} fill="#02B2A1" />
         </svg>
        );
      }
      if (payload.action === 'Withdrawal') {
        return (
		 <svg width="100" height="100" x={cx-10} y={cy-12}>
           <polygon points={size} fill="#CC000A" />
         </svg>
        );
      }
      if (payload.action === 'Participants') {
        return (
		 <svg width="100" height="100" x={cx-10} y={cy-12}>
           <polygon points={size} fill="#F794E0" />
         </svg>
        );
      }
  };



  const CustomizedDotPolygon = (props) => {
    const { cx, cy, payload } = props;
    let size = 0;
    switch (true) {
      case (payload.count === 1):
        size = "7.5,0.75 14.25,6 11.25,12.75 3.75,12.75 0.75,6";
        break;
      case (payload.count === 2):
        size = "10,1 19,8 15,17 5,17 1,8";
        break;
      case (payload.count > 2 && payload.count <= 10):
        size = "12.5,1.25 23.75,10 18.75,21.25 6.25,21.25 1.25,10";
        break;
      case (payload.count > 10 && payload.count <= 20):
        size = "15,1.5 28.5,12 22.5,25.5 7.5,25.5 1.5,12";
        break;
      case (payload.count > 20 && payload.count <= 30):
        size = "17.5,1.75 32.75,14 26.25,29.75 8.75,29.75 1.75,14";
        break;
      case (payload.count > 30 && payload.count <= 40):
        size = "20,2 38,16 30,34 10,34 2,16";
        break;
      case (payload.count > 40 && payload.count < 50):
        size = "22.5,2.25 42.75,18 33.75,38.25 11.25,38.25 2.25,18";
        break;
      case (payload.count > 50):
        size = "25,2.5 47.5,20 37.5,42.5 12.5,42.5 2.5,20";
        break;
      default:
        console.log("payload.count does not match any case");
        break;
    }
    if (payload.action === 'Acceptance') {
        return (
		    <svg width="100" height="100" x={cx-10} y={cy-10}>
      <polygon points={size} fill="#FF9300" />
    </svg>
        );
      }
    if (payload.action === 'Accession') {
        return (
		     <svg width="100" height="100" x={cx-10} y={cy-10}>
      <polygon points={size} fill="#7FD1FF" />
    </svg>
        );
      }
      if (payload.action === 'Approval') {
        return (
		    <svg width="100" height="100" x={cx-10} y={cy-10}>
      <polygon points={size} fill="#0082CC" />
    </svg>
        );
      }
      if (payload.action === 'Consent to be bound') {
        return (
		    <svg width="100" height="100" x={cx-10} y={cy-10}>
      <polygon points={size} fill="#96EDBF" />
    </svg>
        );
      }
      if (payload.action === 'Definitive signature') {
        return (
		    <svg width="100" height="100" x={cx-10} y={cy-10}>
      <polygon points={size} fill="#F4E922" />
    </svg>
        );
      }
      if (payload.action === 'Ratification') {
        return (
	    <svg width="100" height="100" x={cx-10} y={cy-10}>
      <polygon points={size} fill="#0EAD29" />
    </svg>
      );
      }
      if (payload.action === 'Signature subject to ratification') {
        return (
	    <svg width="100" height="100" x={cx-10} y={cy-10}>
      <polygon points={size} fill="#AC83E5" />
    </svg>
      );
      }
      if (payload.action === 'Succession') {
        return (
		    <svg width="100" height="100" x={cx-10} y={cy-10}>
      <polygon points={size} fill="#02B2A1" />
    </svg>
        );
      }
      if (payload.action === 'Withdrawal') {
        return (
		    <svg width="100" height="100" x={cx-10} y={cy-10}>
      <polygon points={size} fill="#CC000A" />
    </svg>
        );
      }
      if (payload.action === 'Participants') {
        return (
		    <svg width="100" height="100" x={cx-10} y={cy-10}>
      <polygon points={size} fill="#F794E0" />
    </svg>
        );
      }
  };

  const CustomTooltip = ({ active, payload, label } ) => {
    if (active && payload && payload.length) {
        let date = new Date(label);
        if (props.displayCountryName) {
          return (
            <div className="custom-tooltip" >
              <p style={{fontSize: "x-small"}} className="label">{`Name : ${payload[0].payload.name }`} </p>
              <p style={{fontSize: "x-small"}}>{`Year : ${date.toLocaleDateString('sv-SE')}`}</p>
              <p style={{fontSize: "x-small"}}>{`Countries : ${payload[0].payload.countries}`}</p>
              <p style={{fontSize: "x-small"}}>{`Action : ${payload[0].payload.action}`}</p>
            </div>
          );
        } else {
          return (
            <div className="custom-tooltip" >
              <p style={{fontSize: "x-small"}} className="label">{`Name : ${payload[0].payload.name }`} </p>
              <p style={{fontSize: "x-small"}}>{`Year : ${date.toLocaleDateString('sv-SE')}`}</p>
              <p style={{fontSize: "x-small"}}>{`Countries_abbrev : ${payload[0].payload.countries_abbrev}`}</p>
              <p style={{fontSize: "x-small"}}>{`Action : ${payload[0].payload.action}`}</p>
            </div>
          );
        }
    }
    return null;
  };

  function splitArrayByAgreement(array, propertyName) {
    return array.reduce((acc, obj) => {
      const propertyValue = obj[propertyName];
      if (!acc[propertyValue]) {
        acc[propertyValue] = [];
      }
      acc[propertyValue].push(obj);
      return acc;
    }, {});
  }

  const getAgreementIds = graphData.map(item => ({
    id: item.agreement_id
  }));

  const getCountries = graphDataCountry.map(item => ({
    country: item.countries
  }));

const distinctAgreementIds = [...new Set(getAgreementIds.map(obj => obj.id))];

const distinctCountries = [...new Set(getCountries.map(obj => obj.country))];

const splitObjectsCountry = splitArrayByAgreement(graphDataCountry, 'countries');
const splitObjectsAgreement = splitArrayByAgreement(graphData, 'agreement_id');

const countryLine1 = splitObjectsCountry[distinctCountries[0]];
const countryLine2 = splitObjectsCountry[distinctCountries[1]];
const countryLine3 = splitObjectsCountry[distinctCountries[2]];
const countryLine4 = splitObjectsCountry[distinctCountries[3]];
const countryLine5 = splitObjectsCountry[distinctCountries[4]];
const agreementLine1 = splitObjectsAgreement[distinctAgreementIds[0]];
const agreementLine2 = splitObjectsAgreement[distinctAgreementIds[1]];
const agreementLine3 = splitObjectsAgreement[distinctAgreementIds[2]];
const agreementLine4 = splitObjectsAgreement[distinctAgreementIds[3]];
const agreementLine5 = splitObjectsAgreement[distinctAgreementIds[4]];

if (props.filterCountries.length > 0) {
  if (countryLine1 !== undefined) {
    countryLine1.forEach(obj => {
      obj.value = 1;
    });
  }
  if (countryLine2 !== undefined) {
    countryLine2.forEach(obj => {
    obj.value = 2;
    });
  }
  if (countryLine3 !== undefined) {
    countryLine3.forEach(obj => {
    obj.value = 3;
    });
  }
  if (countryLine4 !== undefined) {
    countryLine4.forEach(obj => {
    obj.value = 4;
    });
  }
  if (countryLine5 !== undefined) {
    countryLine5.forEach(obj => {
    obj.value = 5;
    });
  }
}

  // Filter agreement parties from database
  const fetchAgreementParties = async () => {
    try {
      let items = [];
      if (props.filterAgreements.length > 0 || props.filterCountries.length > 0
        || props.filterActions.length > 0 || props.filterWeapons.length > 0) {
        setLoader(true);
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ ids: props.filterAgreements, countries: props.filterCountries,
            actions: props.filterActions, weapons: props.filterWeapons })
        };
        const response = await fetch('/api/getAgreementParties', requestOptions);
        const res = await response.json();
        items = res?.data;
        setLoader(false);
      }
      setResults(items);
    } catch (error) {
      setLoader(false);
      console.error('Error fetching data:', error);
    }
  };

   // Get entry_into_force_dates for specifik agreement parties from database
   const fetchEntryIntoForceDates = async () => {
    try {
      let items = [];
      const requestOptions = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
      };
      const response = await fetch('/api/getEntryIntoForceDates', requestOptions);
      const res = await response.json();
      items = JSON.parse(res?.data).result;
      //tems = Array.from(res?.data);
      setEntryIntoForceDates(items);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  if (props.filterCountries.length > 0) {

  return (
    <section className='component mb-5' id='component-form-table'>
      <div className='col-2'>
        {loader && (<div className='loader amcdpLoader' />)}
      </div>
      <ResponsiveContainer width="100%" height={500}>
      <LineChart margin={{ top: 20, right: 30, left: 20, bottom: 10 }}>
        <XAxis
        dataKey={getXValueData}
        domain={[minX, maxX]}
        scale='time'
        type='number'
        ticks={xticks}
        tickFormatter={timeFormatter}
        />
        <Tooltip content={<CustomTooltip />} />
        <Line type="monotone" data={countryLine1} dataKey="value" stroke="black" strokeDasharray="3 3"
            dot={<CustomizedDotCircle />}
            activeDot={<CustomizedDotCircle />}/>
        <Line type="monotone" data={countryLine2} dataKey="value" stroke="black" strokeDasharray="4 1 2 3"
            dot={<CustomizedDotRect />}
            activeDot={<CustomizedDotRect />}/>
        <Line type="monotone" data={countryLine3} dataKey="value" stroke="black" strokeDasharray="5 10 5"
            dot={<CustomizedDotSign />}
            activeDot={<CustomizedDotSign />}/>
        <Line type="monotone" data={countryLine4} dataKey="value" stroke="black" strokeDasharray="5 5"
            dot={<CustomizedDotTri />}
            activeDot={<CustomizedDotTri />}/>
        <Line type="monotone" data={countryLine5} dataKey="value" stroke="black" strokeDasharray="4 4 4"
            dot={<CustomizedDotPolygon />}
            activeDot={<CustomizedDotPolygon />}/>
        {renderEnteredLinesCountries()}
        {renderSignatureLines()}
        <Brush dataKey={getXValueData} height={30} stroke="green"  tickFormatter={timeFormatter} />
      </LineChart>
      </ResponsiveContainer>
      <Legend />
    </section>
  );
} else {
  return (
    <section className='component mb-5' id='component-form-table'>
      <div className='col-2'>
        {loader && (<div className='loader amcdpLoader' />)}
      </div>
      <ResponsiveContainer width="100%" height={500}>
        <LineChart margin={{ top: 20, right: 30, left: 20, bottom: 10 }}>
          <XAxis
          dataKey={getXValueData}
          domain={[minX, maxX]}
          scale='time'
          type='number'
          ticks={xticks}
          tickFormatter={timeFormatter}
          />
          <YAxis domain={[minY,maxY]}>
            <Label value="Number of agreement actions" position="insideMiddleLeft" angle={-90} /></YAxis>
          <Tooltip content={<CustomTooltip />} />
          <Line type="monotone" data={agreementLine1} dataKey="value" stroke="black" strokeDasharray="3 3"
              dot={<CustomizedDotCircle />}
              activeDot={<CustomizedDotCircle />}/>
          <Line type="monotone" data={agreementLine2} dataKey="value" stroke="black" strokeDasharray="4 1 2 3"
              dot={<CustomizedDotRect />}
              activeDot={<CustomizedDotRect />}/>
          <Line type="monotone" data={agreementLine3} dataKey="value" stroke="black" strokeDasharray="5 10 5"
              dot={<CustomizedDotSign />}
              activeDot={<CustomizedDotSign />}/>
          <Line type="monotone" data={agreementLine4} dataKey="value" stroke="black" strokeDasharray="5 5"
              dot={<CustomizedDotTri />}
              activeDot={<CustomizedDotTri />}/>
          <Line type="monotone" data={agreementLine5} dataKey="value" stroke="black" strokeDasharray="4 4 4"
              dot={<CustomizedDotPolygon />}
              activeDot={<CustomizedDotPolygon />}/>
          {renderEnteredLines()}
          {renderSignatureLines()}
          {renderWithdrawalLines()}
          <Brush dataKey={getXValueData} height={30} stroke="green"  tickFormatter={timeFormatter} />
        </LineChart>
      </ResponsiveContainer>
      <Legend />
    </section>
  );
}
}