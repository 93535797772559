import { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import PageButtons from '../pageButtons';

export default function SearchResult(props) {
  const currentLang = localStorage.getItem('i18nextLng') || 'sv';

  // Result
  const [results, setResults] = useState([]);

  // Show or hide spinning loader
  const [loader, setLoader] = useState(false);

  // Current page
  const [currentPage, setCurrentPage] = useState(1);
  // Number of rows per page
  const [totalPages, setTotalPages] = useState(1);
  // Maximum of 5 pagination buttons
  const [pageItems, setPageItems] = useState([]);

  // Update demonstrated data whenever page, sort order or any of the filters are changed
  useEffect(() => {
    fetchSearch(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, props.text, props.filter]);

  function getPageItems(selectedPage, totPages) {
    const tot = totPages < 5 ? totPages : 5;
    const items = [];
    let startIndex = 1;
    if (selectedPage <= 3 || totPages <= 5) {
      startIndex = 1;
    } else if (totPages - selectedPage <= 2) {
      startIndex = selectedPage - 4 + (totPages - selectedPage);
    } else {
      startIndex = selectedPage - 2;
    }
    for(let i = startIndex; i < startIndex+tot; i +=1) {
      var pagingClass = currentPage === i ? 'page-link active' : 'page-link';
      items.push(
        <li className='page-item page-item-nav' id={`page-list-${i}`} key={`page-list-${i}`} >
          <Link id={`page-${i}`} key={`page-${i}`} className={pagingClass} onClick={() => {
            setCurrentPage(i);
            getPageItems(i, totPages);
          }}>
            {i}
          </Link>
        </li>
      );
    }
    return items;
  }
  // Filter agreement parties from database
  const fetchSearch = async (page, requestDemonstratedSort) => {
    try {
      let items = [];
      let hits = 0;
      setLoader(true);
      const pSize = 25;
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ text: props.text, filter: props.filter, page: page, pageSize: pSize})
      };
      const response = await fetch('/api/search', requestOptions);
      const res = await response.json();
      if (res?.isOk) {
        setTotalPages(res.totalPages);
        setPageItems(getPageItems(page, res.totalPages));

        items = res.data;
        hits = res.hits;
        props.handleHitsChange(hits);
      }
      setLoader(false);
      setResults({"items": items, "hits": hits});
    } catch (error) {
      setLoader(false);
      console.error('Error fetching data:', error);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage- 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };

  return (
    <section className='component mb-5' id='component-form-table'>
      <div className='anchor' id='table'></div>
      <div className='row'>
        <div className='col-2'>
          <div className='loader amcdpLoader'
            style={{visibility: loader ? 'visible' : 'hidden' }} />
        </div>
      </div>

      <div className='card'>
        <ul className='list-group list-group-flush'>
          {results?.items?.map((item, i) => (
            <li className='list-group-item' id={`res-${item.agreement_id}-${i}`}
              key={`res-${item.agreement_id}-${i}`}>
              <span className='list-grou-item-actions-title h2 search-result-hit-title'>
                <Link to={`../${currentLang}/profilePage?id=${item.agreement_id}`} className=''>
                  {item.name}
                </Link>
              </span>
              <p className='mb-2 search-description'>{item.description}</p>
              <p className='small text-muted'>{item.year}</p>
            </li>
          ))}
        </ul>
        </div>

      <PageButtons handleFirstPage={handleFirstPage} handlePrevPage={handlePrevPage}
        handleNextPage={handleNextPage} handleLastPage={handleLastPage} currentPage={currentPage}
        totalPages={totalPages} pageItems={pageItems} />
    </section>

  );
}