import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';

function MenuButton(props) {
  const { t } = useTranslation();
  // Used to make sure there is only one click eventlistener for menu container
  const [isEventListening, setIsEventListening] = useState(true);

  useEffect(() => {
    handleOnBlur();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function checkLogInStatus(props) {
    const tokenString = sessionStorage.getItem('token');
    if (tokenString && tokenString !== '') {
      props.onSetIsLoggedOut(false);
    } else {
      props.onSetIsLoggedOut(true);
    }
  }

    function handleOnBlur() {
    const menuButton = document.getElementById('menu-button');
    const menuContainer = document.getElementById('menu-container');

    if(menuButton !== null && menuButton !== undefined && menuContainer !== null
      && menuContainer !== undefined && isEventListening) {
      document.addEventListener('click', function(event) {
        const outsideClick = !menuContainer.contains(event.target);
        // If clicked outside the menu container
        if (outsideClick && menuContainer.classList.contains('show')) {
          menuButton.click();
        }
      });
      setIsEventListening(false);
    }
  }


  return (
    <button id='menu-button'
      className='button-text button-icon function-menu-toggle-button icon-menu'
      data-bs-target='#menu-container'
      data-bs-toggle='collapse'
      onClick={() => checkLogInStatus(props)}
      onBlur={handleOnBlur}>
      <span className='function-menu-username visually-hidden-md'>{t('header.menu')}</span>
    </button>
  )
}

export default MenuButton;