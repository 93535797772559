import { React, useState } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { useTranslation } from 'react-i18next';
import Menu from './menu';
import MenuButton from './menuButton';
import uuLogo from '../designsystem/6.1/theme-uu/img/uu-logo-red.svg';


function Header () {
    const { i18n, t } = useTranslation();
    const [inSwedish, setInSwedish] = useState(false);
    const [isLoggedOut, setIsLoggedOut] = useState(false);

    let language2 = inSwedish ? 'sv' : 'en';
    let language = inSwedish ? 'en' : 'sv';

    const changeLanguage = nextLanguage => {
      i18n.changeLanguage(nextLanguage);
      localStorage.setItem('i18nextLng', nextLanguage);
    };

return (
<header className='layout-header'>
    <div className='container'>
        <div className='layout-header-local row'>
            <div className='layout-header-uu-logo'>
                <Link to='https://uu.se' style={{display: 'contents'}}>
                    <img alt='Uppsala universitet' src={uuLogo} />
                </Link>
            </div>
            <div className='layout-header-logo'>
                <Link to={`./${language}`}
                    className='layout-logo logo-link layout-header-name-text'>
                    AMC DATA
                </Link>
            </div>
            <div className='layout-header-name'>
                <Link to='https://www.uu.se/centrum/alva-myrdal-centrum'
                    className='layout-header-name-text text-uppercase header-name-amc homeHeader'>
                    {t('header.first.title')}
                    <br/>
                    {t('header.second.title')}
                </Link>
            </div>
            <div className='layout-header-buttons header-buttons'>
                <button className='button-text button-icon icon-language' onClick={() => {
                        setInSwedish(!inSwedish);
                        changeLanguage(language2);

                        const orig = window.location.origin;
                        const href = window.location.href;
                        const hrefEnd = href.replace(orig, '').substring(3);
                        let newHref = `${orig}/${language2}`;
                        if (hrefEnd) {
                            newHref = `${newHref}${hrefEnd}`;
                        }
                        window.history.replaceState(null, 'New Page Title', `${newHref}`);
                    }
                }>
                    <span className='visually-hidden-md'>{t('header.language')}</span>
                </button>
                <div className='site-menu'>
                    <MenuButton onSetIsLoggedOut={setIsLoggedOut} />
                    <nav id='menu-container'
                        className='function-menu-container collapse site-menu-container'
                        aria-label='personlig meny'>
                        <Menu isLoggedOut={isLoggedOut} />
                    </nav>
                </div>

            </div>
      </div>
  </div>

</header>
);
}

export default Header;

