import { React } from 'react';
import {
  Navigate,
  Routes,
  Route,
} from 'react-router-dom';
import Header from './pages/header';
import Footer from './pages/footer';
import Home from './pages/home';
import DataPortal from './pages/dataPortal';
import DataVisualizer from './pages/dataVisualizer';
import NotFound from './pages/notFound';
import NoAccess from './pages/noAccess';
import LoadDatabase from './pages/loadDataToDb/loadDatabase';
import AgreementDatabase from './pages/agreementDatabase';
import DigitalLibrary from './pages/search/digitalLibrary';
import ProfilePage from './pages/profile/profilePage';
import Definitions from './pages/definitions';
import About from './pages/about';
import Login from './pages/login/login';
import ResetPwd from './pages/login/resetPwd';
import ProtectedRoute from './pages/login/protectedRoute';
import './designsystem/6.1/theme-uu/css/uu-system-style.css';
import './App.css';

function App() {
  const currentLang = localStorage.getItem('i18nextLng') || 'sv';

  return (
    <div>
      <Header />
      <Routes>
        <Route path='/' element={<Navigate to={currentLang} />} />
        <Route path='/sv/' element={<Home />} />
        <Route path='/en/' element={<Home />} />
        <Route path='/:language/login' element={<Login />} />
        <Route path='/:language/resetPwd' element={
          <ProtectedRoute>
            <ResetPwd />
          </ProtectedRoute>} />
        <Route path='/:language/dataPortal' element={<DataPortal />} />
        <Route path='/:language/dataVisualizer' element={<DataVisualizer />} />
        <Route path='/:language/loadDatabase' element={
          <ProtectedRoute>
            <LoadDatabase />
          </ProtectedRoute> } />
        <Route path='/:language/agreementDatabase' element={<AgreementDatabase />} />
        <Route path='/:language/digitalLibrary' element={<DigitalLibrary />} />
        <Route path='/:language/profilePage' element={<ProfilePage />} />
        <Route path='/:language/profilePage:id' element={<ProfilePage />} />
        <Route path='/:language/definitions' element={<Definitions />} />
        <Route path='/:language/about' element={<About />} />
        <Route path='/:language/noaccess' element={<NoAccess />} />
        <Route path='/:language/notFound' element={<NotFound />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;

// here app catches the suspense from page in case translations are not yet loaded
/* export default function WrappedApp() {
  return (
    <Suspense fallback='...is loading'>
      <App />
    </Suspense>
  );
} */