import { useTranslation } from 'react-i18next';
import DemonstratedTable from './demonstratedTable';
import VerifiedTable from './verifiedTable';
import ConsultationTable from './consultationTable';

export default function Table(props) {
  const { t } = useTranslation();

  return (
    <section className='component mb-5 control-system' id='component-form-table'>
      <div className='anchor' id='table'></div>
      <nav aria-label='Tabulär navigering'>
        <ul className='nav nav-tabs' id='myTab1' role='tablist'>
          <li className='nav-item flex-fill text-center' role='presentation'>
            <button className='nav-link active' id='deomonstrated1-tab' data-bs-toggle='tab'
              data-bs-target='#deomonstrated1' type='button' role='tab'
              aria-controls='deomonstrated' aria-selected='true'>
               {`${t('agreementControlSystem.table.demonstratedcompliance')}`}
            </button>
          </li>
          <li className='nav-item flex-fill text-center' role='presentation'>
            <button className='nav-link' id='verified1-tab' data-bs-toggle='tab'
              data-bs-target='#verified1' type='button' role='tab' aria-controls='verified'
              aria-selected='false'>
              {`${t('agreementControlSystem.table.verifiedcompliance')}`}
            </button>
          </li>
          <li className='nav-item flex-fill text-center' role='presentation'>
            <button className='nav-link' id='consultation1-tab' data-bs-toggle='tab'
              data-bs-target='#consultation1' type='button' role='tab' aria-controls='consultation'
              aria-selected='false'>
              {`${t('agreementControlSystem.table.consultation.title')}`}
            </button>
          </li>
        </ul>
      </nav>
      <div className='card tab-card tab-content' id='myTabContent1'>
        <div className='card-body tab-pane fade show active' id='deomonstrated1' role='tabpanel'
          aria-labelledby='deomonstrated1-tab'>
          <DemonstratedTable agreementFilters={props.agreementFilters} />
        </div>
        <div className='card-body tab-pane fade' id='verified1' role='tabpanel'
          aria-labelledby='verified1-tab'>
          <VerifiedTable agreementFilters={props.agreementFilters} />
        </div>
        <div className='card-body tab-pane fade' id='consultation1' role='tabpanel'
          aria-labelledby='consultation1-tab'>
          <ConsultationTable agreementFilters={props.agreementFilters} />
        </div>
      </div>
    </section>

  );
}