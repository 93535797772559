import { useTranslation } from 'react-i18next';
import { useState, useEffect, useLayoutEffect } from 'react';
import { getHTMLFromSitevisionObj } from './common';
import parseToHtml from 'html-react-parser';

function DataPortal() {
  const { t, i18n } = useTranslation();
  const currentLang = localStorage.getItem('i18nextLng') || 'sv';

  // Separate loaders
  const [loaderContent, setLoaderContent] = useState(false);
  const [loaderAgreement, setLoaderAgreement] = useState(false);
  const [loaderAgreementParties, setLoaderAgreementParties] = useState(false);
  const [loaderWeapons, setLoaderWeapons] = useState(false);
  const [loaderConsultation, setLoaderConsultation] = useState(false);
  const [loaderDemonstrated, setLoaderDemonstrated] = useState(false);
  const [loaderVerified, setLoaderVerified] = useState(false);
  const [loaderAssociation, setLoaderAssociation] = useState(false);

  // Text fetched from Sitevision
  const [infoLang, setInfoLang] = useState({
    "en": { "preamble": "", "agreementDataset": "", "weaponsDataset": "", "consultationDataset": "",
      "demonstratedDataset": "", "verifiedDataset": "", "associationDataset": "",
      "agreementPartiesDataset": "" },
    "sv": { "preamble": "", "agreementDataset": "", "weaponsDataset": "", "consultationDataset": "",
      "demonstratedDataset": "", "verifiedDataset": "", "associationDataset": "",
      "agreementPartiesDataset": "" }});

  // Selected language
  const [selectedLang, setSelectedLang] = useState('en');

  // Links to files
  const [agreementFiles, setAgreementFiles] = useState([]);
  const [weaponFiles, setWeaponFiles] = useState([]);
  const [consultationFiles, setConsultationFiles] = useState([]);
  const [demonstratedFiles, setDemonstratedFiles] = useState([]);
  const [verifiedFiles, setVerifiedFiles] = useState([]);
  const [associationFiles, setAssociationFiles] = useState([]);
  const [agreementPartiesFiles, setAgreementPartiesFiles] = useState([]);

  useLayoutEffect(() => {
    // Start at the top
    window.scrollTo(0, 0)
  });

  useEffect(() => {
    fetchGetDataPortalContent();
    fetchFiles('0', setLoaderAgreement, setAgreementFiles);
    fetchFiles('1', setLoaderWeapons, setWeaponFiles);
    fetchFiles('2', setLoaderConsultation, setConsultationFiles);
    fetchFiles('3', setLoaderDemonstrated, setDemonstratedFiles);
    fetchFiles('4', setLoaderVerified, setVerifiedFiles);
    fetchFiles('5', setLoaderAssociation, setAssociationFiles);
    fetchFiles('6', setLoaderAgreementParties, setAgreementPartiesFiles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Catch when language is changed
  i18n.on('languageChanged', (lang) => {
    setSelectedLang(lang);
  });

  // Get content of dataprofile from sitevision
  const fetchGetDataPortalContent= async () => {
    try {
      setLoaderContent(true);
      const requestOptions = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
      };
      const response = await fetch('/api/sv/getDataPortalSVContent', requestOptions);
      const res = await response.json();

      const preambleEn = parseToHtml(getHTMLFromSitevisionObj(
        res?.data?.find(({ name }) => name === 'Preamble-en').properties));
      const preambleSv = parseToHtml(getHTMLFromSitevisionObj(
        res?.data?.find(({ name }) => name === 'Preamble-sv').properties));
      const agreementDatasetEn = parseToHtml(getHTMLFromSitevisionObj(
        res?.data?.find(({ name }) => name === 'AgreementDataset-en').properties));
      const agreementDatasetSv = parseToHtml(getHTMLFromSitevisionObj(
        res?.data?.find(({ name }) => name === 'AgreementDataset-sv').properties));
      const weaponsDatasetEn = parseToHtml(getHTMLFromSitevisionObj(
        res?.data?.find(({ name }) => name === 'WeaponsDataset-en').properties));
      const weaponsDatasetSv = parseToHtml(getHTMLFromSitevisionObj(
        res?.data?.find(({ name }) => name === 'WeaponsDataset-sv').properties));
      const consultationDatasetEn = parseToHtml(getHTMLFromSitevisionObj(
        res?.data?.find(({ name }) => name === 'ConsultationDataset-en').properties));
      const consultationDatasetSv = parseToHtml(getHTMLFromSitevisionObj(
        res?.data?.find(({ name }) => name === 'ConsultationDataset-sv').properties));
      const demonstratedDatasetEn = parseToHtml(getHTMLFromSitevisionObj(
        res?.data?.find(({ name }) => name === 'DemonstratedDataset-en').properties));
      const demonstratedDatasetSv = parseToHtml(getHTMLFromSitevisionObj(
        res?.data?.find(({ name }) => name === 'DemonstratedDataset-sv').properties));
      const verifiedDatasetEn = parseToHtml(getHTMLFromSitevisionObj(
        res?.data?.find(({ name }) => name === 'VerifiedDataset-en').properties));
      const verifiedDatasetSv = parseToHtml(getHTMLFromSitevisionObj(
        res?.data?.find(({ name }) => name === 'VerifiedDataset-sv').properties));
      const associationDatasetEn = parseToHtml(getHTMLFromSitevisionObj(
        res?.data?.find(({ name }) => name === 'AssociationDataset-en').properties));
      const associationDatasetSv = parseToHtml(getHTMLFromSitevisionObj(
        res?.data?.find(({ name }) => name === 'AssociationDataset-sv').properties));
      const agreementPartiesDatasetEn = parseToHtml(getHTMLFromSitevisionObj(
        res?.data?.find(({ name }) => name === 'AgreementPartiesDataset-en').properties));
      const agreementPartiesDatasetSv = parseToHtml(getHTMLFromSitevisionObj(
        res?.data?.find(({ name }) => name === 'AgreementPartiesDataset-sv').properties));

      const info = {
        "en": {
        "preamble": preambleEn,
        "agreementDataset": agreementDatasetEn,
        "weaponsDataset": weaponsDatasetEn,
        "consultationDataset": consultationDatasetEn,
        "demonstratedDataset": demonstratedDatasetEn,
        "verifiedDataset": verifiedDatasetEn,
        "associationDataset": associationDatasetEn,
        "agreementPartiesDataset": agreementPartiesDatasetEn },
        "sv": {
        "preamble": preambleSv,
        "agreementDataset": agreementDatasetSv,
        "weaponsDataset": weaponsDatasetSv,
        "consultationDataset": consultationDatasetSv,
        "demonstratedDataset": demonstratedDatasetSv,
        "verifiedDataset": verifiedDatasetSv,
        "associationDataset": associationDatasetSv,
        "agreementPartiesDataset": agreementPartiesDatasetSv }
      };
      setInfoLang(info);
      setSelectedLang(currentLang);

      setLoaderContent(false);

    } catch (error) {
      console.error('Error fetching data:', error);
      setLoaderContent(false);
    }
  };

  function getFileItem(file, fileType, key) {
    const items = [];
    if (file && file.url !== '') {
      items.push(
        <li id={key} key={key}>
          <a href={file.url} className='icon-position-left icon-download'>
            {fileType}
          </a>
        </li>);
    }
    return items;
  }

  async function fetchFiles(id, setLoader, setFiles) {
    try {
      setLoader(true);
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      };
      const response = await fetch(`/api/sv/getDataPortalSVFiles?id=${id}`, requestOptions);
      const res = await response.json();

      const fileItems = [];
      fileItems.push(
        <ul className='list-unstyled card-body' id={`files-${id}`} key={`files-${id}`}>
          {getFileItem(res?.files?.csv[0], 'CSV', `csv-file-${id}`)}
          {getFileItem(res?.files?.excel[0], 'Excel', `excel-file-${id}`)}
          {getFileItem(res?.files?.r[0], 'R', `r-file-${id}`)}
          {getFileItem(res?.files?.stata[0], 'STATA', `stata-file-${id}`)}
          {getFileItem(res?.files?.codebook[0], 'CODEBOOK', `codebook-file-${id}`)}
          {getFileItem(res?.files?.listOfVar[0], 'LIST OF VARIABLES', `var-file-${id}`)}
      </ul>
      );
      setLoader(false);
      setFiles(fileItems);

    } catch (error) {
      console.error('Error fetching data:', error);
      setLoader(false);
    }
  }

  return (
    <div className='container mt-5'>
      <h1>{t('dataPortal.title')}</h1>
      <div className='col-2'>
        {loaderContent && (<div className='loader amcdpLoader' />)}
      </div>
      {selectedLang === 'en' ? infoLang.en.preamble : infoLang.sv.preamble}
      <div className='container-fluid jumbotron jumbotron-fluid p-4 mt-5'>
        <div className='row'>
					<div className='col-lg-8'>
						<h2>{t('dataPortal.agreement.dataset.title')}</h2>
            {selectedLang === 'en' ? infoLang.en.agreementDataset : infoLang.sv.agreementDataset}
					</div>
					<div className='col-lg-4 mb-0'>
						<div className='card'>
							<h3 className='card-header'>{t('dataPortal.availableas')}</h3>
              <div className='col-2'>
                {loaderAgreement && (<div className='loader amcdpLoader' />)}
              </div>
              {agreementFiles}
						</div>
					</div>
				</div>
			</div>
			<div className='row p-5 pb-4'>
				<div className='col-lg-8'>
					<h3>{t('dataPortal.weapondataset.title')}</h3>
          {selectedLang === 'en' ? infoLang.en.weaponsDataset : infoLang.sv.weaponsDataset}
				</div>
				<div className='col-lg-4 mb-0'>
					<div className='card'>
						<h4 className='card-header'>{t('dataPortal.availableas')}</h4>
            <div className='col-2'>
              {loaderWeapons && (<div className='loader amcdpLoader' />)}
            </div>
						{weaponFiles}
					</div>
				</div>
			</div>
			<div className='row p-5 pb-4'>
				<div className='col-lg-8'>
					<h3>{t('dataPortal.consultation.title')}</h3>
          {selectedLang === 'en' ? infoLang.en.consultationDataset : infoLang.sv.consultationDataset}
				</div>
				<div className='col-lg-4 mb-0'>
					<div className='card'>
						<h4 className='card-header'>{t('dataPortal.availableas')}</h4>
            <div className='col-2'>
              {loaderConsultation && (<div className='loader amcdpLoader' />)}
            </div>
						{consultationFiles}
					</div>
				</div>
			</div>
			<div className='row p-5 pb-4'>
				<div className='col-lg-8'>
					<h3>{t('dataPortal.demonstration.title')}</h3>
          {selectedLang === 'en' ? infoLang.en.demonstratedDataset : infoLang.sv.demonstratedDataset}
				</div>
				<div className='col-lg-4 mb-0'>
					<div className='card'>
						<h4 className='card-header'>{t('dataPortal.availableas')}</h4>
            <div className='col-2'>
              {loaderDemonstrated && (<div className='loader amcdpLoader' />)}
            </div>
						{demonstratedFiles}
					</div>
				</div>
			</div>
			<div className='row p-5 pb-4'>
				<div className='col-lg-8'>
					<h3>{t('dataPortal.verified.title')}</h3>
          {selectedLang === 'en' ? infoLang.en.verifiedDataset : infoLang.sv.verifiedDataset}
				</div>
				<div className='col-lg-4 mb-0'>
					<div className='card'>
						<h4 className='card-header'>{t('dataPortal.availableas')}</h4>
            <div className='col-2'>
              {loaderVerified && (<div className='loader amcdpLoader' />)}
            </div>
						{verifiedFiles}
					</div>
				</div>
			</div>
			<div className='row p-5 pb-4'>
				<div className='col-lg-8'>
					<h3>{t('dataPortal.association.title')}</h3>
          {selectedLang === 'en' ? infoLang.en.associationDataset : infoLang.sv.associationDataset}
				</div>
				<div className='col-lg-4 mb-0'>
					<div className='card'>
						<h4 className='card-header'>{t('dataPortal.availableas')}</h4>
            <div className='col-2'>
              {loaderAssociation && (<div className='loader amcdpLoader' />)}
            </div>
						{associationFiles}
					</div>
				</div>
			</div>
      <div className='row p-5 pb-4'>
				<div className='col-lg-8'>
					<h3>{t('dataPortal.agreement.parties.dataset.title')}</h3>
          {selectedLang === 'en' ? infoLang.en.agreementPartiesDataset
            : infoLang.sv.agreementPartiesDataset}
				</div>
				<div className='col-lg-4 mb-0'>
					<div className='card'>
						<h4 className='card-header'>{t('dataPortal.availableas')}</h4>
            <div className='col-2'>
              {loaderAgreementParties && (<div className='loader amcdpLoader' />)}
            </div>
						{agreementPartiesFiles}
					</div>
				</div>
			</div>
		</div>
  );
}

export default DataPortal;