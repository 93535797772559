import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

async function loginUser(credentials) {
  const response = await fetch('/api/user/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  });
  const res = await response.json();
  return res;
}

export const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [status, setStatus] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [warningTitle, setWarningTitle] = useState('Warning');
  const [warning, setWarning] = useState('');

  const { t } = useTranslation();

  const handleLogin = async (e) => {
    e.preventDefault();
    const res = await loginUser({
      email: username,
      password
    });
    if (res.isOk) {
      sessionStorage.setItem('token', JSON.stringify(res.data.accessToken));
      setStatus(t('login.ok'));
      setIsLoggedIn(true);
      document.getElementById('loginwarning').style.display='none';
      // If login is successful jump to start page
      window.location.href = "./";
    } else {
      setWarningTitle(t('login.fail'));
      setWarning(t('login.fail.help'));
      document.getElementById('loginwarning').style.display='block';
      setIsLoggedIn(false);
    }
  };

  useEffect(() => {
    document.getElementById('loginwarning').style.display='none';
    const tokenString = sessionStorage.getItem('token');
    if (tokenString && tokenString !== '') {
      setStatus(t('login.already'));
      setIsLoggedIn(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='container mt-5'>
      <h1>{t('login.title')}</h1>
      <div className='alert alert-warning' role='alert' id='loginwarning'>
        <h2 className='icon-position-left icon-warning'>{warningTitle}</h2>
        <p>{warning}</p>
      </div>
      <form onSubmit={handleLogin}>
        <p>
          <label className='form-label' htmlFor='username'>{t('login.username')}</label>
          <input type='text' id='username' name='username' className='form-control'
            placeholder={t('login.username')} required='' autoFocus=''
            onChange={(e) => setUsername(e.target.value)} />
        </p>
        <p>
          <label className='form-label' htmlFor='password'>{t('login.password')}</label>
          <input type='password' id='password' name='password' className='form-control'
            placeholder={t('login.password')} required='' autoFocus=''
            onChange={(e) => setPassword(e.target.value)} />
        </p>
        <button type='submit' disabled={isLoggedIn}>{t('login.title')}</button>
      </form>
      <p>{status}</p>
    </div>
  );
};

export default Login;