import { useTranslation } from 'react-i18next';

export default function ProfileHeader(props) {
  const { t } = useTranslation();

   return (
<section className='component' id='component-module-jumbotron'>
  <div className='anchor' id='jumbotron'></div>
  <div className='container-fluid jumbotron jumbotron-fluid'>
    <div className='row'>
      <div className='col-lg-8'>
        <h1 className='treaty-header'>{props.name}</h1>
        <div className='row'>
          <div className='col-lg-8'>
            <div className='row align text-center is-extra-space'>
              <div className='col-12 col-md-4'>
                <p className='key-text'>{t('profile.year')}</p>
                <h2 className='key-value'>{props.year}</h2>
              </div>
              <div className='col-12 col-md-4'>
                <p className='key-text'>{t('profile.status')}</p>
                <h2 className='key-value'>{props.status}</h2>
              </div>
              <div className='col-12 col-md-4'>
                <p className='key-text'>{t('profile.parties')}</p>
                <h2 className='key-value'>{props.nrStatesPartiesTotal}</h2>
              </div>
            </div>
          </div>
          <div className='col-md-4 treaty-details'>
            <div className='profilHeader'>
              <div>{t('profile.topic')}:</div>
              <div><strong>{props.weaponsItemsTopic}</strong></div>
            </div>
            <div className='profilHeader'>
              <div>{t('profile.format')}:</div>
              <div><strong>{props.format}</strong></div>
            </div>
            <div className='profilHeader'>
              <div>{t('profile.regulationType')}:</div>
              <div><strong>{props.regulationType}</strong></div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-lg-4 mt-3'>
        <h2>{t('profile.moreAgreements')}</h2>
        <div className='card mb-0 treatylist' id='agreements' key='agreements'>
          <ul className='list-group list-group-flush overflow-scroll height-250'>
            {props.agreements}
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
  );
}