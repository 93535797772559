// Shorten long names
export function getName(name, length) {
  let newName = name;
  if (newName?.length > length) {
    newName = name.substr(0, length) + '...';
  }
  return newName;
}

// Get icon according to type of zone  or laterality
export function getIcon(nuclearWeaponsFreeZones, laterality) {
  let iconClass = '';
  if (nuclearWeaponsFreeZones === '0') {
    iconClass = 'africa';
  } else if (nuclearWeaponsFreeZones === '1' || nuclearWeaponsFreeZones === '2') {
    iconClass = 'asia';
  } else if (nuclearWeaponsFreeZones === '3') {
    iconClass = 'southamerica';
  } else if (nuclearWeaponsFreeZones === '4') {
    iconClass = 'antarctica';
  } else if (nuclearWeaponsFreeZones === '5') {
    iconClass = 'oceania';
  } else if (laterality === '1') {
    iconClass = 'twoparts';
  } else if (laterality === '2') {
    iconClass = 'moreparts';
  }
  return iconClass;
}

// Split agreements into 3 columns
export function getAgreementInColumns(sortedAgreements) {
  const test = [];
  // Populate maximum of 3 columns depending on number of agreements
  if (sortedAgreements?.length === 1) {
    test.push({ column1: [sortedAgreements[0]], column2: undefined, column3: undefined});
  } else if (sortedAgreements?.length === 2) {
    test.push(
      { column1: [sortedAgreements[0]], column2: [sortedAgreements[1]], column3: undefined});
  } else {
    const noColumns = 3;
    const flooredResult = Math.floor(sortedAgreements?.length / noColumns);
    const rest = sortedAgreements?.length % noColumns;
    const noItemsInCol1 = rest >= 1 ? flooredResult + 1 : flooredResult;
    const noItemsInCol2 = rest === 2 ? flooredResult + 1 : flooredResult;
    test.push({
      column1: sortedAgreements?.slice(0, noItemsInCol1),
      column2: sortedAgreements?.slice(noItemsInCol1, noItemsInCol1 + noItemsInCol2),
      column3: sortedAgreements?.slice(noItemsInCol1 + noItemsInCol2)});
  }
  return test;
}

// Get text from content objects
function getTexts(texts) {
  let html = '';
  texts.forEach((text) => {
    if (text.type === 'text') {
      html += `${text.content}`;
    } else if ((text.type === 'inline')) {
      html += `<${text.htmlName}>${getTexts(text.children)}</${text.htmlName}>`;
    }
  });

  return html;
}

// Get html from sitevision object
export function getHTMLFromSitevisionObj(properties) {
  let html = '';
  if (properties?.textContent) {
    properties.textContent?.forEach((text) => {
      html += `<${text.htmlName}>${getTexts(text.children)}</${text.htmlName}>`;
    });
  } else if (properties?.image) {
    html += `<img src=${properties.image?.URL} alt=${properties.image?.alt} />`;
  }
  return html;
}

// Get text from sitevision object
export function getTextFromSitevisionObj(properties) {
  let str = '';
  if (properties?.textContent) {
    const strs = [];
    properties.textContent?.forEach((text) => {
      strs.push(getTexts(text.children));
    });
    str = strs.join(' ');
  }
  return str;
}

export const triggerTypes = new Map();
triggerTypes.set('0', 'One time');
triggerTypes.set('1', 'Quota');
triggerTypes.set('2', 'Cyclical');
triggerTypes.set('3', 'Continuous');

export const initiationTypes = new Map();
initiationTypes.set('0', 'Directly upon entry into force');
initiationTypes.set('1', 'Set time after entry into force');
initiationTypes.set('2', 'Other');

export const durationTypes = new Map();
durationTypes.set('0', 'Set time');
durationTypes.set('1', 'Obligations timeline');
durationTypes.set('2', 'Agreement duration');
durationTypes.set('3', 'Other');

export function removeLeadingZeros(s) {
  while(s.charAt(0) === '0') {
    s = s.substring(1);
  }
  return s;
}

export const verifiedCategoryTypes = new Map();
verifiedCategoryTypes.set('0', 'NTMs');
verifiedCategoryTypes.set('1', 'Display');
verifiedCategoryTypes.set('2', 'Remote monitoring');
verifiedCategoryTypes.set('3', 'In-person exhibition');
verifiedCategoryTypes.set('4', 'In-person demonstration');
verifiedCategoryTypes.set('5', 'In-person Inspection');
verifiedCategoryTypes.set('6', 'In-person monitoring');
verifiedCategoryTypes.set('7', 'Other');

export function getTriggerInterval(triggerAgreement, quota, cyclical) {
  let interval = ''
  if (triggerAgreement === '1') {
    interval = ` - ${quota}`;
  } else if (triggerAgreement === '2') {
    interval = ` - ${cyclical}`;
  }
  return interval;
}
