import { useTranslation } from 'react-i18next';
import { useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import { getName } from '../common';
import PageButtons from '../pageButtons';

export default function ConsultationTable(props) {
  const { t } = useTranslation();
  const currentLang = localStorage.getItem('i18nextLng') || 'sv';

  // Sort order, ascending = true, descending = false
  const [sortNameAsc, setSortNameAsc] = useState(true);
  const [sortCategoryAsc, setSortCategoryAsc] = useState(true);
  const [sortMechanismAsc, setSortMechanismAsc] = useState(true);
  const [sortForumAsc, setSortForumAsc] = useState(true);

  // Result
  const [results, setResults] = useState([]);

  // Result consultation
  const [consultationFilters, setConsultationFilters] = useState([]);

  // Show or hide spinning loader
  const [loader, setLoader] = useState(false);

  // Current page
  const [currentPage, setCurrentPage] = useState(1);
  // Number of rows per page
  const [totalPages, setTotalPages] = useState(1);
  // Maximum of 5 pagination buttons
  const [pageItems, setPageItems] = useState([]);

  const [currentRequestSort, setCurrentRequestSort] =
  useState( {"name": 'name', "sortAcending": true });

  const consultationForumTypes = new Map();
  consultationForumTypes.set('1', 'Party-to-Party');
  consultationForumTypes.set('2', 'Diplomatic Channels');

  const associationForumGeneralTypes = new Map();
  associationForumGeneralTypes.set('0', 'Upon necessity');
  associationForumGeneralTypes.set('1', 'Upon cycle');
  associationForumGeneralTypes.set('2', 'Upon state-party request');
  associationForumGeneralTypes.set('3', 'Upon specified circumstance/event');
  associationForumGeneralTypes.set('4', 'Upon assembly/organization direction');
  associationForumGeneralTypes.set('5', 'Other');

  const consultationMechanismTypes = new Map();
  consultationMechanismTypes.set('0', 'Implementation consultation');
  consultationMechanismTypes.set('1', 'Review consultation');
  consultationMechanismTypes.set('2', 'Amendment consultation');
  consultationMechanismTypes.set('3', 'Withdrawal consultation');
  consultationMechanismTypes.set('4', 'Technologies/improvement consultation');
  consultationMechanismTypes.set('5', 'Extraordinary/special consultation');
  consultationMechanismTypes.set('6', 'Party-dispute consultation');
  consultationMechanismTypes.set('7', 'Interpretation-dispute consultation');
  consultationMechanismTypes.set('8', 'Compliance-dispute consultation');
  consultationMechanismTypes.set('9', 'Other consultation');

  const consultationCategoryTypes = new Map();
  consultationCategoryTypes.set('0', 'Operations and Maintenance consultation');
  consultationCategoryTypes.set('1', 'Dispute and Ambiguity consultation');

  useEffect(() => {
    fetchConsultationControlsystem(currentPage, currentRequestSort);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update demonstrated data whenever page, sort order or any of the filters are changed
  useEffect(() => {
    fetchConsultationControlsystem(currentPage, currentRequestSort);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, currentRequestSort, props.agreementFilters, consultationFilters]);

  // Toggle 'all' consultation on and off
  function onConsultToggleAll(isChecked) {
    document.getElementById('consultOpAndMaintenanceId').checked = isChecked;
    document.getElementById('consultDispAndAmbigId').checked = isChecked;
    setConsultationFilters(!consultationFilters);
  }

  // Update check on 'all'
  function onConsultUpdateAllCheck() {
    let isChecked = false;
    if (document.getElementById('consultOpAndMaintenanceId').checked
      && document.getElementById('consultDispAndAmbigId').checked) {
        isChecked = true;
    }
    document.getElementById('consultAllId').checked = isChecked;
    setConsultationFilters(!consultationFilters);
  }

  function getPageItems(selectedPage, totPages) {
    const tot = totPages < 5 ? totPages : 5;
    const items = [];
    let startIndex = 1;
    if (selectedPage <= 3 || totPages <= 5) {
      startIndex = 1;
    } else if (totPages - selectedPage <= 2) {
      startIndex = selectedPage - 4 + (totPages - selectedPage);
    } else {
      startIndex = selectedPage - 2;
    }
    for(let i = startIndex; i < startIndex+tot; i +=1) {
      var pagingClass = currentPage === i ? 'page-link active' : 'page-link';
      items.push(
        <li className='page-item page-item-nav' id={`page-list-${i}`} key={`page-list-${i}`} >
          <Link id={`page-${i}`} key={`page-${i}`} className={pagingClass} onClick={() => {
            setCurrentPage(i);
            getPageItems(i, totPages);
          }}>
            {i}
          </Link>
        </li>
      );
    }
    return items;
  }

  // Filter agreement parties from database
  const fetchConsultationControlsystem = async (page, requestConsultationSort) => {
    try {
      const pSize = document.getElementsByName('rowsPerPageConsultation')[0].value;
      let consultationItems = [];
      const mechanism = {
        consultation: {
          isOperationsAndMaintenance: document.getElementById('consultOpAndMaintenanceId').checked,
          isDisputeAndAmbiguity: document.getElementById('consultDispAndAmbigId').checked },
      };

      const checkboxes = document.getElementsByName('agreement-group-cs');
      const filterAgreements = [];
      for (let i = 0, n = checkboxes.length; i < n; i++) {
        if (checkboxes[i].checked) {
          const agreementId = checkboxes[i].value;
          filterAgreements.push(agreementId);
        }
      }

      const isNoMechanismSet = Object.values(mechanism).every(item => item === false);
      // Only fetch data when filter is set
      if (!isNoMechanismSet && filterAgreements.length > 0) {
        setLoader(true);
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ ids: filterAgreements, mechanism: mechanism, page: page,
            pageSize: pSize, sortCol: requestConsultationSort.name,
            sortAscending: requestConsultationSort.sortAscending })
        };

        // Consultation
        const consultationResponse =
          await fetch('/api/controlSystem/getConsultation', requestOptions);
        const resConsul = await consultationResponse.json();
        if (resConsul.isOk) {
          setTotalPages(resConsul.totalPages);
          setPageItems(getPageItems(page, resConsul.totalPages));

          resConsul.data?.forEach(item => {
            item.consultationCategoryStr = consultationCategoryTypes.get(item.category);
            item.consultationMechanismStr =
              consultationMechanismTypes.get(item.mechanism);
            item.consultationForumStr = item.forum === '0'
              ? item.agreement_forum
              : consultationForumTypes.get(item.forum);
            consultationItems.push(item);
          });
          setResults({"items": consultationItems, "hits": resConsul.hits});
        }
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error('Error fetching data:', error);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage- 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };

  const handleRowsPerPage = (pageCount) => {
    fetchConsultationControlsystem(currentPage, currentRequestSort);
  };

  function filterItem(id, str, isAll) {
    return (
      <div className='form-group'>
        <div className='form-check'>
          <input
            className='form-check-input' type='checkbox' id={id}
            onChange={(e) => isAll ? onConsultToggleAll(e.target.checked) :
              onConsultUpdateAllCheck()
            }
          />
          <label className='form-check-label' htmlFor={id}>
            {isAll && <strong>{str}</strong>}
            {!isAll && str}
          </label>
        </div>
      </div>
    );
  }

  return (
    <section className='component' id='component-form-table'
      aria-labelledby='section-header-form-table'>
      <div className='col'>
        <div className='card agreement-control-system'>
          <div className='card-body bg-light'>
            <fieldset>
              <legend>{t('agreementControlSystem.table.consultation')}</legend>
              <div className='row'>
                <div className='col-md-4'>
                  {filterItem('consultAllId', t('agreementControlSystem.all'), true)}
                </div>
                <div className='col-md-4'>
                {filterItem('consultOpAndMaintenanceId',
                  t('agreementControlSystem.operationandmaintenance'), false)}
                </div>
                <div className='col-md-4'>
                  {filterItem('consultDispAndAmbigId',
                    t('agreementControlSystem.disputeandambiguity'), false)}
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>

      <h2>{t('agreementControlSystem.table.result')} - {results?.hits ? results?.hits : 0}
        {` ${t('agreementControlSystem.table.hits')}`}</h2>
      <div className='row'>
        <div className='col-2'>
          <h3 id='section-header-form-table'>
            {t('agreementControlSystem.table.page')} {currentPage} ({totalPages})
          </h3>
        </div>
        <div className='col-3'>
          <label className='control-system-rowsperpage' htmlFor='rowsPerPageConsultation'>
            {t('agreementControlSystem.table.rowsperpage')}
          </label>
          <select name='rowsPerPageConsultation' id='rowsPerPageConsultation'
            onChange={(event) => handleRowsPerPage(event.target.value)}>
            <option value='10'>10</option>
            <option value='25'>25</option>
            <option value='50'>50</option>
          </select>
        </div>
        <div className='col-2'>
          <div className='loader amcdpLoader' style={{visibility: loader ? 'visible' : 'hidden' }} />
        </div>
      </div>
      <div className='anchor' id='table'></div>
      <div className='table-responsive'>
        <table className='table table-striped table-hover mb-4'>
          <thead>
          <tr>
              <th className='icon-position-right icon-sort tableHeader table-select' scope='col'
                onClick={() => {
                  setSortNameAsc(!sortNameAsc);
                  setCurrentRequestSort({"name": 'name', "sortAscending": sortNameAsc });
                }}>
                {t('agreementControlSystem.table.name')}
              </th>
              <th className='icon-position-right icon-sort tableHeader table-select' scope='col'
                onClick={() => {
                  setSortCategoryAsc(!sortCategoryAsc);
                  setCurrentRequestSort({"name": 'category', "sortAscending": sortCategoryAsc });
                }}>
                {t('agreementControlSystem.table.consultation.category')}
              </th>
              <th scope='col'>
                {t('agreementControlSystem.table.consultation.interval')}
              </th>
              <th scope='col'>
                {t('agreementControlSystem.table.consultation.mechanismid')}
              </th>
              <th className='icon-position-right icon-sort tableHeader table-select' scope='col'
                onClick={() => {
                  setSortMechanismAsc(!sortMechanismAsc);
                  setCurrentRequestSort({"name": 'mechanism',
                    "sortAscending": sortMechanismAsc });
                }}>
                {t('agreementControlSystem.table.consultation.mechanism')}
              </th>
              <th className='icon-position-right icon-sort tableHeader table-select' scope='col'
                onClick={() => {
                  setSortForumAsc(!sortForumAsc);
                  setCurrentRequestSort({"name": 'forum',
                    "sortAscending": sortForumAsc });
                }}>
                {t('agreementControlSystem.table.consultation.forum')}
              </th>
            </tr>
          </thead>
          <tbody>
            {results?.items?.map((item, i) => (
              <tr id={`cs-${item.agreement_id}-${i}`} key={`cs-${item.agreement_id}-${i}`}>
                <td>
                  { item.name.length > 35 ?
                    <Link to={`../${currentLang}/profilePage?id=${item.agreement_id}`} className=''
                      title={item.name}>
                      {getName(item.name, 35)}
                    </Link> :
                      <Link to={`../${currentLang}/profilePage?id=${item.agreement_id}`}
                        className=''>
                      {getName(item.name, 35)}
                    </Link>
                  }
                </td>
                <td>{item.consultationCategoryStr}</td>
                <td>{item.agreement_forum}</td>
                <td>{item.id}</td>
                <td>{item.consultationMechanismStr}</td>
                <td>{item.consultationForumStr}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <PageButtons handleFirstPage={handleFirstPage} handlePrevPage={handlePrevPage}
        handleNextPage={handleNextPage} handleLastPage={handleLastPage} currentPage={currentPage}
        totalPages={totalPages} pageItems={pageItems} />
    </section>
  );
}