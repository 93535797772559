export default function Legend() {

  return (
    <div className='verified_diagram control-system'>
      <h2 className='toggle-header'>
        <button className='toggle-button button-icon'
          data-bs-toggle='collapse' data-bs-target='#toggle-legend'
          aria-controls='toggle-legend' aria-expanded='false'>Legend
        </button>
      </h2>
    <div id='toggle-legend' className='toggle-content collapse'>
      <div className='card'>
          <div className='card-body'>
            <div className='row'>
              <div className='col-1 col-sm-6 verified_legend_divider'>
              <div className='legend-container'>
                <div className='legend-item'>
                  <svg width='20' height='20'>
                    <circle cx='10' cy='10' r='10' fill='#FF9300'/>
                  </svg>
                  <label>Acceptance</label>
                </div>
                <div className='legend-item'>
                  <svg width='20' height='20'>
                    <circle cx='10' cy='10' r='10' fill='#7FD1FF'/>
                  </svg>
                  <label>Accession</label>
                </div>
                <div className='legend-item'>
                  <svg width='20' height='20'>
                    <circle cx='10' cy='10' r='10' fill='#0082CC'/>
                  </svg>
                  <label>Approval</label>
                </div>
                <div className='legend-item'>
                  <svg width='20' height='20'>
                    <circle cx='10' cy='10' r='10' fill='#96EDBF'/>
                  </svg>
                  <label>Consent to be bound</label>
                </div>
                <div className='legend-item'>
                  <svg width='20' height='20'>
                    <circle cx='10' cy='10' r='10' fill='#F4E922'/>
                  </svg>
                  <label>Definitive signature</label>
                </div>
                <div className='legend-item'>
                  <svg width='20' height='20'>
                    <circle cx='10' cy='10' r='10' fill='#F794E0'/>
                  </svg>
                  <label>Participants</label>
                </div>
                <div className='legend-item'>
                  <svg width='20' height='20'>
                    <circle cx='10' cy='10' r='10' fill='#0EAD29'/>
                  </svg>
                  <label>Ratification</label>
                </div>
                <div className='legend-item'>
                  <svg width='20' height='20'>
                    <circle cx='10' cy='10' r='10' fill='#AC83E5'/>
                  </svg>
                  <label>Signature subject to ratification</label>
                </div>
                <div className='legend-item'>
                  <svg width='20' height='20'>
                    <circle cx='10' cy='10' r='10' fill='#02B2A1'/>
                  </svg>
                  <label>Succession</label>
                </div>
                <div className='legend-item'>
                  <svg width='20' height='20'>
                    <circle cx='10' cy='10' r='10' fill='#CC000A'/>
                  </svg>
                  <label>Withdrawal</label>
                </div>
              </div>
              </div>
              <div className='col-11 col-sm-6'>
              <div className='legend-container'>
                  <div className='legend-item'>
                    <svg width='40' height='10' className='legend-line'>
                    <line x1='0' y1='5' x2='40' y2='5' stroke='green' strokeDasharray='3 3' strokeWidth='2'/>
                    </svg>
                    <span className='legend-label'>EIF Date</span>
                  </div>
                  <div className='legend-item'>
                    <svg width='40' height='10' className='legend-line'>
                    <line x1='0' y1='5' x2='40' y2='5' stroke='yellow' strokeDasharray='3 3' strokeWidth='2'/>
                    </svg>
                    <span className='legend-label'>Signature Date</span>
                  </div>
                  <div className='legend-item'>
                    <svg width='40' height='10' className='legend-line'>
                    <line x1='0' y1='5' x2='40' y2='5' stroke='red' strokeDasharray='3 3' strokeWidth='2'/>
                    </svg>
                    <span className='legend-label'>Agreement Termination Date</span>
                  </div>
				        </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        </div>
  );
}
