import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function BrowseFile(props) {
  const { t } = useTranslation();

  const [file, setFile] = useState('');
  const inputFile = useRef(null);

  const handleFileUpLoad = e => {
    const { files } = e.target;
    if (files && files.length) {
      setFile(files[0]);
    }
  };

  const onButtonClick = () => {
    inputFile.current.click();
  };

  useEffect(() => {
    props.passFile(file);
  }, [file]);

  return (
<div className='row mb-2 border-bottom mb-4 pb-4'>
  <h3>{props.title}</h3>
  <div className='col-2'>
    <label className='form-label upload-db visually-hidden' htmlFor={`${props.title}-input`}>
      {props.title}
    </label>
    <input style={{ display: 'none' }} accept='.csv' ref={inputFile} onChange={handleFileUpLoad}
      type='file' id={`${props.title}-input`} />
    <div className='button button-outline' onClick={onButtonClick}>
      {`${t('db.browse')}...`}
    </div>
  </div>
  <div className='col-4'>
    {props.selectionStatus}
  </div>
  <div className='col-5'>
    {props.status}
  </div>
</div>
  );
}

export default BrowseFile;