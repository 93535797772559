import { useTranslation } from 'react-i18next';
import instructionImageAgreements1 from '../../img/agreements-instruction-1.jpg';
import instructionImageAgreements2 from '../../img/agreements-instruction-2.jpg';
import instructionImageAgreements3 from '../../img/agreements-instruction-3.jpg';
import instructionImageAgreements4 from '../../img/agreements-instruction-4.jpg';

const InstructionDropdown = () => {
  const { t } = useTranslation();

  return (
    <div className='instruction'>
      <h2 className='toggle-header'>
        <button className='toggle-button button-icon'
          data-bs-toggle='collapse' data-bs-target='#toggle-instruction'
          aria-controls='toggle-instruction' aria-expanded='false'>
          {t('agreements.instruction')}
        </button>
      </h2>
      <div id='toggle-instruction' className='toggle-content collapse'>
        <div className='card'>
          <div className='card-body'>
            <h3>{t('agreements.instruction.title')}:</h3>
            <div className='row mb-4 pb-4'>
              <div className='col-md-7'>
                <img src={instructionImageAgreements1} alt='Introduction to using agreement diagram'
                  style={{ maxWidth: '100%' }} />
              </div>
              <div className='col-md-5'>
                <p>{t('agreements.instruction.description_1_1')}</p>
                <p>{t('agreements.instruction.description_1_2')}</p>
                <p>{t('agreements.instruction.description_1_3')}</p>
                <p>{t('agreements.instruction.description_1_4')}</p>
                <p>{t('agreements.instruction.description_1_5')}</p>
                  <p>  {t('agreements.instruction.description_1_5_1')}</p>
                  <p> {t('agreements.instruction.description_1_5_2')}</p>
                  <p> {t('agreements.instruction.description_1_5_3')}</p>
              </div>
            </div>
            <div className='row mb-4 pb-4'>
              <div className='col-md-7'>
                <img src={instructionImageAgreements2}
                  alt='Obligation diagram with exapnded restrictions'
                  style={{ maxWidth: '100%' }} />
              </div>
              <div className='col-md-5'>
                <p>{t('agreements.instruction.description_2_1')}</p>
                <p>{t('agreements.instruction.description_2_2')}</p>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-7'>
                <img src={instructionImageAgreements3} alt='Obligation diagram with expanded bans'
                  style={{ maxWidth: '100%' }} />
              </div>
              <div className='col-md-5'>
                <p>{t('agreements.instruction.description_3_1')}</p>
                <p>{t('agreements.instruction.description_3_2')}</p>
                <p>{t('agreements.instruction.description_3_3')}</p>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-7'>
                <img src={instructionImageAgreements4} alt='Obligation diagram with expanded bans'
                  style={{ maxWidth: '100%' }} />
              </div>
              <div className='col-md-5'>
                <p>{t('agreements.instruction.description_4_1')}</p>
                <p>{t('agreements.instruction.description_4_2')}</p>
              </div>
            </div>
          </div>
      </div>
      </div>
    </div>
  );
};

export default InstructionDropdown;
