import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { getHTMLFromSitevisionObj } from './common';
import parseToHtml from 'html-react-parser';
import { Link } from 'react-router-dom';

function About() {
  const { t } = useTranslation();
  const [faqs, setFaqs] = useState('');
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    fetchGetFAQ();
  }, []);

  // Get FAQ from sitevision
  const fetchGetFAQ= async () => {
    try {
      setLoader(true);
      const requestOptions = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
      };
      const response = await fetch('/api/sv/getFAQ', requestOptions);
      const res = await response.json();

      const faq = parseToHtml(getHTMLFromSitevisionObj(res?.data?.find(({ name }) =>
        name === 'Innehåll').properties));

      setFaqs(faq);
      setLoader(false);

    } catch (error) {
      console.error('Error fetching data:', error);
      setLoader(false);
    }
  };

  return (
    <div className='container mt-5'>
      <div className='row'>
        <div className='col-md-12'>
          <h1>{t('about.title')}</h1>
          <p>{t('about.intro.paragraph1')}</p>
          <p>{t('about.intro.paragraph2')}</p>
        </div>
        <div className='row mt-4'>
          <h2>{t('about.ourprojects')}</h2>
          <div className='col-md-4'>
            <div className='card'>
              <h3 className='card-header'>{t('about.project.arms.data.title')}</h3>
              <div className='card-body'>
                <p>{t('about.project.arms.data.paragraph1')}</p>
                <p>
                  {t('about.project.arms.data.paragraph2')}
                  <Link to='mailto:amc@uu.se' >
                    amc@uu.se
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='card'>
              <h3 className='card-header'>{t('about.project.nucneg.title')}</h3>
              <div className='card-body'>
                <p>{t('about.project.nucneg.paragraph1')}</p>
              </div>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='card'>
              <h3 className='card-header'>{t('about.project.handbook.title')}</h3>
              <div className='card-body'>
                <p>{t('about.project.handbook.paragraph1')}</p>
                <p>
                  {t('about.project.handbook.paragraph2')}
                  <Link to='mailto:amc@uu.se' >
                    amc@uu.se
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-8'>
          <h2>{t('about.about.title')}</h2>
          <p>{t('about.about.paragraph1')}</p>
          <p>
            <Link to='https://www.uu.se/centrum/alva-myrdal' >
              {t('about.about.paragraph2')}
            </Link>
          </p>
          <h3>{t('about.funding.title')}</h3>
          <p>{t('about.funding.paragraph1')}</p>
          <h2>{t('about.whowas.alva.title')}</h2>
          <p>{t('about.whowas.alva.paragraph1.part1')}{parseToHtml('&ndash;')}
            {t('about.whowas.alva.paragraph1.part2')}</p>
          <p>{t('about.whowas.alva.paragraph2')}</p>
        </div>
        <div className='col-md-4'>
          <h2>{t('about.faq')}</h2>
          <div className='card card-body overflow-scroll faq'>
            <div className='col-2'>
              {loader && (<div className='loader amcdpLoader' />)}
            </div>
            {faqs}
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;